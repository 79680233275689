import {Component, Inject, Input, OnInit} from '@angular/core';
import { ElasticsearchService } from '@netfoundry-ui/shared/elasticsearch';
import { TobytesPipe } from '@netfoundry-ui/ui/pipes';
import { MatDialog } from '@angular/material/dialog';
import { LoggerService } from '@netfoundry-ui/shared/services';
import { Chart } from 'angular-highcharts';
import { Subscription } from 'rxjs';
import moment from 'moment';
import { isEmpty, get } from 'lodash';
import * as momentTz from 'moment-timezone';
import { ServiceEventTemplateQueryParameter } from '@netfoundry-ui/shared/model';
import {TEMPLATE_SEARCH_SERVICE, TemplateSearchService} from "@netfoundry-ui/shared/apiv2";

@Component({
    selector: 'app-service-health-timeline',
    templateUrl: './service-health-timeline.component.html',
    styleUrls: ['./service-health-timeline.component.scss'],
})
export class ServiceHealthTimelineComponent implements OnInit {
    @Input() networkId: any = null;
    @Input() networkGroupId: any = null;
    @Input() dateFilter: any = '24h';
    @Input() height = '300px';
    @Input() serviceId: any = null;
    chart: Chart;
    colors = ['#08dc5a', '#FF0D49', '#1aadce', '#0273fb', '#6d00f2', '#ffc000', '#ff7e00', '#ca0000', '#00aeb0'];
   /** colorMap = {
        'service.dial.success': '#08dc5a',
        'service.dial.fail': '#FF0D49',
        'service.dial.timeout': '#ff2222',
        'service.dial.error_other': '#ff5622',
    }; */
    colorMap = {
        'INVALID_SERVICE': '#08dc5a',
        'NO_ONLINE_TERMINATORS': '#1aadce',
        'NO_PATH': '#0273fb',
        'NO_TERMINATORS':'#6d00f2',
        'PATH_MISSING_LINK': '#ffc000',
        'ROUTER_ERR_CONN_REFUSED':'#ff7e00',
        'ROUTER_ERR_DIAL_TIMED_OUT':'#ff2222',
        'ROUTER_ERR_GENERIC':'#ff5622',
        'ROUTER_ERR_INVALID_TERMINATOR':'#ca0000',
        'SUCCESS':'#08dc5a'

    };
    // colors = ['#08dc5a', '#910000'];
    noData = true;
    isLoading = false;
    initialized = false;
    // currentOrgId;
    utilization_options = {};
    utilization_series = [];
    private subscription = new Subscription();

    constructor(
        private elasticsearch: ElasticsearchService,
        private toBytes: TobytesPipe,
        private graphViewer: MatDialog,
        private logger: LoggerService,
        @Inject(TEMPLATE_SEARCH_SERVICE) public templateService: TemplateSearchService
    ) {}

    ngOnInit() {
        this.initialized = true;
        this.generateUtilizationData();
    }

    ngOnChanges() {
        this.generateUtilizationData();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public getUtilizationData() {
        //const index = 'ncserviceevents';
        const index = 'ncfabriccircuits';

        this.logger.info('Ziti Service Events Query:', JSON.stringify(this.getQuery()));
        // this.statusLoading = true;

        this.noData = true;
        this.isLoading = true;
        //reporting
        const serviceEventTemplateParams2: ServiceEventTemplateQueryParameter = this.templateService.getServiceHealthTemplateQuery(
          this.dateFilter,
          this.networkId,
          this.networkGroupId,
          index,
          this.serviceId,
          this.templateService.getInterval(this.dateFilter),
          '3',
          'UTC',
        );
        const serviceEventTemplateParams: ServiceEventTemplateQueryParameter = {
            indexName: index,
            aggInterval: this.elasticsearch.intervals[this.dateFilter] + 'ms',
            timeZone: momentTz.tz.guess(),
            gte: 'now-' + this.dateFilter,
            lte: 'now',
            size: '0',
            aggTermSize: '10',
            serviceId: this.serviceId,
            networkId: this.networkId,
            networkGroupId: this.networkGroupId,
        };

        this.subscription.add(
            //this.elasticsearch.search(this.networkGroupId, index, this.getQuery()).subscribe((data) => {
            this.elasticsearch
                .apiTemplateSearch(this.templateService.SERVICE_HEALTH_TIME_SERIES_TEMPLATE, serviceEventTemplateParams)
                .subscribe((data) => {
                    const timeBuckets = get(data, this.templateService.dial_health_time_buckets_path);
                    if (isEmpty(timeBuckets)) {
                        this.logger.info('No item buckets returned for utilization data');
                        this.noData = true;
                        this.isLoading = false;
                        return;
                    }

                    // reset the utilization series so we don't stack series
                    this.utilization_series = [];
                    const dataseries = [];
                    for (const bucket of timeBuckets) {
                        // this is the timestamp
                        const timestamp = moment(bucket['key_as_string']).unix() * 1000;
                        const innerBuckets = get(bucket, this.templateService.dial_health_inner_buckets_path)
                        for (const inner_bucket of innerBuckets) {
                            let event_type = inner_bucket['key'];
                            //const val = inner_bucket['sum']['value'];
                            const doc_count = inner_bucket['doc_count'];
                            // if event_type is empty considered it as SUCCESS
                            if (event_type === "") {
                                event_type = "SUCCESS";
                            }
                            if (dataseries[event_type] === undefined) {
                                dataseries[event_type] = [];
                            }
                            dataseries[event_type].push([timestamp, Number(doc_count)]);
                        }
                    }

                    this.set_chart_options();

                    for (const itemkey of Object.keys(dataseries)) {
                        this.utilization_series.push({
                            name: itemkey,
                            data: dataseries[itemkey],
                            color: this.colorMap[itemkey],
                        });
                    }

                    if (!isEmpty(get(data, this.templateService.dial_health_time_buckets_path))) {
                        this.noData = false;
                    }

                    this.isLoading = false;
                })
        );
    }

    public set_chart_options() {
        const startTime = moment()
            .local()
            .subtract(
                this.dateFilter.slice(0, -1),
                this.dateFilter.slice(this.dateFilter.length - 1, this.dateFilter.length)
            );
        const endTime = moment().local();

        window.moment = moment;

        // utilization options
        this.utilization_options = {
            chart: {
                type: 'area',
                height: this.height,
            },
            yAxis: {
                labels: {
                    format: '{value}%',
                },
                title: {
                    enabled: false,
                },
            },
            legend: {
                symbolHeight: 7,
                symbolWidth: 5,
                symbolRadius: 6,
                margin: 0,
                maxHeight: 95,
                itemStyle: {
                    color: window.getComputedStyle(document.body).getPropertyValue('--text'),
                },
            },
            tooltip: {
                pointFormat:
                    '<span style="color:{series.color}">{series.name}</span>: <b>{point.percentage:.1f}%</b> ({point.y:,.0f} dials)<br/>',
                split: true,
            },
            plotOptions: {
                area: {
                    stacking: 'percent',
                    lineColor: '#ffffff',
                    lineWidth: 1,
                    marker: {
                        lineWidth: 1,
                        lineColor: '#ffffff',
                    },
                },
            },

            time: {
                useUTC: false,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
            colors: this.colors,
            styledMode: true,
            title: { text: null },
            subtitle: {
                text: 'Service Dial metrics measure service access attempts from the NetFoundry network',
                verticalAlign: 'bottom',
            },
            xAxis: {
                type: 'datetime',
                // the interval to use for the x-axis
                // tickInterval: interval,
                // the max tick number
                max: endTime.valueOf(),
                // the min tick number
                min: startTime.valueOf(),
                // the units that the ticks are allowed to land on
                // units: units,
            },
            credits: { enabled: false },
        };
    }

    public getQuery() {
        const model: any = {
            aggs: {
                items: {
                    date_histogram: {
                        field: 'timestamp',
                        // interval: this.elasticsearch.determine_interval(this.dateFilter),
                        // interval: this.determine_interval(),
                        interval: this.elasticsearch.intervals[this.dateFilter],
                        time_zone: 'UTC',
                        // time_zone: momentTz.tz.guess(),
                        min_doc_count: 1,
                    },
                    aggs: {
                        event_types: {
                            terms: {
                                field: 'event_type.keyword',
                                size: 3,
                            },
                            aggs: {
                                sum: {
                                    sum: {
                                        field: 'count',
                                    },
                                },
                            },
                        },
                    },
                },
            },
            size: 0,
            query: {
                bool: {
                    must: [
                        {
                            range: {
                                '@timestamp': {
                                    gte: 'now-' + this.dateFilter,
                                    lte: 'now',
                                    format: 'epoch_millis',
                                },
                            },
                        },
                        {
                            match_phrase: {
                                network_id: {
                                    query: this.networkId,
                                },
                            },
                        },
                        {
                            match_phrase: {
                                organizationId: {
                                    query: this.networkGroupId,
                                },
                            },
                        },
                        {
                            match_phrase: {
                                nf_service_id: {
                                    query: this.serviceId,
                                },
                            },
                        },
                    ],
                },
            },
        };

        return model;
    }

    private generateUtilizationData() {
        if (this.initialized && this.networkId !== null && this.networkId && this.networkGroupId !== null) {
            this.getUtilizationData();
        }
    }

    private determine_interval() {
        if (this.dateFilter.includes('m')) {
            return '1d';
        } else if (this.dateFilter.includes('d')) {
            return '1h';
        } else if (this.dateFilter.includes('h')) {
            return '10m';
        } else {
            // just in case...
            return '1d';
        }
    }
}
