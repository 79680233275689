<div class="modal solid open" id="NetworkControllerHARestartModal">
    <div (click)="close()" class="miniClose cancel icon-HamburgerAppClose"></div>
    <div class="title-row">
        <div class="icon" style="background-image: url(/assets/svgs/EditNetwork.svg)"></div>
        <div class="title-container">
            <div class="title">Restart Controllers</div>
            <div class="subtitle">Restart all controllers via host or process in parallel or via rolling restart mode. Restarting the host will not affect any Customer Hosted Controllers</div>
        </div>
    </div>

    <div class="content-row">
        <div id="RestartMode">
            <div class="label">
                Restart Scope
                <span class="specialnote">Required</span>
            </div>
            <p-dropdown 
            [options]="scopeOptions" 
            [(ngModel)]="selectedScopeOption"
            optionLabel="label">
            <ng-template let-selectedScopeOption pTemplate="selectedItem">
                <div class="dropdown-item">
                    <span>{{ selectedScopeOption.label }}</span>
                    <p-tag
                        *ngIf="selectedScopeOption.tagValue" 
                        [severity]="selectedScopeOption.severity" 
                        [value]="selectedScopeOption.tagValue">
                    </p-tag>
                </div>
            </ng-template>
        </p-dropdown>
        </div>
    </div>
    <div class="content-row">
        <div id="RestartField">
        <div class="label">
            Restart Mode
        <span class="specialnote">Required</span>
            </div>
            <p-dropdown 
                [options]="restartOptions" 
                [(ngModel)]="selectedOption"
                optionLabel="label">
                <ng-template let-selectedOption pTemplate="selectedItem">
                    <div class="dropdown-item">
                        <span>{{ selectedOption.label }}</span>
                        <p-tag
                            *ngIf="selectedOption.tagValue" 
                            [severity]="selectedOption.severity" 
                            [value]="selectedOption.tagValue">
                        </p-tag>
                    </div>
                </ng-template>
                <ng-template let-option pTemplate="item">
                    <div class="dropdown-item">
                        <span>{{ option.label }}</span>
                        <p-tag
                            *ngIf="option.tagValue" 
                            [severity]="option.severity" 
                            [value]="option.tagValue">
                        </p-tag>
                    </div>
                </ng-template>
            </p-dropdown>
            <div *ngIf="selectedOption.value === true">
                <p-message
                severity="warn"
                text="Restarting network controllers in parallel will cause network downtime and issues with connectivity during the restart process."
                ></p-message>
            </div>
        </div>
    </div>
    <div class="button-row">
        <button (click)="restartAll()" class="save" id="CreateButton">Restart</button>
    </div>
</div>
