<div class="modal solid upgradeModal open" id="upgradeModal">
    <div (click)="cancel()" class="close cancel" id="CancelXButton"></div>
    <div class="icon upgradenetwork"></div>
    <div class="title">{{ title }}</div>
    <div class="subtitle">
        Are you sure you want to upgrade this network? All components will be temporarily unavailable.
        <div class="label">
            Select Upgrade Options
            <span class="specialnote">Required</span>
        </div>
        <select
            (ngModelChange)="optionSelected($event)"
            [ngModel]="selectedUpgradeOption.value"
            id="upgradeOptions"
            name="optionSelect"
        >
            <option *ngFor="let upgradeOption of upgradeOptionList" [ngValue]="upgradeOption.value">
                {{ upgradeOption.label }}
            </option>
        </select>
        <ng-container *ngIf="(selectedUpgradeOption.value === 'ncer' || selectedUpgradeOption.value === 'er')">
            <div class="label">
                Select Edge Routers to Upgrade
                <span class="specialnote">Required</span>
            </div>
            <p-multiSelect
                [(ngModel)]="selectedEdgeRouters"
                [options]="edgeRouterList"
                [showHeader]="false"
                [style]="{ width: '100%' }"
                optionLabel="name"
            ></p-multiSelect>
        </ng-container>
        <div class="label">
            Select a Version
            <span class="specialnote">Required</span>
        </div>
        <select [(ngModel)]="selectedVersion" id="versionSelect" name="versionSelect">
            <option *ngFor="let version of versionList" [ngValue]="version.networkVersion">
                {{ version.networkVersion }}
                <span *ngIf="version.recommended" class="inline-block ml-3"> (Recommended)</span>
            </option>
        </select>
    </div>
    <div class="buttons">
        <div (click)="cancel()" class="linkButton cancel" id="CancelUpgrade">
            {{ cancelAction }}
        </div>
        <button (click)="confirm()" [disabled]="!selectedVersion" class="save alert confirm" id="ConfirmAction">
            {{ action }}
        </button>
    </div>
</div>
