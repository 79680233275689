import { Resource, HateoasResource } from '@lagoshny/ngx-hateoas-client';
@HateoasResource('clusters')

export class Cluster extends Resource {
  id: string;
  networkId: string;
  leaderId: string;
  readOnly: boolean;
  partitioned: boolean;
  createdAt: string;
  updatedAt: string;
}
