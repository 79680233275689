import { HttpClient, HttpHeaders } from '@angular/common/http';
import {ElementRef, Inject, Injectable, Renderer2} from '@angular/core';
import { ENVIRONMENT, Environment } from '@netfoundry-ui/shared/model';
import { firstValueFrom, lastValueFrom, ReplaySubject } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ApiService } from './api.service';
import { ErrorHistoryService } from './error-history.service';
import { LoggerService } from './logger.service';
import { HTTP_CLIENT } from './token.service';
import {FeatureService} from "./feature.service";

import {isEmpty} from 'lodash';

@Injectable({ providedIn: 'root' })
export class BrandingModeService {
    public brandingAvailable = false;
    public _darkModeEnabled = false;
    public tenantLabel;
    public domain;
    public logosAvailable;
    public logoCss;
    private _theme = 'light';
    private _primary = '#0273fb';
    private _secondary = '#08dc5a';
    public defaultTheme;
    public defaultPrimary;
    public defaultSecondary;
    public defaultBrandCSS;
    public defaultPrimaryOpaque;
    private data = new ReplaySubject<boolean>();
    public brandingUpdates = this.data.asObservable();
    private s3BucketPrefix = 'nf-console-branding-';
    public orgBrandingInit = false;
    public loginBrandingCSS = '';
    brandingUpdatedSource = new ReplaySubject<boolean>(1);
    brandingUpdated = this.brandingUpdatedSource.asObservable();

    constructor(
        protected logger: LoggerService,
        @Inject(ENVIRONMENT) private environment: Environment,
        @Inject(HTTP_CLIENT) public httpClient: HttpClient,
        private apiService: ApiService,
        private errorHistoryService: ErrorHistoryService,
        private featureService: FeatureService
    ) {}

    async fetchBrandCss(tenantLabel?: string): Promise<any> {
        if (!tenantLabel) {
            this.tenantLabel = await firstValueFrom(this.apiService.currentTenant.pipe(catchError((err) => null))).then(
                (tenant: any) => tenant.label,
                (error) => {
                    this.logosAvailable = false;
                }
            );
            // bail if we're not logged in yet
            if (!this.tenantLabel) {
                this.logosAvailable = false;
                return;
            }
        } else this.tenantLabel = tenantLabel;

        this.domain = this.environment.domain;

        this._checkDarkMode();
        if (this.environment.domain !== 'localhost' && this.environment.domain !== 'uuix') {
            const brandUrl = `https://s3.amazonaws.com/${this.s3BucketPrefix}${this.domain}/${this.tenantLabel}/css/${this._theme}.css`;

            // use a service call to get the CSS as text otherwise angular and the browser fire security alarms
            const headers = new HttpHeaders().set('X-Skip-Interceptor', 'true');

            return lastValueFrom(
                this.httpClient.get(brandUrl, { headers: headers, responseType: 'text' }).pipe(
                    catchError((err) => {
                        this.brandingAvailable = false;
                        this.logosAvailable = false;
                        this.errorHistoryService.addError(err.message);
                        this.configureDefaultBranding();
                        return null;
                    })
                )
            ).then((res) => {
                // strip out CSS brackets so we don't have to do a CORS security bypass
                let rawCss;
                if (typeof res === 'string') {
                    rawCss = res.replace(':root {', '').replace('}', '');
                }
                this.logger.info('Inject branding CSS', rawCss);
                rawCss = this.configureDefaultBranding(rawCss);
                this.logger.info('Branding primary color', this._primary);
                this.logger.info('Branding secondary color', this._secondary);
                this.brandingAvailable = true;
                rawCss += `--primary: ${this._primary}; --secondary: ${this._secondary};`;
                // set the logo URL
                // get the logos if we have them
                return this.getLogos(rawCss);
            }).finally(() => {
              this.brandingUpdatedSource.next(true);
            });
        } else return Promise.resolve();
    }

    private configureDefaultBranding(rawCss = '') {
        const parts = rawCss.split(';');
        this._primary = parts[0]?.split(':')[1]?.trim();
        this._secondary = parts[1]?.split(':')[1]?.trim();
        let loginLogo;
        parts.forEach((part) => {
            if (part.split(':')[0]?.trim()?.indexOf('loginLogo') >= 0) {
                loginLogo = part.split(':')[1]?.trim();
            }
        });
        if (!loginLogo) {
          if (this.environment?.defaultBranding?.loginLogo) {
              loginLogo = `url(${window.location.origin + this.environment?.defaultBranding?.loginLogo})`;
          } else {
              loginLogo = 'var(--logoLight)';
          }
        }
        rawCss += `--loginLogo: ${loginLogo}; `;
        if (this.environment.defaultTheme === 'dark') {
            this.loginBrandingCSS = `--backgroundColor: var(--backgroundColor_DARK);
                      --controlBoxBackground: var(--controlBoxBackground_DARK);
                      --brightness: var(--brightness_DARK);
                      --text: var(--text_DARK);
                      --inputBackground: var(--inputBackground_DARK);
                      --inputBorder: var(--inputBorder_DARK);
                      --loginLogo: ${loginLogo};
                      --buttonBackground: var(--buttonBackground_DARK);
                      `;
        }
        return rawCss;
    }

    private getLogos(rawCss): Promise<any> {
        const logoCssUrl = `https://s3.amazonaws.com/${this.s3BucketPrefix}${this.domain}/${this.tenantLabel}/css/logo.css`;
        const headers = new HttpHeaders().set('X-Skip-Interceptor', 'true');

        return lastValueFrom(
            this.httpClient.get(logoCssUrl, { headers: headers, responseType: 'text' }).pipe(
                catchError((err) => {
                    //html.style.cssText = rawCss;
                    this.logosAvailable = false;
                    this.sendBrandingUpdate(true);
                    return rawCss;
                })
            )
        ).then((res) => {
            let logoCss;
            if (typeof res === 'string') {
                logoCss = res.replace(':root {', '').replace('}', '');
            }
            this.logger.info('Inject Logos', logoCss);

            // wait until both requests are complete to inject the CSS
            //html.style.cssText = rawCss + logoCss;
            this.logoCss = logoCss;
            this.logosAvailable = true;
            this.sendBrandingUpdate(true);
            return rawCss + logoCss;
        });
    }

    // This is the restore called upon a logout, to persist the color restore, need to do that from the branding component
    public restore() {
        this._primary = '#0273fb';
        this._secondary = '#08dc5a';
        const html = document.getElementsByTagName('body')[0];
        html.style.cssText = '';
    }

    getPrimary() {
        return this._primary;
    }

    getSecondary() {
        return this._secondary;
    }

    private sendBrandingUpdate(data: boolean) {
        this.data.next(data);
    }

    get darkModeEnabled() {
        return this._darkModeEnabled === true || this.featureService.darkModeEnabled;
    }

    set darkModeEnabled(enabled) {
        this._darkModeEnabled = enabled;
        this.featureService.darkModeEnabled = enabled;
    }

    public updateDarkMode() {
        this._darkModeEnabled = this.featureService.darkModeEnabled;
    }

    public initCustomBrandingUrl() {
      if (!this.environment.defaultBranding) {
        return;
      }
      this.defaultTheme = this.environment.defaultTheme;
      this.defaultPrimary = this.environment.defaultBranding.primaryColor;
      this.defaultSecondary = this.environment.defaultBranding.secondaryColor;
      this.defaultPrimaryOpaque = this.environment.defaultBranding.primaryColorOpaque;
      this.defaultBrandCSS = `
        --primaryColor: ${this.defaultPrimary};
        --secondaryColor: ${this.defaultSecondary};
        --primaryColorOpaque: ${this.defaultPrimaryOpaque};
        --loginLogo: url(${window.origin + this.environment.defaultBranding.loginLogo});
      `;
    }

    private _checkDarkMode() {
        try {
          const localSetting = localStorage.getItem('darkMode_' + this.tenantLabel);
          if (localSetting === 'true') {
            this._theme = 'dark';
            this.darkModeEnabled = true;
            this.logger.info('Dark mode is enabled');
          }
        } catch(err) {
          this.logger.error('failed to update dark mode setting: ' + err);
        }
    }
}
