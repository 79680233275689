import { Component, OnInit, Inject } from '@angular/core';
import { ConsoleTourComponent } from '@netfoundry-ui/feature/console-tour';
import { VideoPlayerComponent } from '@netfoundry-ui/shared/video-player';
import { V2NetworkCreationFormComponent } from '@netfoundry-ui/feature/form/v2-network-creation-form';
import { MatDialog } from '@angular/material/dialog';
import { ApiService, FeatureService } from '@netfoundry-ui/shared/services';
import _, { cloneDeep, delay, get } from 'lodash';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { Environment, ENVIRONMENT } from '@netfoundry-ui/shared/model';

import { AutoFabricService } from '@netfoundry-ui/feature/auto-fabric';
import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';

declare const gtag;

const EnjoyHint = get(window, 'EnjoyHint', (p?: any) => {});

@Component({
    selector: 'app-getting-started',
    templateUrl: './getting-started.component.html',
    styleUrls: ['./getting-started.component.scss'],
})
export class GettingStartedComponent implements OnInit {
    isLoading = true;
    ZIGGY_TEXT = "Congrats! You're up and running. Now What?";
    PROVISIONING_TEXT =
        "Welcome! We took the liberty of spinning up your first network for you. We'll let you know when it's ready.";
    NO_NETWORK_TEXT =
        'It looks you don\'t have a network yet. Select the "Create Network" option bellow to get started.';
    lottieOptionsConstruction: AnimationOptions = {
        path: '/assets/animations/ConstructionZiggy.json',
    };
    lottieOptionsHello: AnimationOptions = {
        path: '/assets/animations/HelloZiggy.json',
    };
    animationItem = {};
    autoFabric = [];
    showStartPage = true;
    userPreferences: any = {};
    dialogRef: any;
    currentNetwork: any = {};
    provisioningNetwork = true;
    noNetwork = false;
    noAutoFabric = true;
    paymentProfile: any = {};
    currentOrg: any = {};
    hideZiggy = this.environment.hideZiggy;

    headerCards = [];
    menuItems = [];

    enjoy_hint_options = {
        showSkip: false,
        showNext: true,
        showPrev: true,
        onEnd: () => {
            //this.stepEnd.bind(this)
        },
        keyCode: '',
        top: 100,
        onSkip: () => {
            //this.modalOpen = false;
        },
    };

    enjoyhint_instance = new EnjoyHint(this.enjoy_hint_options);
    currentStep: any = {};
    steps = [
        {
            id: 0,
            name: 'Create an Endpoint',
            stepStart: 1,
            route: '/create',
            icon: '/assets/images/AppName.svg',
            hints: [
                {
                    event: 'new-endpoint',
                    selector: '.zitiEndpointsNav',
                    description: 'Select "Endpoints" from the navigation menu.',
                    onBeforeStart: () => {
                        $('.zitiEndpointsNav').off('click');
                        $('.zitiEndpointsNav').click((event) => {
                            delay(() => {
                                this.nextStep();
                            }, 100);
                        });
                    },
                },
                {
                    event: 'new-endpoint',
                    selector: '.buttonAdd',
                    description: 'Click the "Add" button in the top right corner.',
                    onBeforeStart: () => {
                        $('.buttonAdd').off('click');
                        $('.buttonAdd').click((event) => {
                            delay(() => {
                                this.enjoyhint_instance?.trigger('skip');
                            }, 100);
                        });
                    },
                },
            ],
        },
    ];

    constructor(
        public dialogForm: MatDialog,
        public featureService: FeatureService,
        private apiService: ApiService,
        public autoFabricService: AutoFabricService,
        public router: Router,
        @Inject(ENVIRONMENT) public environment: Environment
    ) {
        window['enjoyhint'] = this.enjoyhint_instance;
    }

    async ngOnInit() {
        this.apiService.currentUserPreferences.subscribe((userPreferences) => {
            this.userPreferences = _.cloneDeep(userPreferences?.preferences);
            this.showStartPage = this.featureService.showGettingStarted;
        });
        this.apiService.currentOrg.subscribe((org) => {
            this.currentOrg = org;
            this.initListItems();
        });
        this.apiService.currentNetwork.subscribe((network) => {
            if (_.isEmpty(network)) {
                this.initListItems();
                return;
            }
            this.currentNetwork = network;
            this.isLoading = false;
            this.noAutoFabric = !this.autoFabric || this.autoFabric?.length <= 0;
            if (!_.isEmpty(this.currentNetwork?.id)) {
                this.provisioningNetwork = this.currentNetwork.status === 'PROVISIONING';
            } else {
                this.noNetwork = true;
            }
            this.initListItems();
            this.autoFabricService.getAutoFabricRouters(this.currentNetwork, false);
        });
        this.apiService.currentAutoFabric.subscribe((autoFabric) => {
            this.autoFabric = autoFabric;
            this.noAutoFabric = !this.autoFabric || this.autoFabric?.length <= 0;
        });
        this.apiService.currentPaymentProfile.subscribe((data) => {
            this.paymentProfile = data;
        });
        this.featureService.experimentalFeaturesChanged.subscribe(() => {
            this.initListItems();
        });
    }

    initListItems() {
        this.headerCards = [
            {
                title: 'Zero Trust in Minutes',
                subtitle: 'Test your Network with a Quick Demo',
                links: [],
                backgroundClass: 'zt-in-minutes',
                contentType: 'none',
                linkText: 'Try Now',
                itemSelected: this.openSampleService.bind(this),
                completed: localStorage.getItem(`zt-in-minutes-complete_${this.currentOrg?.name}`) === 'true',
            },
            {
                title: 'Anatomy of a Ziti Network',
                subtitle: undefined,
                links: [],
                backgroundClass: 'zt-network-anatomy',
                contentType: 'image',
                imageSrc: '/assets/svgs/PlayButton.svg',
                linkText: 'Watch Now',
                itemSelected: this.openVideoPlayer.bind(this),
                completed: localStorage.getItem(`zt-network-anatomy-complete_${this.currentOrg?.name}`) === 'true',
            },
            {
                title: 'Quick Start Guides and Solution Recipes',
                subtitle: undefined,
                titleImageSrc: '/assets/svgs/GettingStartedDocs.svg',
                links: [
                    {
                        id: 'aws',
                        src: 'https://support.netfoundry.io/hc/en-us/articles/4405639573389-Getting-started-with-NetFoundry-Zero-Trust-Networking-AWS-Example',
                        text: 'AWS',
                        event: 'openAwsDoc',
                    },
                    {
                        id: 'azure',
                        src: 'https://support.netfoundry.io/hc/en-us/articles/6751989578125-Getting-started-with-NetFoundry-Zero-Trust-Networking-Azure-Example',
                        text: 'AZURE',
                        event: 'openAzureDoc',
                    },
                    {
                        id: 'google',
                        src: 'https://support.netfoundry.io/hc/en-us/articles/6600377641997-Getting-started-with-NetFoundry-Zero-Trust-Networking-Google-Cloud-Example',
                        text: 'Google Cloud',
                        event: 'openGoogleCloudDoc',
                    },
                    {
                        id: 'oracle',
                        src: 'https://support.netfoundry.io/hc/en-us/articles/360055462471-Getting-started-with-NetFoundry-Zero-Trust-Networking-Oracle-Cloud-Example',
                        text: 'Oracle',
                        event: 'openOracleCloudDoc',
                    },
                    {
                        id: 'app-embedded',
                        src: 'https://support.netfoundry.io/hc/en-us/articles/6696078681741-App-embedded-Zero-Trust-Networking',
                        text: 'App Embedded',
                        event: 'openAppEmbeddedDoc',
                    },
                ],
                backgroundClass: 'quick-start-links',
                contentType: 'links',
                linkText: 'Learn More',
                itemSelected: this.linksOpened.bind(this),
                completed: localStorage.getItem(`quick-start-links-complete_${this.currentOrg?.name}`) === 'true',
            },
        ];
        this.menuItems = [
            {
                title: 'Learn How to Navigate the Console',
                subtitle: '',
                buttonText: 'TAKE THE TOUR',
                iconClass: 'take-console-tour',
                itemSelected: this.showConsoleTour.bind(this),
                disabled: false,
                disabledTooltip: '',
                showSpinner: false,
                completed: localStorage.getItem(`take-console-tour-complete_${this.currentOrg?.name}`) === 'true',
            },
            {
                title: 'Watch Product Tutorials',
                subtitle: '',
                buttonText: 'WATCH THE VIDEOS',
                iconClass: 'product-tutorials',
                itemSelected: this.openProductTutorials.bind(this),
                disabled: false,
                disabledTooltip: '',
                showSpinner: false,
                completed: localStorage.getItem(`product-tutorials-complete_${this.currentOrg?.name}`) === 'true',
            },
            {
                title: 'Start with OpenZiti',
                subtitle: 'Embed Zero Trust directly in your Apps with our SDKs',
                buttonText: 'Learn More',
                iconClass: '',
                itemSelected: this.openZitiDetails.bind(this),
                disabled: false,
                disabledTooltip: '',
                hideZiggy: this.environment.hideZiggy,
                showSpinner: false,
                completed: localStorage.getItem(`open-ziti-details-complete_${this.currentOrg?.name}`) === 'true',
            },
            {
                title: 'Create a New Network',
                subtitle: '',
                buttonText: 'GET STARTED',
                iconClass: 'new-network',
                itemSelected: this.openNetworkCreationDialog.bind(this),
                disabled: false,
                disabledTooltip: '',
                showSpinner: false,
                completed: localStorage.getItem(`new-network-complete_${this.currentOrg?.name}`) === 'true',
            },
        ];

        if (!_.isEmpty(this.currentNetwork?.id)) {
            this.menuItems.splice(3, 1);
            this.headerCards[0].disabled = this.currentNetwork.status !== 'PROVISIONED';
            if (this.provisioningNetwork) {
                this.headerCards[0].disabledTooltip =
                    'Please wait for network to provision before using the sample service.';
                this.headerCards[0].showSpinner = true;
                this.headerCards[0].linkText = 'Creating Network';
            } else if (this.currentNetwork.status !== 'PROVISIONED') {
                this.headerCards[0].showSpinner = false;
                //this.menuItems[5].disabled = true;
                this.headerCards[0].linkText = 'Try Now';
                this.headerCards[0].disabledTooltip = 'Must have a provisioned network to use the sample service.';
            } else {
                this.headerCards[0].showSpinner = false;
                this.headerCards[0].linkText = 'Try Now';
                this.headerCards[0].disabledTooltip = '';
            }
        } else {
            this.headerCards[0].disabled = true;
            this.headerCards[0].disabledTooltip = 'Must create a network before using the sample service.';
            this.headerCards[0].showSpinner = false;
            this.headerCards[0].linkText = '';
        }
    }

    toggleHideStartPage() {
        if (gtag)
            gtag('event', 'click', {
                event_category: 'getting-started',
                event_label: 'toggleHideStartPage',
            });
        this.showStartPage = !this.showStartPage;
        this.featureService.showGettingStarted = this.showStartPage;
    }

    openProductTutorials() {
        if (gtag)
            gtag('event', 'click', {
                event_category: 'getting-started',
                event_label: 'openProductTutorials',
            });
        window.open('https://www.youtube.com/playlist?list=PLO27BWIOqVnYh1mkkJphjXTApS1gOv3r0');
        localStorage.setItem(`product-tutorials-complete_${this.currentOrg?.name}`, 'true');
        this.initListItems();
    }

    openSupportDetails() {
        if (gtag)
            gtag('event', 'click', {
                event_category: 'getting-started',
                event_label: 'openSupportDetails',
            });
        window.open('https://support.netfoundry.io/hc/en-us/articles/360019471912-Contact-NetFoundry-Support');
        localStorage.setItem(`support-details-complete_${this.currentOrg?.name}`, 'true');
        this.initListItems();
    }

    openZitiDetails() {
        if (gtag)
            gtag('event', 'click', {
                event_category: 'getting-started',
                event_label: 'openZitiDetails',
            });
        window.open('https://openziti.github.io');
        localStorage.setItem(`open-ziti-details-complete_${this.currentOrg?.name}`, 'true');
        this.initListItems();
    }

    animationCreated(animationItem: AnimationItem): void {
        this.animationItem = animationItem;
    }

    openNetworkCreationDialog() {
        if (gtag)
            gtag('event', 'click', {
                event_category: 'getting-started',
                event_label: 'openNetworkCreationDialog',
            });
        this.dialogRef = this.dialogForm.open(V2NetworkCreationFormComponent, {
            data: {},
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
    }

    openSampleService() {
        if (gtag)
            gtag('event', 'click', {
                event_category: 'getting-started',
                event_label: 'openSampleService',
            });
        if (this.noAutoFabric && !this.autoFabricService.pollingForFabric) {
            this.warnNoAutoFabric();
        } else {
            this.router.navigate(['getting-started/sample-service']);
        }
    }

    openVideoPlayer() {
        if (gtag)
            gtag('event', 'click', {
                event_category: 'getting-started',
                event_label: 'watchNetworkAnatomyVideo',
            });
        this.dialogRef = this.dialogForm.open(VideoPlayerComponent, {
            data: {},
            height: '455px',
            width: '935px',
            autoFocus: false,
        });
        this.dialogRef.afterClosed().subscribe((result) => {
            localStorage.setItem(`zt-network-anatomy-complete_${this.currentOrg?.name}`, 'true');
            this.initListItems();
        });
    }

    linksOpened() {
        window.open('https://support.netfoundry.io/hc/en-us/categories/360000991011-Docs-Guides', '_blank');
        localStorage.setItem(`quick-start-links-complete_${this.currentOrg?.name}`, 'true');
        this.initListItems();
    }

    showConsoleTour() {
        if (gtag)
            gtag('event', 'click', {
                event_category: 'getting-started',
                event_label: 'showConsoleTour',
            });
        this.dialogRef = this.dialogForm.open(ConsoleTourComponent, {
            data: {},
            height: '455px',
            width: '935px',
            autoFocus: false,
        });
        this.dialogRef.afterClosed().subscribe((result) => {
            localStorage.setItem(`take-console-tour-complete_${this.currentOrg?.name}`, 'true');
            this.initListItems();
        });
    }

    warnNoAutoFabric() {
        const data = {
            title: 'No Fabric',
            appendId: 'NoFabric',
            subtitle:
                'There is no fabric available yet for this network. To continue to the sample service please click "Ok" and we\'ll create your network fabric for you now.',
            bulletList: [],
            icon: 'Confirm',
            action: 'Ok',
        };
        this.dialogRef = this.dialogForm.open(ConfirmComponent, {
            data: data,
            height: '300px',
            width: '600px',
            autoFocus: false,
        });
        this.dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                let zipcode = localStorage.getItem('fabricZipcode_' + this.currentOrg?.name);
                if (_.isEmpty(zipcode)) {
                    zipcode = this.paymentProfile?.billingAddress?.zipcode;
                }
                let country = localStorage.getItem('fabricCountry_' + this.currentOrg?.name);
                if (_.isEmpty(country)) {
                    country = this.paymentProfile?.billingAddress?.country;
                }

                const count = this.autoFabric.length <= 0 ? 1 : 3;

                let locPromise;
                if (_.isEmpty(zipcode)) {
                    locPromise = this.autoFabricService.findGeoLocationFromCountry(country);
                } else {
                    locPromise = this.autoFabricService.findGeoLocationFromZipCode(country, zipcode);
                }
                locPromise.then((position) => {
                    const lat = position?.lat || 39.02982;
                    const long = position?.lng || -77.45926;
                    this.autoFabricService.createFabricFromGeoLocation(
                        this.currentNetwork,
                        lat,
                        long,
                        count,
                        true,
                      1
                    );
                });
                this.router.navigate(['getting-started/sample-service']);
            }
        });
    }

    openStep(selectedStep) {
        this.enjoyhint_instance = new EnjoyHint(this.enjoy_hint_options);
        this.currentStep = cloneDeep(selectedStep);
        this.enjoyhint_instance.set(this.currentStep.hints);
        this.enjoyhint_instance.run();
    }

    nextStep() {
        delay(() => {
            this.enjoyhint_instance.trigger('next');
        }, 100);
    }
}
