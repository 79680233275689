import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit, ɵComponentType } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AutoFabricComponent } from '@netfoundry-ui/feature/auto-fabric';
import { TableHeaderDefaultComponent } from '@netfoundry-ui/feature/data-table';
import { DownloadEntitiesComponent } from '@netfoundry-ui/feature/download-entities';
import { OidcIssuersFormComponent} from '@netfoundry-ui/feature/form/oidc-issuers-form';
import { MetricsModalComponent } from '@netfoundry-ui/feature/metrics-modal';
import { EdgerouterResizeModalComponent } from '@netfoundry-ui/feature/edgerouter-resize-modal';
import { CsvDownloadService, TableFilterService } from '@netfoundry-ui/feature/shared-services';
import { ZitiShareService } from '@netfoundry-ui/feature/ziti/ziti-share';
import { EdgeRouterServiceV2, NETWORK_SERVICE, NetworkServiceV2, StoredSecretService } from '@netfoundry-ui/shared/apiv2';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import {
    Environment,
    ENVIRONMENT,
    NetworkV2,
    OidcIssuer,
    StoredSecret
} from '@netfoundry-ui/shared/model';
import { ApiService, FeatureService, LoggerService, RefresherService, OidcIssuerService } from '@netfoundry-ui/shared/services';
import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { EventsModalComponent } from '@netfoundry-ui/feature/events-modal';
import { JsonViewComponent } from '@netfoundry-ui/feature/json-view';
import { AnimationOptions } from 'ngx-lottie';
import semver from 'semver';
import moment from 'moment';
import { PagedGetOption, Sort, SortOrder } from '@lagoshny/ngx-hateoas-client';
import { BulkErModalComponent } from '@netfoundry-ui/feature/bulk-er-modal';
import { NetworkUpgradeService } from '@netfoundry-ui/feature-network-upgrade';

const columns = [
    'name',
];
const asCreateColumns = ['name', 'id'];

const filename = 'stored-secrets';

const provisionedStatus = 'PROVISIONED';

@Component({
    selector: 'app-oidc-issuers',
    templateUrl: './oidc-issuers.component.html',
    styleUrls: ['./oidc-issuers.component.scss'],
    providers: [OidcIssuerService],
})
export class OidcIssuersComponent implements OnInit, OnDestroy {

    model: OidcIssuer;

    dialogRef;
    items = [];
    oidcIssuers = [] as OidcIssuer[];
    openIndex = -1;
    isLoading;

    allToggled = false;;

    isDeleteAction = false;

    subscription: Subscription = new Subscription();

    page = 1;
    filterString = '';
    deleting = 0;
    pageSize = 30;
    totalElements = 0;
    sorting = 'name';
    ordering = 'asc';
    private deleteMe = [];
    enableDeleteProtection;
    hideAppButton = false;
    changedPage: boolean;
    updatedOidcIssuer;
    filterHasChanged: boolean;
    hasSomeDeletePermission = true;
    filterApplied: any;
    showNoData: boolean;
    columnDefs;
    columnFilters: any = {

    };
    showList = true;
    public selectedIds = [];
    private deletedIds = [];
    private inDeletingStatusCount = 0;
    private openId = '';
    private noDeleteAuthCount = 0;

    showInfoBubble = false;
    lottieOptions: AnimationOptions = {
        path: '/assets/animations/SmartZiggy.json',
    };
    paramList: { size: number; sort: Sort } = {
        size: this.environment.pageSize,
        sort: { name: 'ASC' },
    };
    upgradeableVersionList: any[];

    constructor(
        private logger: LoggerService,
        private oidcIssuerService: OidcIssuerService,
        private refresher: RefresherService,
        public dialogForm: MatDialog,
        private growlerService: GrowlerService,
        public featureService: FeatureService,
        public authorizationService: AuthorizationService,
        public filterService: TableFilterService,
        private csvDownloadService: CsvDownloadService,
        public zitiShareService: ZitiShareService,
        private apiService: ApiService,
        private router: Router,
        @Inject(NETWORK_SERVICE) private networkService: NetworkServiceV2,
        @Inject(ENVIRONMENT) private environment: Environment,
    ) {}

    initTableColumns() {
        const columnFilters = this.columnFilters;
        const headerComponentParams = {
            filterType: 'TEXTINPUT',
            columnFilters,
        };

        this.columnDefs = [
            {
                colId: 'issuer',
                width: 350,
                minWidth: 100,
                field: 'issuer',
                sortColumn: this.sort.bind(this),
                headerName: 'Issuer',
                onCellClicked: (params) => {
                    if (params?.nfTextSelected) {
                        return;
                    }
                    this.open(params.data);
                },
                headerComponent: TableHeaderDefaultComponent,
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: true,
            },
            {
                colId: 'jwksUri',
                width: 350,
                minWidth: 100,
                field: 'jwksUri',
                sortColumn: this.sort.bind(this),
                headerName: 'JWKS URI',
                onCellClicked: (params) => {
                    if (params?.nfTextSelected) {
                        return;
                    }
                    this.open(params.data);
                },
                headerComponent: TableHeaderDefaultComponent,
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: true,
            },
            {
                colId: 'active',
                width: 350,
                minWidth: 100,
                field: 'active',
                sortColumn: this.sort.bind(this),
                headerName: 'Active',
                onCellClicked: (params) => {
                    if (params?.nfTextSelected) {
                        return;
                    }
                    this.open(params.data);
                },
                headerComponent: TableHeaderDefaultComponent,
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: true,
            },
        ];

    }

    ngOnInit() {
        this.filterService.setPageSize(this.pageSize);

        // subscribing to the filter service to handle the change in page
        this.subscription.add(
            this.filterService.setPageEvent.subscribe((pageNum) => {
                this.page = pageNum;
                this.changedPage = true;
                this.isLoading = true;
                this.refresh();
            })
        );
    
    
        // subscribing to the filter service to handle search filter changes
        this.subscription.add(
            this.filterService.setFilterEvent.subscribe((filterString) => {
                this.filterString = filterString;
                this.isfilterApplied();
                this.refresh();
            })
        );
    
        this.isLoading = true;
        this.handlePermissionCheck()
        this.initTableColumns();
    
    }



    handlePermissionCheck() {
        if (this.authorizationService.canListOIDCIssuer()) {
            this.getOidcIssuers();
        } else {
            this.isLoading = false;
            this.showList = false;
            this.oidcIssuers = [];
        }
    }

    ngOnDestroy() {
        this.columnFilters = {};
        this.subscription.unsubscribe();
        this.filterService.reset();
        this.refresher.disableRefresh();
    }

    refresh() {
        this.refresher.disableRefresh();
        this.handlePermissionCheck();
    }


    toggleAll() {
        this.allToggled = !this.allToggled;
        this.selectedIds = [];

        for (const item of this.oidcIssuers) {
                item.selected = this.allToggled;
                if (this.allToggled) {
                    this.selectedIds.push(item.id);
                }
        }

        this.isDeleteAction = this.anyToggled();
    }

    toggle(item: OidcIssuer) {
        item.selected = !item.selected;
        this.enableDeleteProtection = this.featureService.enableDeleteProtection;

        if (this.allToggled) {
            this.allToggled = !this.allToggled;
        }

        const index = this.selectedIds.indexOf(item.id);
        if (index > -1) {
            this.selectedIds.splice(index, 1);
        } else {
            this.selectedIds.push(item.id);
        }
        this.isDeleteAction = this.anyToggled();
        if (!this.enableDeleteProtection) {
            this.isDeleteAction = true;
            this.hideAppButton = false;
        }
        if (this.selectedIds.length > 1 && this.enableDeleteProtection) {
            this.isDeleteAction = false;
            this.hideAppButton = true;
        }
        if (this.selectedIds.length === 1 && this.enableDeleteProtection) {
            this.isDeleteAction = true;
            this.hideAppButton = false;
        }
        if (this.selectedIds.length === 0) {
            this.hideAppButton = false;
            this.isDeleteAction = false;
        }
    }

    filterChanged(columnId, value) {
        if (columnId === 'name') {
            this.filterString = value;
        }
        _.set(this.columnFilters, columnId, value);
        this.isLoading = true;
        this.isfilterApplied();
        this.shouldShowNoData();
        this.refresh();
    }

    isfilterApplied() {
        this.filterHasChanged = true;
        this.filterApplied = _.some(this.columnFilters, (value, filterName) => !_.isEmpty(_.toString(value)));
    }

    shouldShowNoData() {
        this.showNoData = this.oidcIssuers.length <= 0 && !this.filterApplied && !this.isLoading;
    }

    anyToggled() {
        if (this.selectedIds.length > 0) {
            if (this.selectedIds.length === this.items.length) {
                this.allToggled = true;
            }
            return true;
        }
        this.allToggled = false;
        return false;
    }

    toggleMenu(event, index) {
        event.stopPropagation();
        if (index !== this.openIndex) {
            this.openIndex = index;
        } else {
            this.openIndex = -1;
        }
    }


    openConfirm(item: OidcIssuer) {
        const bulletList = [];
        const deletingInfo = this.getDeletingTotal();
        bulletList.push(deletingInfo.ids)
        this.enableDeleteProtection = this.featureService.enableDeleteProtection;

        const data = {
            title: 'Delete',
            appendId: 'Oidc Issuer',
            subtitle: deletingInfo.deleteString,
            bulletList: deletingInfo.ids,
            icon: 'Delete',
            action: 'Yes',
        };
        this.dialogRef = this.dialogForm.open(ConfirmComponent, {
            data: data,
            height: '340px',
            width: '600px',
            autoFocus: false,
        });
        this.dialogRef.afterClosed().subscribe((result) => {
            // if the result has a property loggingOut, rather than being just a boolean value, the user is being
            //  logged out of the console and we should close the dialog without continuing
            if (result === undefined) {
                this.confirmed(false);
            } else if (result['loggingOut'] === undefined) {
                this.confirmed(result);
            }
        });
    }

    deleteSingleItem(item: OidcIssuer) {
        const subtitle = 'Are you sure you would like to delete the following oidc issuer?:';

        this.enableDeleteProtection = this.featureService.enableDeleteProtection;

        const data = {
            title: 'Delete',
            appendId: 'OIDC Issuer',
            subtitle: subtitle,
            bulletList: [item],
            icon: 'Delete',
            action: 'Yes',
            isDestructive: false,
            itemName: item.id,
        };
        this.dialogRef = this.dialogForm.open(ConfirmComponent, {
            data: data,
            height: '340px',
            width: '600px',
            autoFocus: false,
        });
        this.dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                // User canceled. Do nothing.
            } else if (result['loggingOut'] === undefined) {
                this.deletedIds = [];
                this.oidcIssuerService.delete(item.id).subscribe(
                    (data) => {
                        this.deletedIds.push(item.id);
                        this.deleteMe = [];
                        this.isLoading = true;
                        this.refresh();
                    },
                    (error) => {
                        this.growlerService.show(
                            new GrowlerData(
                                'error',
                                'Error',
                                'Oidc Issuer failed to delete',
                                'The selected oidc issuer failed to delete from the system'
                            )
                        );
                    }
                );
            }
        });
    }

    confirmDelete(item) {
        this.toggleAll();
        if (this.allToggled) {
            this.toggleAll();
        }
            item.selected = true;

            const index = this.selectedIds.indexOf(item.id);
            if (index === -1) {
                this.selectedIds.push(item.id);
            }
            this.isDeleteAction = true;
            this.openConfirm(item);
    }

    confirmed(event) {
        if (event) {
            this.isLoading = true;
            this.deleting = this.selectedIds.length;
            for (const item of this.oidcIssuers) {
                if (item.selected) {
                    this.deleteMe[this.deleteMe.length] = item;
                }
            }
            this.deleteNext();
        } else {
            this.selectedIds = [];
            this.isDeleteAction = false;
            this.refresh();
        }
    }

    deleteNext() {
        this.deleting--;
        this.oidcIssuerService.delete(this.deleteMe[this.deleting].id).subscribe(
            () => {
                this.postDelete();
            },
            () => {
                this.postDelete();
            }
        );
    }



    postDelete() {
        // forcing the deleted items to become untoggled
        this.toggle(this.deleteMe[this.deleting]);

        if (this.deleting === 0) {
            this.deleteMe = [];
            this.isLoading = true;
            this.getOidcIssuers()
        } else {
            this.deleteNext();
        }
    }

    getTotalSelected() {
        return this.selectedIds.length;
    }

    getDeletingTotal() {
            let total = 0;
            const ids = [];
            for (const item of this.oidcIssuers) {
                if (item.selected) {
                    ids.push(item);
                    total++;
                }
            }

            let deleteString = 'Are you sure you would like to delete ';

            if (total > 1) {
                deleteString += `these ${total} OIDC Issuers:`;
            } else {
                deleteString += 'the following OIDC Issuer:';
            }

            return {
                deleteString: deleteString,
                ids: ids,
            };
   
        }

    async create() {

        if(this.authorizationService.canCreateOIDCIssuer()) {
            this.dialogRef = this.dialogForm.open(OidcIssuersFormComponent, {
                data: {},
                minHeight: '100%',
                minWidth: '100%',
                height: '100%',
                width: '100%',
            });
            this.dialogRef.afterClosed().subscribe((result) => {
                if (!result || (result && result['loggingOut'] === undefined)) {
                    this.isLoading = true;
                    this.refresh();
                }
            });
        }

    }

    open(item: OidcIssuer) {
        if (this.environment.v3Enabled) {
            return;
        }
        this.model = item;
            this.dialogRef = this.dialogForm.open(OidcIssuersFormComponent, {
                data: {
                    model: this.model,
                },
                minHeight: '100%',
                minWidth: '100%',
                height: '100%',
                width: '100%',
            });

            this.dialogRef.afterClosed().subscribe((result) => {
                // under normal circumstances nothing is returned when the dialog is closed
                //  however, if something is returned, it is because the user is being logged out while this dialog is opened
                //    if this is the case, we do not want to call the refresh function as the user will be unauthenticated
                if (result && result['loggingOut'] === undefined) {
                    const updatedModel = _.get(result, 'updatedOidcIssuer', result);
                    if (updatedModel) {
                        this.updatedOidcIssuer = updatedModel;
                        this.updatedOidcIssuer['actionList'] = item['actionList'];
                        this.isLoading = true;
                        this.refresh();
                    }
                }
                // if user tries to edit the the values and enter wrong parameters, and then if he clicks on close button,
                // the list will get updated with wrong values until refresh. We need to implement clone operation in edit page to fix this issue
                if (result === null || result === undefined) {
                    this.refresh();
                }
            });

    }

    closeActionMenu() {
        this.openIndex = -1;
    }

    sort(sortBy: string, ordering: SortOrder) {
        if (this.sorting === sortBy) {
            this.ordering = ordering;
        } else {
            this.ordering = 'ASC';
            this.sorting = sortBy;
        }

        this.changedPage = true;

        const s: Sort = {};
        [this.sorting] = this.ordering;
        this.paramList.sort = s;
        this.refresh();
    }

    getSortClass(id) {
        if (id === this.sorting) {
            return this.ordering;
        } else {
            return '';
        }
    }

    trackById(index, item) {
        return item.id;
    }


    getOidcIssuers() {

        if (this.filterString && this.page !== 1 && !this.changedPage) {
            this.changedPage = true;
            this.isLoading = true;
            this.filterService.setPage(1, true);
            this.page = 1;
        }

        this.oidcIssuerService
            .findOidcIssuers()
            .subscribe((oidcIssuers) => {
                const newTotalPages = this.oidcIssuerService.lastPageCount;
                // if the number of pages has decreased and the user is past the last page now
                if (this.page > newTotalPages && newTotalPages !== 0) {
                    // move back one page
                    this.filterService.setPage(newTotalPages);
                } else {
                    this.inDeletingStatusCount = 0;
                    const theResult = oidcIssuers as OidcIssuer[];
                    let newHasSomeDeletePermission = false;
                    this.noDeleteAuthCount = 0;

                    let updatedOidcIssuer;
                    const updatedOidcIssuerId = this.updatedOidcIssuer?.id;

                    let index = 0;
                    const newSelectedIds = [];
                    for (const oidcIssuer of oidcIssuers as OidcIssuer[]) {
                        if (this.openId === oidcIssuer.id) {
                            this.open(oidcIssuer);
                            this.openId = '';
                        }
                        if (oidcIssuer.id === updatedOidcIssuerId) {
                            this.updatedOidcIssuer.selected = oidcIssuer['selected'];
                            this.updatedOidcIssuer.status = oidcIssuer['status'];
                            const links = _.get(theResult[index], '_links');
                            _.set(this.updatedOidcIssuer, '_links', links);
                            theResult[index] = this.updatedOidcIssuer;
                        }
                        if (this.selectedIds.indexOf(oidcIssuer.id) > -1) {
                            newSelectedIds.push(oidcIssuer.id);
                            oidcIssuer.selected = true;
                        }
                        const actions = [];
                        // TODO add permission checks
                        if (
                            this.authorizationService.canDeleteOIDCIssuer(oidcIssuer.id)
                        ) {
                            actions.push('delete');
                            newHasSomeDeletePermission = true;
                        } else {
                            this.noDeleteAuthCount++;
                        }
                        // TODO add permission checks
                        if (
                            this.authorizationService.canEditOIDCIssuer(oidcIssuer.id)
                        ) {
                            actions.push('update');
                        }
                        oidcIssuer['actionList'] = actions;

                        index ++;
                    }

                    this.selectedIds = newSelectedIds;
                    this.updatedOidcIssuer = null;
                    // the deleted service ids are only needed for the first refresh. After the first refresh they should no longer appear
                    // if the service does appear again, then something went wrong with the delete and the status shouldn't be set to 800
                    this.deletedIds = [];
                    this.oidcIssuers = theResult;

                    this.totalElements = this.oidcIssuerService.lastTotalCount;
                    this.showList = this.totalElements > 0;

                    this.filterService.setTotalElements(this.oidcIssuers.length);
                    this.filterService.updateTotalPages();

                    this.isDeleteAction = this.anyToggled();
                    this.hasSomeDeletePermission = newHasSomeDeletePermission;

                    this.isLoading = false;
                    this.logger.info('retrieved oidc issuers for network');
                }
            },
            (httpErrorResponse) => {
                this.logger.error(httpErrorResponse);
                this.isLoading = false;
            })
            .add(() => {
                this.filterHasChanged = false;
                this.isLoading = false;
                this.changedPage = false;
                this.shouldShowNoData();
            });
            this.refresher.refreshTimerId = setTimeout(() => {
                this.refresh();
            }, this.refresher.refreshInterval);
    }

}
