import { Component, HostListener, Inject, OnDestroy, OnInit, ɵComponentType } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ZitiShareService } from '@netfoundry-ui/feature/ziti/ziti-share';
import {
    AttributesService,
    AttributesServiceResults,
    EdgeRouterServiceV2,
    ProviderService,
    RegionService,
    ServiceServiceV2,
} from '@netfoundry-ui/shared/apiv2';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import {
    ADVANCED_SERVICE_DIALOG,
    CONFIG_SERVICE_DIALOG,
    EdgeRouterV2,
    ENDPOINT_DIALOG,
    Environment,
    ENVIRONMENT,
    NetworkV2,
    PagedAttributes,
    SERVICE_DIALOG,
    ZShareData,
} from '@netfoundry-ui/shared/model';
import {
    ApiService,
    FeatureService,
    LocationService,
    LoggerService,
    NetworkVersionService,
    ValidateService,
} from '@netfoundry-ui/shared/services';
import { FromDatePipe } from '@netfoundry-ui/ui/pipes';
import copy from 'copy-to-clipboard';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { MetricsModalComponent } from '@netfoundry-ui/feature/metrics-modal';
import { Router } from '@angular/router';
import { EventsModalComponent } from '@netfoundry-ui/feature/events-modal';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';
import semver from 'semver';

@Component({
    selector: 'app-ziti-edge-router',
    templateUrl: './ziti-edge-router.component.html',
    styleUrls: ['./ziti-edge-router.component.scss'],
    providers: [FromDatePipe],
})
export class ZitiEdgeRouterComponent implements OnInit, OnDestroy {
    public model: EdgeRouterV2 = new EdgeRouterV2();
    invalidWords = ['#all'];
    jwtTimeout = 0;
    gettingJwt = false;
    isComplete = false;
    errorName = false;
    errorNameLength = false;
    errorHostProvider = false;
    editId = '';
    processing = false;
    hideHelp = false;
    isInline = false;
    isRouterOpen = true;
    isHosted = true;
    refreshClicked = false;
    provider;
    disabled = false;
    currentNetwork: NetworkV2;
    options;
    productVersion;
    tunnelerSupported = false;
    isBuilding = false;
    isEditing = false;
    bulkEdit = false;
    netfoundryHosted = true;
    syncClicked = false;
    dataCenters = [];
    regions = [];
    errors = {};
    providers = [];
    provisionedString = 'has been created';
    deploymentStatus;
    buildingString = 'is building';
    completedTitleStatusString = this.provisionedString;
    registered = false;
    isCustomerHosted = false;
    datacenter: any;
    enableAltDomain = false;
    canEdit = true;
    canShareEdgeRouter = false;
    selection = 'netfoundryHosted';
    networkGroupId;
    jwtExpired = false;
    jwtExpiration;
    wssAllowed = false;
    displayedDatacenters = [];
    displayedRegions = [];
    assocServices = [];
    serviceMap = {};
    assocEndpoints = [];
    managedEndpoints = [];
    endpointMap = {};
    noTraversalAllowed = false;
    isRouterManaged: boolean;
    isLoadingAssocServices: boolean;
    isLoadingAssocEndpoints: boolean;
    isLoadingEdgeRouterAttr: boolean;
    edgeRouterError: boolean;
    attributeError = false;
    edgeRouterAttributes = new PagedAttributes();
    selectedEdgeRouterAttributes = new PagedAttributes();
    isLoading: boolean;
    whiteLabelSupported = false;
    initalModel: EdgeRouterV2 = new EdgeRouterV2();
    linkListenerChanged = false;
    softwareRegistered = false;
    private subscription = new Subscription();
    private relatedSubscription = new Subscription();
    private componentCount = 0;
    private componentCountLoaded = false;
    private warnSelfServ = false;
    private isSelfServiceTeamsTier = false;

    constructor(
        private logger: LoggerService,
        private dialogRef: MatDialogRef<ZitiEdgeRouterComponent>,
        public edgeRouterService: EdgeRouterServiceV2,
        private attributeService: AttributesService,
        public serviceServiceV2: ServiceServiceV2,
        public growlerService: GrowlerService,
        public authorizationService: AuthorizationService,
        private locationService: LocationService,
        private regionService: RegionService,
        private providerService: ProviderService,
        public validateService: ValidateService,
        public networkVersionService: NetworkVersionService,
        private zitiShareService: ZitiShareService,
        private fromDate: FromDatePipe,
        public dialogForm: MatDialog,
        private apiService: ApiService,
        public featureService: FeatureService,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: any,
        @Inject(ENDPOINT_DIALOG) private endpointDialog: ɵComponentType<any>,
        @Inject(SERVICE_DIALOG) private serviceDialog: ɵComponentType<any>,
        @Inject(ADVANCED_SERVICE_DIALOG) private advServiceDialog: ɵComponentType<any>,
        @Inject(CONFIG_SERVICE_DIALOG) private configServiceDialog: ɵComponentType<any>,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {}

    get hostProvider() {
        return _.get(this.model, '_embedded.host.provider');
    }

    get expiration() {
        return _.get(this.model, 'registration.expiresAt');
    }

    get hostDataCenter() {
        return _.get(this.model, '_embedded.host.dataCenterId');
    }

    toggleRouter() {
        this.isRouterOpen = !this.isRouterOpen;
    }

    toggle() {
        this.isHosted = !this.isHosted;
    }

    ngOnInit() {
        this.isLoading = true;
        this.initModel();
        this.currentNetwork = this.apiService.currentNetwork.getValue();
        if (this.currentNetwork) {
            if (!this.bulkEdit) {
                this.model.networkId = this.currentNetwork.id;
            }

            this.productVersion = this.currentNetwork.productVersion;

            if (semver.gte(this.productVersion, '7.3.90')) {
                this.wssAllowed = true;
            }

            this.whiteLabelSupported = this.featureService.whiteLabelSupported(this.currentNetwork);

            this.networkVersionService
                .getNetworkFeatures(this.currentNetwork)
                .toPromise()
                .then((features) => {
                    const zitiFeatures = _.get(features, '_embedded.network-versions[0].zitiFeatures');
                    this.tunnelerSupported = _.some(
                        zitiFeatures,
                        (feature: any) => feature.displayName === 'edge-router.is-tunneler-enabled'
                    );
                });
            this.initializeEdgeRouterSelector();
            if (this.model.id) {
                this.findAssociatedEntities();
            }
        }

        this.subscription.add(
            this.apiService.currentSubscription.subscribe(async (subscription) => {
                this.isSelfServiceTeamsTier =
                    this.featureService.isSelfServiceTeamsTier(subscription) ||
                    this.featureService.isSelfServicePAYG(subscription);
            })
        );

        if (!this.bulkEdit) {
            this.model.dataCenterId = _.get(this.model, '_embedded.host.dataCenterId');
        }
        if (this.bulkEdit) {
            this.validate();
        }
        if (!this.model.jwt) {
            this.registered = true;
        }
        if(this.model.id) {
            this.findERDeploymentState()
        }
        if (this.model.provider === 'CUSTOMER') {
            this.isCustomerHosted = true;
            this.noTraversalAllowed = true;
        }
        if (!this.isCustomerHosted && this.authorizationService.canListDataCenters()) {
            this.providerService.getProviderPage().then((providers) => {
                if (this.isSelfServiceTeamsTier) {
                    this.providers = providers.filter((provider) => provider.type === 'AWS');
                } else {
                    this.providers = providers;
                }
            });
            if (!this.bulkEdit && !this.isEditing) {
                this.model.provider = 'AWS';
            }
            this.regionService.findRegionsByProvider([this.model.provider]).subscribe((regions) => {
                this.displayedRegions = regions;
            });
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.relatedSubscription.unsubscribe();
    }

    hide(response?) {
        this.dialogRef.close(response);
    }

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
        this.hide();
    }

    async save() {
        this.model.attributes = Array.from(this.selectedEdgeRouterAttributes.mappedAtrributes.keys());
        if (!this.whiteLabelSupported) {
            _.unset(this.model, 'enabled');
            _.unset(this.model, 'alternateDomainName');
        }
        if (this.selection === 'customerHosted') {
            _.unset(this.model, 'alternateDomainName');
        }
        const model = _.cloneDeep(this.model);
        if (this.selection === 'customerHosted') {
            _.unset(model, 'provider');
            _.unset(model, 'locationCode');
        }
        if (this.isCustomerHosted && this.isEditing) {
            _.unset(this.model, 'linkListener');
        }

        if (!this.wssAllowed) delete this.model.wssListener;
        this.processing = true;
        if (await this.validate()) {
            if (this.bulkEdit) {
                this.dialogRef.close({ saved: true });
            } else if (this.model.id) {
                if (!this.tunnelerSupported) {
                    _.unset(this.model, 'tunnelerEnabled');
                }
                if (this.linkListenerChanged) {
                    const data = {
                        title: 'Restart Required',
                        appendId: 'ToggleLinkListener',
                        subtitle:
                            '<p>Changing the Link Listener property will cause this router to restart. Any edge connections to the router will be re-established.</p>' +
                            '<p>Do you want to continue?</p>',
                        useInnerHtml: true,
                        icon: 'RouterHosted',
                        action: 'Yes',
                        isDestructive: false,
                    };
                    const dialogRef = this.dialogForm.open(ConfirmComponent, {
                        data: data,
                        height: '340px',
                        width: '600px',
                        autoFocus: false,
                    });
                    dialogRef.afterClosed().subscribe((result) => {
                        if (result) {
                            this.edgeRouterService.patchResource(this.model).subscribe(
                                (data: EdgeRouterV2) => {
                                    this.processing = false;
                                    this.dialogRef.close(data);
                                },
                                (httpErrorResponse) => {
                                    this.processing = false;
                                    this.growlerService.show(
                                        new GrowlerData(
                                            'error',
                                            'Edge Router update request failed. ',
                                            httpErrorResponse.error.errors[0]
                                        )
                                    );
                                }
                            );
                        }
                    });
                } else {
                    this.edgeRouterService.patchResource(this.model).subscribe(
                        (data: EdgeRouterV2) => {
                            this.processing = false;
                            this.dialogRef.close(data);
                        },
                        (httpErrorResponse) => {
                            this.processing = false;
                            this.growlerService.show(
                                new GrowlerData(
                                    'error',
                                    'Edge Router update request failed. ',
                                    httpErrorResponse.error.errors[0]
                                )
                            );
                        }
                    );
                }
            } else {
                if (this.selection !== 'netfoundryHosted' && this.tunnelerSupported) {
                    this.model.tunnelerEnabled = true;
                } else if (!this.tunnelerSupported) {
                    _.unset(this.model, 'tunnelerEnabled');
                }

                const model = _.cloneDeep(this.model);
                if (this.selection === 'customerHosted') {
                    _.unset(model, 'provider');
                    _.unset(model, 'locationCode');
                }
                _.unset(model, 'dataCenterId');

                this.edgeRouterService.createResource({ body: model }).subscribe(
                    (data: EdgeRouterV2) => {
                        if (!this.isHosted) {
                            this.canShareEdgeRouter = true;
                        }
                        this.model = data;
                        this.processing = false;
                        this.isComplete = true;
                        if (this.selection === 'netfoundryHosted') {
                            this.growlerService.show(
                                new GrowlerData(
                                    'success',
                                    'Success',
                                    'Edge Router creation successful',
                                    'Edge Router create process started successfully.<a href="' +
                                        this.router.createUrlTree(['/process-executions']).toString() +
                                        '">Click here to find out more.</a>'
                                )
                            );
                            this.dialogRef.close(data);
                        }
                        if (this.selection === 'customerHosted' && !this.model.jwt) {
                            this.gettingJwt = true;
                            this._checkForJWT(this.model.id);
                        }
                    },
                    (httpErrorResponse) => {
                        this.processing = false;
                        this.growlerService.show(
                            new GrowlerData('error', 'Edge Router creation failed. ', httpErrorResponse.error.errors[0])
                        );
                    }
                );
            }
        } else {
            this.processing = false;
        }
    }

    _checkForJWT(erId) {
        const currentTime = new Date().getUTCDate();
        this.edgeRouterService.getEdgeRouter(this.model.id).then(
            (data: EdgeRouterV2) => {
                if (_.isEmpty(data.jwt) && this.gettingJwt && currentTime < this.jwtTimeout) {
                    _.delay(() => {
                        this._checkForJWT(erId);
                    }, 500);
                } else {
                    this.model = data;
                    this.gettingJwt = false;
                }
            },
            () => {
                this.gettingJwt = false;
            }
        );
    }

    openDownloads() {
        window.open(this.networkVersionService.getDownloadsLink() + '#zitirouters');
    }

    openUrl(url) {
        window.open(url);
    }

    downloadJWT() {
        if (!this.model.jwt) {
            return;
        }
        const name = this.model.name + '.jwt';
        const jwt = new Blob([this.model.jwt], { type: 'text/plain;charset=utf-8' });
        saveAs(jwt, name);
    }

    getEdgeRouterConfig() {
        this.edgeRouterService.getConfig(this.model.id).subscribe((c) => {
            const name = this.model.name + '.yml';
            const yml = new Blob([c], { type: 'text/plain;charset=utf-8' });
            saveAs(yml, name);
        });
    }

    findERDeploymentState() {
        this.edgeRouterService.erSoftwareDeploymentState(this.model.id).subscribe((softwareState) => {
            this.deploymentStatus = softwareState[0].softwaredeploymentstate[0].online
            this.softwareRegistered = softwareState[0].softwaredeploymentstate[0].enabled
        })
    }

   forceSync() {
        this.edgeRouterService.forceSync(this.model.id).subscribe((result) => {
            if(result) {
                this.syncClicked = false;
                this.growlerService.show(
                    new GrowlerData(
                        'success',
                        'Success',
                        'Force Sync Finished',
                        'Edge Router force sync finished successfully.'
                    )
                );
            }
        })
    }

    timeoutForceSync() {
        this.syncClicked = true;
        setTimeout(() => {
            this.forceSync()
        }, 3000);
    }

    refreshSoftwareDeploymentState() {
        this.edgeRouterService.refreshERSoftwareDeploymentState(this.model.softwareDeploymentStateId).subscribe((result) => {
            this.refreshClicked = true;
            this.disabled = true;
            this.growlerService.show(
                new GrowlerData(
                    'success',
                    'Success',
                    'Software Agent Refresh Started',
                    'Edge Router Software Agent was refreshed, check back in a few minutes for result'
                )
            );
            return result;
        })
    }

    providerChanged(provider) {
        if (!this.model) {
            this.selection = 'netfoundryHosted';
        }
        if (this.bulkEdit && this.model.provider === 'CUSTOMER') {
            this.selection = 'customerHosted';
            this.disableNFHosted();
        } else {
            this.selection = 'netfoundryHosted';
            this.regionService.findRegionsByProvider(provider).subscribe((regions) => {
                this.displayedRegions = regions;
            });
        }
    }

    getEdgeRouterRegKey() {
        this.edgeRouterService.getRegistrationKey(this.model.id).subscribe(
            (value) => {
                copy(value.registrationKey);
                this.growlerService.show(
                    new GrowlerData(
                        'success',
                        'Success',
                        'Reg Key Copied',
                        'The reg key ' + value.registrationKey + ' has been copied to your clipboard.'
                    )
                );
            },
            (err: HttpErrorResponse) => {
                this.growlerService.show(new GrowlerData('error', 'Click to copy request failed.', err.error));
            }
        );
    }

    async validate() {
        this.errors = {};
        this.attributeError = false;
        if (!this.wssAllowed) delete this.model.wssListener;
        if (!this.model.id) {
            const errors = await this.edgeRouterService.validateCreate(this.model);
            if (!_.isArray(errors)) {
                return false;
            }
            if (_.find(errors, (e) => e.path === '$.name')) {
                this.errors['name'] = _.find(errors, (e) => e.path === '$.name').message;
            }
            if (_.find(errors, (e) => e.path === '$.attributes')) {
                this.attributeError = true;
                this.errors['attributes'] = _.find(errors, (e) => e.path === '$.attributes').message;
            }
            if (this.selection === 'netfoundryHosted' && _.find(errors, (e) => e.path === '$.provider')) {
                this.errors['provider'] = _.find(errors, (e) => e.path === '$.provider').message;
            }
            if (this.selection === 'netfoundryHosted' && _.find(errors, (e) => e.path === '$.region')) {
                this.errors['region'] = _.find(errors, (e) => e.path === '$.region').message;
            }
            if (
                this.enableAltDomain &&
                _.isEmpty(this.model.alternateDomainName) &&
                this.selection !== 'customerHosted'
            ) {
                errors.push({
                    path: '$.alternateDomainName',
                    message: 'Cannot be empty. Enter a valid domain or turn off Custom DNS',
                });
                this.errors['alternateDomainName'] = 'Cannot be empty. Enter a valid domain or turn off Custom DNS';
            } else if (_.find(errors, (e) => e.path === '$.alternateDomainName')) {
                this.errors['alternateDomainName'] = _.find(errors, (e) => e.path === '$.alternateDomainName').message;
            }
            return errors.length === 0;
        } else {
            const errors = await this.edgeRouterService.validateUpdate(this.model.id, this.model);
            if (!_.isArray(errors)) {
                return false;
            }
            if (_.find(errors, (e) => e.path === '$.name')) {
                this.errors['name'] = _.find(errors, (e) => e.path === '$.name').message;
            }
            if (_.find(errors, (e) => e.path === '$.attributes')) {
                this.attributeError = true;
                this.errors['attributes'] = _.find(errors, (e) => e.path === '$.attributes').message;
            }
            if (this.selection === 'netfoundryHosted' && _.find(errors, (e) => e.path === '$.provider')) {
                this.errors['provider'] = _.find(errors, (e) => e.path === '$.provider').message;
            }
            if (this.selection === 'netfoundryHosted' && _.find(errors, (e) => e.path === '$.region')) {
                this.errorHostProvider = true;
                this.errors['region'] = _.find(errors, (e) => e.path === '$.region').message;
            }
            if (_.find(errors, (e) => e.path === '$.alternateDomainName')) {
                this.errors['alternateDomainName'] = _.find(errors, (e) => e.path === '$.alternateDomainName').message;
            }
            return errors.length === 0;
        }
    }

    reissueJWT() {
        this.edgeRouterService.resetToken(this.model.id).subscribe((data: any) => {
            this.growlerService.show(
                new GrowlerData(
                    'success',
                    'Success',
                    'Reissue Successful',
                    'Reissue token process was started successfully <a href="' +
                        this.router.createUrlTree(['/process-executions']).toString() +
                        '">Click here to find out more.</a>'
                )
            );
            this.hide(data);
        });
    }

    share() {
        if (this.canShareEdgeRouter) {
            this.zitiShareService.show(new ZShareData('edgeRouter', null, this.model));
        }
    }

    toggleEnabled() {
        this.model.enabled = !this.model.enabled;
    }

    toggleAlternateDomain() {
        this.enableAltDomain = !this.enableAltDomain;
    }

    toggleLinkListener() {
        this.model.linkListener = !this.model.linkListener;
        if (this.isEditing && this.model.provider !== 'CUSTOMER') {
            this.linkListenerChanged = this.model.linkListener !== this.initalModel.linkListener;
        }
    }

    toggleNoTraversal() {
        if (this.isHosted) {
            this.model.noTraversal = false;
            return;
        }
        this.model.noTraversal = !this.model.noTraversal
    }

    toggleWssListener() {
        if (!this.isHosted) {
            this.model.wssListener = false;
            return;
        }
        this.model.wssListener = !this.model.wssListener;
    }

    disableCustomerHosted() {
        this.isHosted = true;
        this.model.provider = 'AWS';
        this.model.linkListener = true;
        this.model.noTraversal = false;
        this.validate();
    }

    disableNFHosted() {
        this.model.wssListener = false;
        this.isHosted = false;
        this.model.locationCode = undefined;
        this.model.provider = undefined;
        this.model.linkListener = false;
        this.model.noTraversal = true;
        this.validate();
    }

    getMetrics() {
        this.dialogForm.open(MetricsModalComponent, {
            data: {
                resourceType: 'edge-router',
                model: this.model,
                networkGroupId: this.currentNetwork.networkGroupId,
                networkId: this.currentNetwork.id,
            },
            height: '800px',
            width: '1200px',
            autoFocus: false,
        });
    }

    getEventsData() {
        this.dialogForm.open(EventsModalComponent, {
            data: {
                resourceType: 'edgeRouter',
                model: this.model,
                networkId: this.currentNetwork.id,
                networkGroupId: this.networkGroupId,
            },
            height: '620px',
            width: '1050px',
            autoFocus: false,
        });
    }

    endpointSelected(name: string) {
        this.dialogRef.close();
        const service = this.endpointMap[name];
        this.dialogForm.open(this.endpointDialog, {
            data: { model: service, inline: true },
            minHeight: '93%',
            minWidth: '100%',
            height: '93%',
            width: '100%',
        });
    }

    serviceSelected(name: string) {
        const service = this.serviceMap[name];
        let dialog = this.serviceDialog;
        if (service.modelType === 'AdvancedTunnelerToEndpoint') {
            dialog = this.advServiceDialog;
        } else if (service.modelType === 'Generic') {
            dialog = this.configServiceDialog;
        }
        this.dialogForm.open(dialog, {
            data: { model: service, inline: true },
            minHeight: '93%',
            minWidth: '100%',
            height: '93%',
            width: '100%',
        });
    }

    findAssociatedEntities() {
        this.isLoadingAssocServices = true;

        this.edgeRouterService
            .findAssociatedServices(this.model.id)
            .then((recs) => {
                this.assocServices = recs.map((s) => s.name);
                recs.forEach((rec) => {
                    this.serviceMap[rec.name] = rec;
                });
            })
            .finally(() => {
                this.isLoadingAssocServices = false;
            });
        this.isLoadingAssocEndpoints = false;
        this.edgeRouterService
            .findAssociatedEndpoints(this.model.id)
            .then((recs) => {
                this.assocEndpoints = recs.map((s) => s.name);
                recs.forEach((rec) => {
                    this.endpointMap[rec.name] = rec;
                });
            })
            .finally(() => {
                this.isLoadingAssocEndpoints = false;
            });
    }

    getSelectedEdgeRouters() {
        const tmpCollectorMap1 = new PagedAttributes();
        this.model.attributes.forEach((attribute) => {
            tmpCollectorMap1.mappedAtrributes.set(attribute, { name: attribute, isGroup: true });
        });
        this.selectedEdgeRouterAttributes = tmpCollectorMap1;
    }

    addEdgeRouterAttribute(newAttribute) {
        const success = this.attributeService.addAttributes(
            newAttribute,
            this.invalidWords,
            this.selectedEdgeRouterAttributes
        );
        this.model.attributes = Array.from(this.selectedEdgeRouterAttributes.mappedAtrributes.keys());
        this.validate();
        if (success) this.selectedEdgeRouterAttributes = success as PagedAttributes;
        else {
            this.edgeRouterError = true;
            this.errors['attributes'] = `attribute name not allowed: ${newAttribute}`;
        }
    }

    removeEdgeRouterAttribute(oldAttribute) {
        this.selectedEdgeRouterAttributes = this.attributeService.removeAttribute(
            oldAttribute,
            this.selectedEdgeRouterAttributes
        );
        this.model.attributes = Array.from(this.selectedEdgeRouterAttributes.mappedAtrributes.keys());
        this.validate();
    }

    private initModel() {
        if (!this.model.attributes) {
            this.model.attributes = [];
        }
        this.model.linkListener = true;
        this.isInline = this.data.inline;
        if (this.data.wssEnable) this.model.wssListener = true;

        if (this.data.model) {
            this.initalModel = _.cloneDeep(this.data.model);
            this.model = this.data.model;
            this.bulkEdit = this.data.bulkEdit;
            this.isEditing = !this.bulkEdit;
            this.edgeRouterService
                .findManagedEndpoints(this.model.zitiId)
                .toPromise()
                .then((recs) => {
                    this.managedEndpoints = recs.map((s) => s.name);
                    this.isRouterManaged = recs[0]?.typeId === 'Router';
                });

            if (this.bulkEdit && this.model.provider === 'CUSTOMER') {
                this.selection = 'customerHosted';
            }

            Object.defineProperty(this, 'model', {
                get: function () {
                    return this._model;
                },
                set: function (value) {
                    this._model = value;
                },
            });
            this.model = this.data.model;

            if (!this.data.bulkEdit) {
                this.model.dataCenterId = _.get(this.model, '_embedded.host.dataCenterId');

                const hostProvider = _.get(this.model, '_embedded.host.provider');

                this.isHosted = hostProvider !== 'CUSTOMER';
                // this.bulkEdit = data.bulkEdit;
                // this.isEditing = !this.bulkEdit;
                this.canEdit = this.authorizationService.canUpdateEdgeRouter(this.model.id);
                this.canShareEdgeRouter =
                    this.authorizationService.canUpdateEdgeRouter(this.model.id) &&
                    this.model.provider === 'CUSTOMER' &&
                    this.model.jwt !== null;
            }

            if (this.model.registration) {
                if (moment() > moment(this.model.registration.expiresAt)) {
                    this.jwtExpired = true;
                } else {
                    this.jwtExpiration = moment(this.model.registration.expiresAt).local().format('M/D/YY h:mm a');
                }
            }
        }
    }

    private initializeEdgeRouterSelector() {
        this.isLoadingEdgeRouterAttr = true;
        this.getSelectedEdgeRouters();
        this.edgeRouterError = false;
        this.attributeService
            .getEdgeRouterAttributes(this.currentNetwork, true)
            .then((results: AttributesServiceResults) => {
                this.edgeRouterAttributes = results.groupAttributes;
            })
            .catch((err) => {
                this.edgeRouterError = true;
                this.logger.error(err);
            })
            .finally(() => {
                this.isLoadingEdgeRouterAttr = false;
                this.isLoading = this.isLoadingEdgeRouterAttr;
            });
    }
}
