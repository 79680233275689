import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ClickOutsideModule } from 'ng-click-outside';
import { FormsModule } from '@angular/forms';
import { UiPipesModule } from '@netfoundry-ui/ui/pipes';
import { ItemSelectorComponent } from "./item-selector.component";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
    imports: [CommonModule, RouterModule, ClickOutsideModule, FormsModule, UiPipesModule, MatTooltipModule],
    declarations: [ItemSelectorComponent],
    exports: [ItemSelectorComponent],
})
export class FeatureItemSelectorModule {}
