import { InjectionToken } from '@angular/core';

export const ENVIRONMENT = new InjectionToken('ENVIRONMENT');
export const _VERSION = new InjectionToken('_VERSION');

export class Environment {
    readonly domain: string;
    readonly disableV6: boolean;
    readonly production: boolean;
    readonly originDomain: string;
    readonly originPort: boolean;
    readonly gatewayUrl: string;
    readonly apiUrl: string;
    readonly clientSyncsApiUlr: string;
    readonly netflowUrl: string;
    readonly downloadsURL: string;
    readonly customerURL: string;
    readonly appsURL: string;
    readonly bannerURL: string;
    readonly elasticConfig: {
        usePublicApi: boolean;
        reportingServiceEnabled: boolean;
        reportingServiceUrl: string;
        reportingTemplateServiceUrl: string;
    };
    readonly defaultNetworkConfigName: string;
    readonly defaultNetworkConfigMetadataId: string;
    readonly defaultLocationCode: string;
    readonly authconfig: {
        readonly clientID: string;
        readonly domain: string;
        readonly callbackURL: string;
        readonly newSignupRedirect: string;
        // Namespace for where the organization ID is stored in the auth token
        readonly jwtOrgKey: string;
        readonly audience: string;
    };
    readonly identityConfig: {
        readonly url: string;
        readonly invitationUrl: string;
        readonly intermediateReturnUrl: string;
        readonly registrationReturnUrl: string;
        readonly loginReturnUrl: string;
    };
    readonly billingConfig: {
        // this is subject to change
        readonly billingUrl: string;
        // stripe production environment
        readonly stripe: string;
    };
    // temporary property for the how long the session timeout should be. This should get replaced once the API supports session timeout
    readonly defaultSessionTimeoutSeconds: number;
    // temporary property for how long before the session timeout the user should be warned about the timeout
    readonly defaultTimeoutWarningSeconds: number;
    // two hour timeout for endpoint session users
    readonly endpointSessionTimeoutSeconds: number;
    readonly pageSize: number;
    // list of comma separated email ids of test robots. If the current profile's email id is matching with any of the given test user
    // email ids, then some of the functionalities will be hidden or diabled.
    readonly internalTestUserEmailIds: string;
    readonly authorizationUrl: string;
    readonly reportingUrl: string;
    readonly gtmLink: string;
    readonly v2apiUrl: string;
    readonly v3apiUrl: string;
    readonly notificationUrl: string;
    readonly registrationIp: string;
    // TODO: remove when MOP can handle custom timeouts
    readonly pldtTimeoutSeconds: number;
    readonly pldtId: string;
    readonly defaultSize: string;
    readonly chargebeeConfig: {
        // this is subject to change
        readonly site: string;
        // chargebee test environment
        readonly publishableKey: string;
    };
    disableLogRocket?: boolean;
    readonly sampleServiceUrl: string;
    kibanaURL: string;
    isCloudZiti: boolean;
    v3Enabled: boolean;
    hsId: string;
    hideSubmitTicket: boolean;
    hideZiggy: boolean;
    defaultBranding: any;
    defaultTheme: string;
    showDefaultLogo: boolean;
    enableCustomLoadingIndicator: boolean;
    titleName: string;
}
