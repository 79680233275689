<div [hidden]="!isLoading" id="Loading" *ngIf="showBrandedLoader">
    <div class="grid-row">
        <div class="col">
            <div class="loader">
                <div class="nfloader">
                    <ng-lottie *ngIf="!environment.enableCustomLoadingIndicator" [options]="loadingIndicatorOptions"></ng-lottie>
                    <ng-lottie *ngIf="environment.enableCustomLoadingIndicator && showNfLoadingIndicator" [options]="nfLoadingIndicatorOptions"></ng-lottie>
                    <ng-lottie *ngIf="environment.enableCustomLoadingIndicator && !showNfLoadingIndicator" [options]="neutralLoadingIndicatorOptions"></ng-lottie>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loader *ngIf="!showBrandedLoader" [isLoading]="isLoading"></app-loader>
