import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TableHeaderDefaultComponent } from '@netfoundry-ui/feature/data-table';
import { OrganizationPromptComponent } from '@netfoundry-ui/feature/form/organization-prompt';
import { CsvDownloadService, TableFilterService } from '@netfoundry-ui/feature/shared-services';
import { UserformComponent } from '@netfoundry-ui/page/identities';
import { AuthorizationService, IamService } from '@netfoundry-ui/shared/authorization';
import { Environment, ENVIRONMENT, Tenant, OidcIdentityAudienceMap } from '@netfoundry-ui/shared/model';
import { ApiService, FeatureService, LoggerService, OidcIdentityAudienceMapService, RefresherService} from '@netfoundry-ui/shared/services';
import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { GrowlerService, GrowlerData } from '@netfoundry-ui/shared/growler';

const columns = ['name', 'siteUrl', 'isActive'];
const filename = 'audiences';
const translateStatus = true;

@Component({
    selector: 'app-oidc-identity-audience-map',
    templateUrl: './oidc-identity-audience-map.component.html',
    styleUrls: ['./oidc-identity-audience-map.component.scss'],
})
export class OidcIdentityAudienceMapComponent implements OnInit, OnDestroy {
    model: OidcIdentityAudienceMap;

    dialogRef;
    items = [];
    oidcIdentityAudienceMaps = [] as OidcIdentityAudienceMap[];
    openIndex = -1;
    isLoading;
    tenantId;

    allToggled = false;;

    isDeleteAction = false;

    subscription: Subscription = new Subscription();

    page = 1;
    filterString = '';
    deleting = 0;
    pageSize = 30;
    totalElements = 0;
    sorting = 'name';
    ordering = 'asc';
    private deleteMe = [];
    enableDeleteProtection;
    hideAppButton = false;
    changedPage: boolean;
    updatedOidcIdentityAudienceMap;
    filterHasChanged: boolean;
    hasSomeDeletePermission = true;
    filterApplied: any;
    showNoData: boolean;
    columnDefs;
    columnFilters: any = {

    };
    showList = true;
    public selectedIds = [];
    private deletedIds = [];
    private inDeletingStatusCount = 0;
    private openId = '';
    private noDeleteAuthCount = 0;


    constructor(
        private service: OidcIdentityAudienceMapService,
        private logger: LoggerService,
        public apiService: ApiService,
        public dialogForm: MatDialog,
        public authorizationService: AuthorizationService,
        private refresher: RefresherService,
        public filterService: TableFilterService,
        private csvDownloadService: CsvDownloadService,
        public featureService: FeatureService,
        private growlerService: GrowlerService,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {}



initTableColumns() {
    const columnFilters = this.columnFilters;
    const headerComponentParams = {
        filterType: 'TEXTINPUT',
        columnFilters,
    };
    this.columnDefs = [
        {
            colId: 'id',
            width: 350,
            minWidth: 100,
            field: 'id',
            sortColumn: this.sort.bind(this),
            headerName: 'Id',
            onCellClicked: (params) => {
                if (params?.nfTextSelected) {
                    return;
                }
                this.open(params.data);
            },
            headerComponent: TableHeaderDefaultComponent,
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
        },
        {
            colId: 'userIdentityId',
            width: 350,
            minWidth: 100,
            field: 'userIdentityId',
            sortColumn: this.sort.bind(this),
            headerName: 'User Identity Id',
            onCellClicked: (params) => {
                if (params?.nfTextSelected) {
                    return;
                }
                this.open(params.data);
            },
            headerComponent: TableHeaderDefaultComponent,
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
        },
        {
            colId: 'audience Id',
            width: 350,
            minWidth: 100,
            field: 'oidcAudienceId',
            sortColumn: this.sort.bind(this),
            headerName: 'Audience Id',
            onCellClicked: (params) => {
                if (params?.nfTextSelected) {
                    return;
                }
                this.open(params.data);
            },
            headerComponent: TableHeaderDefaultComponent,
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
        },
        {
            colId: 'subject',
            width: 350,
            minWidth: 100,
            field: 'subject',
            sortColumn: this.sort.bind(this),
            headerName: 'Subject',
            onCellClicked: (params) => {
                if (params?.nfTextSelected) {
                    return;
                }
                this.open(params.data);
            },
            headerComponent: TableHeaderDefaultComponent,
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
        },
        {
            colId: 'active',
            width: 350,
            minWidth: 100,
            field: 'active',
            sortColumn: this.sort.bind(this),
            headerName: 'Active',
            onCellClicked: (params) => {
                if (params?.nfTextSelected) {
                    return;
                }
                this.open(params.data);
            },
            headerComponent: TableHeaderDefaultComponent,
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
        },
    ];


}   
ngOnInit() {
    this.filterService.setPageSize(this.pageSize);

    // subscribing to the filter service to handle the change in page
    this.subscription.add(
        this.filterService.setPageEvent.subscribe((pageNum) => {
            this.page = pageNum;
            this.changedPage = true;
            this.isLoading = true;
            this.refresh();
        })
    );
    this.subscription.add(
        this.apiService.currentTenant.subscribe((currentTenant) => {
            if(currentTenant.id) {
                this.tenantId = currentTenant.id;
                this.isLoading = true;
                this.handlePermissionCheck()
                this.initTableColumns();
            }
        })
    )


    // subscribing to the filter service to handle search filter changes
    this.subscription.add(
        this.filterService.setFilterEvent.subscribe((filterString) => {
            this.filterString = filterString;
            this.isfilterApplied();
            this.refresh();
        })
    );




}

handlePermissionCheck() {
    if (this.authorizationService.canListOIDCAudience()) {
        this.getOidcIdentityAudienceMaps();
    } else {
        this.isLoading = false;
        this.showList = false;
        this.oidcIdentityAudienceMaps = [];
    }
}



ngOnDestroy() {
    this.columnFilters = {};
    this.subscription.unsubscribe();
    this.filterService.reset();
    this.refresher.disableRefresh();
}

create() {
//leaving this code here because we may need to edit and add option for this
}

open(item: OidcIdentityAudienceMap) {
//leaving this code here because we may need to edit and add option for this
}

refresh() {
    this.refresher.disableRefresh();
    this.handlePermissionCheck();
}


filterChanged(event) {
    if (event.columnId === 'name') {
        this.filterString = event.value;
    }
    _.set(this.columnFilters, event.columnId, event.value);
    this.isLoading = true;
    this.isfilterApplied();
    this.shouldShowNoData();
    this.refresh();
}

shouldShowNoData() {
    this.showNoData = this.oidcIdentityAudienceMaps.length <= 0 && !this.filterApplied && !this.isLoading;
}

isfilterApplied() {
    this.filterHasChanged = true;
    this.filterApplied = _.some(this.columnFilters, (value) => !_.isEmpty(_.toString(value)));
}



getOidcIdentityAudienceMaps() {

    if (this.filterString && this.page !== 1 && !this.changedPage) {
        this.changedPage = true;
        this.isLoading = true;
        this.filterService.setPage(1, true);
        this.page = 1;
    }

    this.subscription.add(
        this.filterService.setPageEvent.subscribe((pageNum) => {
            this.page = pageNum;
        })
    );

    this.service
        .findOidcIdentityAudienceMaps(this.tenantId)
        .subscribe((oidcIdentityAudienceMaps) => {
            const newTotalPages = this.service.lastPageCount;
            // if the number of pages has decreased and the user is past the last page now
            if (this.page > newTotalPages && newTotalPages !== 0) {
                // move back one page
                this.filterService.setPage(newTotalPages);
            } else {
                this.inDeletingStatusCount = 0;
                const theResult = oidcIdentityAudienceMaps as OidcIdentityAudienceMap[];
                const newHasSomeDeletePermission = false;
                this.noDeleteAuthCount = 0;

                let updatedOidcAudience;
                const updatedOidcAudienceId = this.updatedOidcIdentityAudienceMap?.id;

                let index = 0;
                const newSelectedIds = [];
                for (const oidcIdentityAudienceMap of oidcIdentityAudienceMaps as OidcIdentityAudienceMap[]) {
                    if (this.openId === oidcIdentityAudienceMap.id) {
                        this.open(oidcIdentityAudienceMap);
                        this.openId = '';
                    }
                    if (oidcIdentityAudienceMap.id === updatedOidcAudienceId) {
                        this.updatedOidcIdentityAudienceMap.selected = oidcIdentityAudienceMap['selected'];
                        const links = _.get(theResult[index], '_links');
                        _.set(this.updatedOidcIdentityAudienceMap, '_links', links);
                        theResult[index] = this.updatedOidcIdentityAudienceMap;
                    }
                    if (this.selectedIds.indexOf(oidcIdentityAudienceMap.id) > -1) {
                        newSelectedIds.push(oidcIdentityAudienceMap.id);
                        oidcIdentityAudienceMap.selected = true;
                    }
                    const actions = [];

                    // // TODO add permission checks
                    // if (
                    //     this.authorizationService.canDeleteOIDCAudience(oidcIdentityAudienceMap.id)
                    // ) {
                    //     actions.push('delete');
                    //     newHasSomeDeletePermission = true;
                    // } else {
                    //     this.noDeleteAuthCount++;
                    // }
                    // TODO add permission checks
                    // if (
                    //     this.authorizationService.canEditOIDCAudience(oidcIdentityAudienceMap.id)
                    // ) {
                    //     actions.push('update');
                    // }
                    // if (this.featureService.powerUserEnabled) {
                    //     actions.push('json');
                    // }
                    oidcIdentityAudienceMap['actionList'] = actions;

                    index ++;
                }

                this.selectedIds = newSelectedIds;
                this.updatedOidcIdentityAudienceMap = null;
                // the deleted service ids are only needed for the first refresh. After the first refresh they should no longer appear
                // if the service does appear again, then something went wrong with the delete and the status shouldn't be set to 800
                this.deletedIds = [];
                this.oidcIdentityAudienceMaps = theResult;

                this.totalElements = this.service.lastTotalCount;
                this.showList = this.totalElements > 0;

                this.filterService.setTotalElements(this.oidcIdentityAudienceMaps.length);
                this.filterService.updateTotalPages();

                // this.isDeleteAction = this.anyToggled();
                this.hasSomeDeletePermission = newHasSomeDeletePermission;

                this.isLoading = false;
                this.logger.info('retrieved oidc public client maps for network');
            }
        },
        (httpErrorResponse) => {
            this.logger.info(httpErrorResponse)
        }
    )

        .add(() => {
            this.filterHasChanged = false;
            this.isLoading = false;
            this.changedPage = false;
            this.shouldShowNoData();
        });
        this.refresher.refreshTimerId = setTimeout(() => {
            this.refresh();
        }, this.refresher.refreshInterval);
}


delete(item: OidcIdentityAudienceMap) {
//leaving this code here because we may need the delete option for this
}


toggleAll() {
//leaving this code here because we may need the delete option for this
}

toggle(item: OidcIdentityAudienceMap) {
    // item.selected = !item.selected;
    // this.enableDeleteProtection = this.featureService.enableDeleteProtection;

    // if (this.allToggled) {
    //     this.allToggled = !this.allToggled;
    // }

    // const index = this.selectedIds.indexOf(item.id);
    // if (index > -1) {
    //     this.selectedIds.splice(index, 1);
    // } else {
    //     this.selectedIds.push(item.id);
    // }
    // this.isDeleteAction = this.anyToggled();
    // if (!this.enableDeleteProtection) {
    //     this.isDeleteAction = true;
    //     this.hideAppButton = false;
    // }
    // if (this.selectedIds.length > 1 && this.enableDeleteProtection) {
    //     this.isDeleteAction = false;
    //     this.hideAppButton = true;
    // }
    // if (this.selectedIds.length === 1 && this.enableDeleteProtection) {
    //     this.isDeleteAction = true;
    //     this.hideAppButton = false;
    // }
    // if (this.selectedIds.length === 0) {
    //     this.hideAppButton = false;
    //     this.isDeleteAction = false;
    // }

    //leaving this code here because we may need the delete option for this
}

anyToggled() {
    // if (this.selectedIds.length > 0) {
    //     if (this.selectedIds.length === this.items.length) {
    //         this.allToggled = true;
    //     }
    //     return true;
    // }
    // this.allToggled = false;
    // return false;
    //leaving this code here because we may need the delete option for this
}


openConfirm(item: OidcIdentityAudienceMap) {
        // const bulletList = []
        // const deletingInfo = this.getDeletingTotal();
        // bulletList.push(deletingInfo.ids)
        // this.enableDeleteProtection = this.featureService.enableDeleteProtection;

        // const data = {
        //     title: 'Delete',
        //     appendId: 'Oidc Org To Public Client Map',
        //     subtitle: deletingInfo.deleteString,
        //     bulletList: deletingInfo.ids,
        //     icon: 'Delete',
        //     action: 'Yes',
        // };
        // this.dialogRef = this.dialogForm.open(ConfirmComponent, {
        //     data: data,
        //     height: '340px',
        //     width: '600px',
        //     autoFocus: false,
        // });
        // this.dialogRef.afterClosed().subscribe((result) => {
        //     // if the result has a property loggingOut, rather than being just a boolean value, the user is being
        //     //  logged out of the console and we should close the dialog without continuing
        //     if (result === undefined) {
        //         this.confirmed(false);
        //     } else if (result['loggingOut'] === undefined) {
        //         this.confirmed(result);
        //     }
        // });
        //leaving this code here because we may need the delete option for this
    
}

deleteSingleItem(item: OidcIdentityAudienceMap) {
    // const subtitle = 'Are you sure you would like to delete the following org to public client map:';

    // this.enableDeleteProtection = this.featureService.enableDeleteProtection;

    // const data = {
    //     title: 'Delete',
    //     appendId: 'Oidc Audience',
    //     subtitle: subtitle,
    //     bulletList: [item],
    //     icon: 'Delete',
    //     action: 'Yes',
    //     isDestructive: this.enableDeleteProtection,
    //     itemName: item.id
    // };
    // this.dialogRef = this.dialogForm.open(ConfirmComponent, {
    //     data: data,
    //     height: '340px',
    //     width: '600px',
    //     autoFocus: false,
    // });
    // this.dialogRef.afterClosed().subscribe((result) => {
    //     if (!result) {
    //         // User canceled. Do nothing.
    //     } else if (result['loggingOut'] === undefined) {
    //         this.deletedIds = [];
    //         this.service.delete(item.id).subscribe(
    //             (data) => {
    //                 this.deletedIds.push(item.id);
    //                 this.deleteMe = [];
    //                 this.isLoading = true;
    //                 this.refresh();
    //             },
    //             (error) => {
    //                 this.growlerService.show(
    //                     new GrowlerData(
    //                         'error',
    //                         'Error',
    //                         'Oidc Identity Audience Map failed to delete',
    //                         'The oidc identity audience map failed to delete from the system'
    //                     )
    //                 );
    //             }
    //         );
    //     }
    // });

    //leaving this code here because we may need the delete option for this
}


confirmDelete(item) {
    // this.toggleAll();
    // if (this.allToggled) {
    //     this.toggleAll();
    // }

    // item.selected = true;
    // const index = this.selectedIds.indexOf(item.id);
    // if (index === -1) {
    //     this.selectedIds.push(item.id);
    // }
    // this.isDeleteAction = true;
    // this.openConfirm(item);
    //leaving this code here because we may need the delete option for this
}

confirmed(event) {
    // if (event) {
    //     this.isLoading = true;
    //     this.deleting = this.selectedIds.length;
    //     for (const item of this.oidcOrgPublicClientMaps) {
    //         if (item['selected']) {
    //             this.deleteMe[this.deleteMe.length] = item;
    //         }
    //     }
    //     this.deleteNext();
    // } else {
    //     this.selectedIds = [];
    //     this.isDeleteAction = false;
    //     this.getOidcOrgPublicClientMaps();
    // }
    //leaving this code here because we may need the delete option for this
}

getDeletingTotal() {
    let total = 0;
    const ids = []

    for (const item of this.oidcIdentityAudienceMaps) {
        if (item.selected) {
            ids.push(item);
            total++;
        }
    }

    let deleteString = 'Are you sure you would like to delete ';

    if (total > 1) {
        deleteString += `these ${total} Oidc Org Public Client Map:`;
    } else {
        deleteString += 'the following Oidc Org Public Client Map:';
    }

    return {
        deleteString: deleteString,
        ids: ids,
    };
}

deleteNext() {
    this.deleting--;
    this.service.delete(this.deleteMe[this.deleting].id).subscribe(
        () => {
            this.postDelete();
        },
        () => {
            this.postDelete();
        }
    );
}

postDelete() {
    // forcing the deleted items to become untoggled
    this.toggle(this.deleteMe[this.deleting]);

    if (this.deleting === 0) {
        this.deleteMe = [];
        this.isLoading = true;
        this.getOidcIdentityAudienceMaps();
    } else {
        this.deleteNext();
    }
}

sort(sortBy) {
    if (this.sorting === sortBy) {
        if (this.ordering === 'asc') {
            this.ordering = 'desc';
        } else {
            this.ordering = 'asc';
        }
    } else {
        this.ordering = 'asc';
        this.sorting = sortBy;
    }
}

getSortClass(id) {
    if (id === this.sorting) {
        return this.ordering;
    } else {
        return '';
    }
}



}
