import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import {
    EdgeRouterRegistrationKeyV2,
    EdgeRouterV2,
    Environment,
    ENVIRONMENT,
    NetworkV2,
} from '@netfoundry-ui/shared/model';
import { HTTP_CLIENT, TokenService } from '@netfoundry-ui/shared/services';
import { catchError, Observable } from 'rxjs';
import {
    GetOption,
    HateoasResourceOperation,
    HttpMethod,
    PagedGetOption,
    PagedResourceCollection,
} from '@lagoshny/ngx-hateoas-client';
import { take, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { Router } from '@angular/router';
import { ZITI_DOMAIN_CONTROLLER } from 'ziti-console-lib';
import { EndpointServiceV2 } from './endpoint.service';
import { ZitiControllerService } from './ziti-controller.service';

@Injectable({ providedIn: 'root' })
export class EdgeRouterServiceV2 extends HateoasResourceOperation<EdgeRouterV2> {
    static defaultHttpAccept = {
        headers: { accept: 'application/hal+json' },
        pageParams: {
            page: 0,
            size: 2000,
        },
    };
    apiUrl: string;
    lastPageCount = 0;
    lastTotalCount = 0;
    provisionedStatus = 'PROVISIONED';
    componentCount = 0;

    constructor(
        @Inject(HTTP_CLIENT) private http: HttpClient,
        private tokenService: TokenService,
        public growlerService: GrowlerService,
        @Inject(ENVIRONMENT) private environment: Environment,
        private endpointService: EndpointServiceV2,
        public dialogForm: MatDialog,
        private authorizationSvc: AuthorizationService,
        private router: Router,
        @Inject(ZITI_DOMAIN_CONTROLLER) private zitiController: ZitiControllerService
    ) {
        super(EdgeRouterV2);
        this.apiUrl = environment.v3Enabled ? environment.v3apiUrl : environment.v2apiUrl;
    }

    override getResource(): Observable<EdgeRouterV2> {
        throw new Error('Do not use: see get getEndpoint');
    }

    override getPage(): Observable<PagedResourceCollection<EdgeRouterV2>> {
        throw new Error('Do not use: see getEndpoints');
    }

    getEdgeRouter(id: string, options: GetOption = {}): Promise<EdgeRouterV2 | undefined> {
        return super
            .getResource(id, { ...EdgeRouterServiceV2.defaultHttpAccept, ...options })
            .toPromise()
            .then((edgerouters) => edgerouters);
    }

    getEdgeRouterPage(options?: PagedGetOption): Promise<EdgeRouterV2[]> {
        return super
            .getPage({ ...EdgeRouterServiceV2.defaultHttpAccept, ...options })
            .pipe(
                tap((val) => {
                    this.lastPageCount = val.totalPages;
                    this.lastTotalCount = val.totalElements;
                })
            )
            .toPromise()
            .then((edgerouters) => (edgerouters ? edgerouters.resources : []));
    }

    public getConfig(edgeRouterId: string, networkControllerId?: string): Observable<string> {
        const options: any = {
            responseType: 'text',
        };
        if (networkControllerId) {
            options.params = { networkControllerId };
        }
        return this.http.get(this.apiUrl + `edge-routers/${edgeRouterId}/config`, options) as Observable<any>;
    }

    getRegistrationToke(networkControllerId: string, edgeRouterId: string) {
      const options: any = {
        responseType: 'json',
      };
      const body: any = {
        networkId: networkControllerId,
        zitiId: edgeRouterId
      };
      return this.http.post(this.apiUrl + `edge-router-registrations`, body, options);
    }

    public validateMove(id: string, provider: string, region: string, ipAddress: string) {
        const headers = new HttpHeaders().set('Accept', 'application/json').set('nf-validate', '');
        const body: any = { provider, region, ipAddress };
        return this.http
            .post(this.apiUrl + 'edge-routers/' + id + '/move', body, {
                headers: headers,
                responseType: 'json',
            })
            .toPromise();
    }

    public move(id: string, provider: string, region: string, ipAddress: string): Observable<string> {
        const options: any = {};
        const body: any = { provider, region, ipAddress };
        const url = `${this.apiUrl}edge-routers/${id}/move`;
        return this.http.post(url, body, options) as Observable<any>;
    }

    public validateUpdate(edgeRouterId: string, model: any) {
        const headers = new HttpHeaders().set('Accept', 'application/json').set('nf-validate', '');
        return this.http
            .patch(this.apiUrl + 'edge-routers/' + edgeRouterId, model, {
                headers: headers,
                responseType: 'json',
            })
            .toPromise();
    }

    public async validateCreate(model: any) {
        const headers = new HttpHeaders().set('Accept', 'application/json').set('nf-validate', '');
        return this.http
            .post(this.apiUrl + 'edge-routers', model, {
                headers: headers,
                responseType: 'json',
            })
            .toPromise();
    }

    public getRegistrationKey(
        edgeRouterId: string,
        networkControllerId?: string
    ): Observable<EdgeRouterRegistrationKeyV2> {
        const options: any = {};
        if (networkControllerId) {
            options.params = { networkControllerId };
        }
        return this.http.post(
            this.apiUrl + `edge-routers/${edgeRouterId}/registration-key`,
            options
        ) as Observable<any>;
    }

    public restart(edgeRouterId: string): Observable<string> {
        const options: any = {};
        const url = `${this.apiUrl}edge-routers/${edgeRouterId}/host/restart`;
        return this.http.post(url, options) as Observable<any>;
    }

    public restartProcess(edgeRouterId: string): Observable<string> {
        const options: any = {};
        const body: any = {};
        const url = `${this.apiUrl}edge-routers/${edgeRouterId}/process/restart`;
        return this.http.post(url, body, options) as Observable<any>;
    }

    public resizeHost(edgeRouterId: string): Observable<string> {
        const options: any = {};
        const body: any = {};
        const url = `${this.apiUrl}edge-routers/${edgeRouterId}/host/resize`;
        return this.http.post(url, body, options) as Observable<any>;
    }

    public resetToken(edgeRouterId: string): Observable<any> {
        return this.http.post(this.apiUrl + 'edge-routers/' + edgeRouterId + '/reissue', {});
    }

    public suspend(edgeRouterId: string): Observable<string> {
        const options: any = {};
        const url = `${this.apiUrl}edge-routers/${edgeRouterId}/suspend`;
        return this.http.post(url, options) as Observable<any>;
    }

    public resume(edgeRouterId: string): Observable<string> {
        const options: any = {};
        const url = `${this.apiUrl}edge-routers/${edgeRouterId}/resume`;
        return this.http.post(url, options) as Observable<any>;
    }

    public getJson(edgeRouterId: string): Observable<string> {
        const options: any = {};
        const url = `${this.apiUrl}edge-routers/${edgeRouterId}/?meta=ziti,diffZiti`;
        return this.http.get(url, options) as Observable<any>;
    }

    public resize(edgeRouterId: string, size: string): Observable<string> {
        const options: any = {};
        const body: any = { size };
        const url = `${this.apiUrl}edge-routers/${edgeRouterId}/host/resize`;
        return this.http.post(url, body, options) as Observable<any>;
    }

    
    public forceSync(edgeRouterId: string): Observable<string> {
        const options: any = {};
        const url = `${this.apiUrl}edge-routers/${edgeRouterId}/sync`;
        return this.http.patch(url, options) as Observable<any>;
    }

    public downloadToCsv(networkId: string): Observable<any> {
        const params = new HttpParams().set('networkId', networkId).set('size', this.lastTotalCount.toString());
        const headers = new HttpHeaders().set('Accept', 'text/csv');
        return this.http.get(this.apiUrl + 'edge-routers', {
            headers: headers,
            params: params,
            responseType: 'text',
        });
    }

    public findSaltEnabledEdgeRouters(ids: any[]): Observable<any> {
        const params = new HttpParams().set('id', ids.join(',')).set('embed', 'softwareDeploymentState');
        return this.http.get(this.apiUrl + 'edge-routers', {
            params: params,
            responseType: 'json',
        });
    }

    public erSoftwareDeploymentState(id: string): Observable<any> {
        const params = new HttpParams().set('id', id).set('embed', 'softwareDeploymentState');
        return this.http.get(this.apiUrl + 'edge-routers', {
            params: params,
            responseType: 'json',
        });
    }

    public refreshERSoftwareDeploymentState(id: string): Observable<any> {
        const options: any = {};
        const url = `${this.apiUrl}software-deployment-states/${id}/refresh`;
        return this.http.post(url, options) as Observable<any>;
    }

    public bulkUpgrade(edgeRouterIds: any, networkId: string, target: string) {
        return this.http.post(this.apiUrl + 'networks/' + networkId + '/upgrade', {
            target,
            includeController: false,
            edgeRouterIds,
        });
    }

    public downloadFileFormat(
        networkId: string,
        format = 'text/csv',
        params: HttpParams = new HttpParams()
    ): Observable<any> {
        params = params.set('networkId', networkId);
        const headers = new HttpHeaders().set('Accept', format);
        return this.http.get(this.apiUrl + 'edge-routers', {
            headers: headers,
            params: params,
            responseType: 'text',
        });
    }

    /**
     * Function for sharing the registration information
     */
    public share(emailParams: any) {
        return this.customQuery(HttpMethod.POST, '/share', { body: [emailParams] });
    }
    upgrade = (item: EdgeRouterV2, version: string) => {
        const body: any = {
            target: version,
        };
        this.http
            .post(`${this.apiUrl}edge-routers/${item.id}/upgrade`, body, {
                headers: this.setHeaders(),
            })
            .pipe(
                catchError((err) => {
                    const msg = new GrowlerData('error', 'Error', err.error?.errors[0] ?? err.message);
                    this.growlerService.show(msg);
                    return Promise.reject();
                }),
                tap(() => {
                    const msg = new GrowlerData('info', 'Info', `Upgrading edge router ${item.name} to ${version}`);
                    this.growlerService.show(msg);
                }),
                take(1)
            )
            .subscribe();
    };

    public findAssociatedServices(routerId: string): Promise<any> {
        if (!routerId) return Promise.resolve([]);
        const params = new HttpParams().set('accessibleEdgeRouterId', routerId);
        const headers = new HttpHeaders().set('Accept', 'application/json');
        return this.http
            .get(this.apiUrl + 'services', {
                headers: headers,
                params: params,
                responseType: 'json',
            })
            .toPromise();
    }

    public findAssociatedEndpoints(routerId: string): Promise<any> {
        if (!routerId) return Promise.resolve([]);
        const params = new HttpParams().set('accessibleEdgeRouterId', routerId);
        const headers = new HttpHeaders().set('Accept', 'application/json');
        return this.http
            .get(this.apiUrl + 'endpoints', {
                headers: headers,
                params: params,
                responseType: 'json',
            })
            .toPromise();
    }

    public findManagedEndpoints(zitiId: string): Observable<any> {
        const params = new HttpParams().set('zitiId', zitiId);
        const headers = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get(this.apiUrl + 'endpoints', {
            headers: headers,
            params: params,
            responseType: 'json',
        });
    }

    public createZitiER(model: any) {
        const zitiModel = {
            name: model.name,
            roleAttributes: model.attributes.map((attr: any) => attr.slice(1)),
            appData: {},
            isTunnelerEnabled: true,
            noTraversal: false,
            cost: 0,
            tags: {},
        };
        const zitiApiUrl = this.zitiController?.zitiSessionData?.zitiDomain + '/edge/management/v1/edge-routers';
        const headers = new HttpHeaders()
            .set('Accept', 'application/json')
            .set('zt-session', this.zitiController?.zitiSessionData?.zitiSessionId);

        return this.http
            .post(zitiApiUrl, zitiModel, {
                headers: headers,
                responseType: 'json',
            })
            .toPromise()
            .then((result: any) => result.data);
    }

    private setHeaders(): HttpHeaders {
        // getting the access token
        const tokenResponse = this.tokenService.getAccessToken();

        // value to return
        let headers;

        if (!tokenResponse?.expired && tokenResponse?.accessToken) {
            // if the token was not expired and there was an access token returned, set the headers using the access token
            headers = new HttpHeaders().set('Authorization', 'Bearer ' + tokenResponse.accessToken);
        } else {
            // if there were no headers return a new set of HTTP headers without an access token
            headers = new HttpHeaders();
        }

        if (headers != null) {
            return headers.append('Content-Type', 'application/json');
        } else {
            return headers;
        }
    }

    async getComponentCount(networkId: string) {
        const componentProms = [];
        const erProm = this.getEdgeRoutersProm(networkId);
        const epProm = this.getEndpointsProm(networkId);
        componentProms.push(erProm);
        componentProms.push(epProm);
        return Promise.all(componentProms).then((results) => {
            this.componentCount = results[0] + results[1];
            return this.componentCount;
        });
    }

    async getEndpointsProm(networkId: string): Promise<number> {
        const options = {
            params: {
                networkId: networkId,
            },
        };
        let endpointTotalElements = 0;
        return this.endpointService.getEndpointPage(options, 'id').then((endpoints) => {
            endpoints.forEach((endpoint) => {
                if (endpoint.typeId !== 'Router') {
                    endpointTotalElements++;
                }
            });
            return endpointTotalElements;
        });
    }

    async getEdgeRoutersProm(networkId: string): Promise<number> {
        const options = {
            params: {
                networkId: networkId,
                typeId: 'Router',
            },
        };
        return this.getEdgeRouterPage(options).then((edgeRouters) => edgeRouters.length);
    }

    async canCreateNetwork(
        network: NetworkV2,
        maxEndpoints: number,
        isGrowth: boolean,
        getLatestCount: boolean = true
    ) {
        if (this.authorizationSvc.canCreateEdgeRouters()) {
            const isWithinLimits = await this.checkLimits(network, maxEndpoints, isGrowth, getLatestCount);
            if (isWithinLimits) {
                if (network.status === this.provisionedStatus) {
                    return true;
                } else {
                    this.growlerService.show(
                        new GrowlerData(
                            'error',
                            'Error',
                            'Network Not Ready',
                            'The Network must be provisioned in order to add an Edge Router.'
                        )
                    );
                    return false;
                }
            }
        }
        return false;
    }

    private async checkLimits(
        network: NetworkV2,
        maxEndpoints: number,
        isGrowth: boolean,
        getLatestCount: boolean = true
    ) {
        let componentCount = this.componentCount;
        if (getLatestCount) {
            componentCount = await this.getComponentCount(network.id);
        }

        if (componentCount >= maxEndpoints && maxEndpoints !== -1) {
            let planLabel = 'Trial';
            let upgradeInstructions =
                'To add more, upgrade to a Growth Tier account by navigating to the Billing Dashboard and selecting the "Upgrade" option.';
            let afterListText = 'Would you like to go to the Billing Dashboard now?';
            let navOnConfirm = true;
            let appendId = '';
            let action = 'Yes';
            let isWarning = false;

            if (isGrowth) {
                planLabel = 'Growth';
                upgradeInstructions =
                    'To add more, upgrade to an Enterprise account by contacting support@netfoundry.io.';
                afterListText = '';
                navOnConfirm = false;
                appendId = '_growth';
                action = 'Ok';
                isWarning = true;
            } else {
                planLabel = 'Trial';
                upgradeInstructions =
                    'To add more, please reach out to our support team at support@netfoundry.io';
                navOnConfirm = false;
                action = 'Ok';
                isWarning = false;
            }

            const data = {
                title: 'Plan Limit Reached',
                appendId: `MaxComponents${appendId}`,
                subtitle: `Only ${maxEndpoints} components are allowed in a ${planLabel} Tier account. ${upgradeInstructions}`,
                icon: 'AddaNetwork',
                action: action,
                warning: isWarning,
            };
            const dialogRef = this.dialogForm.open(ConfirmComponent, {
                data: data,
                height: '340px',
                width: '600px',
                autoFocus: false,
            });
            dialogRef.afterClosed().subscribe((result: any) => {
                if (result && navOnConfirm) {
                    this.router.navigate(['/billing']);
                }
            });

            return false;
        }
        return true;
    }
}
