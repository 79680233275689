<div class="header">
    <div class="title" id="SubTitle">{{ title }}</div>
    <div *ngIf="!shortBreadCrumbs" [ngClass]="{ over: actionTitle === '' }" class="bread" id="Breadcrumb1">
        {{ tenantLabel }} <span class="secondary">/</span> {{ network.name }} <span class="secondary">/</span>
        {{ breadcrumb }}
    </div>
    <div *ngIf="shortBreadCrumbs" [ngClass]="{ over: actionTitle === '' }" class="bread" id="Breadcrumb2">
        <span class="secondary"></span> {{ breadcrumb }}
    </div>
    <div class="line"></div>
    <div
        (click)="openResourceUploadDialog()"
        *ngIf="_resourceType && this.showUploadLink()"
        class="bread upload-link"
        id="UploadLink"
    >
        {{ uploadText }}
    </div>
    <div *ngIf="tabs === 'endpoints'" class="tabs">
        <div
            *ngIf="authorizationService.canListEndpoints()"
            [ngClass]="{ selected: url === Gateways }"
            [routerLink]="Gateways"
            class="tab"
            id="ManageGatewaysTab"
        >
            Gateways
        </div>
        <div
            *ngIf="authorizationService.canListEndpoints()"
            [ngClass]="{ selected: url === Clients }"
            [routerLink]="Clients"
            class="tab"
            id="ManageClientsTab"
        >
            Clients
        </div>
        <div
            *ngIf="authorizationService.canListAzureVirtualWanSites()"
            [ngClass]="{ selected: url === Azure_Virtual_Wan_Sites }"
            [routerLink]="Azure_Virtual_Wan_Sites"
            class="tab"
            id="ManageAzureSitesTab"
        >
            Azure Virtual WAN Sites
        </div>
        <div
            *ngIf="authorizationService.canListEndpointGroups()"
            [ngClass]="{ selected: url === Groups }"
            [routerLink]="Groups"
            class="tab"
            id="ManageGroupsTab"
        >
            Endpoint Groups
        </div>
    </div>
    <div *ngIf="tabs === 'appwans'" class="tabs">
        <div
            *ngIf="authorizationService.canListServices()"
            [ngClass]="{ selected: url === Services }"
            [routerLink]="Services"
            class="tab"
            id="ManageServicesTab"
        >
            Services
        </div>
        <div
            *ngIf="authorizationService.canListAppWans()"
            [ngClass]="{ selected: url === Appwans }"
            [routerLink]="Appwans"
            class="tab"
            id="ManageAppWansTab"
        >
            AppWANs
        </div>
    </div>
    <div *ngIf="tabs === 'networks'" class="tabs">
        <!--    <div class="tab" *ngIf="!featureService.experimentalFeatures" id="ManageNetworksTab" routerLink="/networks" [ngClass]="{selected:url==='/networks'}">Manage Networks</div>-->
        <div [ngClass]="{ selected: url === '/networks' }" class="tab" id="ManageNetworksTab1" routerLink="/networks">
            Networks
        </div>
        <!--    <div-->
        <!--      *ngIf="authorizationService.canListOrganizations()"-->
        <!--      [ngClass]="{ selected: url === '/network-groups' }"-->
        <!--      class="tab"-->
        <!--      id="ManageNetworksGroupTab"-->
        <!--      routerLink="/network-groups"-->
        <!--    >-->
        <!--      Network Groups-->
        <!--    </div>-->
        <div
            *ngIf="authorizationService.canListNetworks() && featureService.disableNewNavigation"
            [ngClass]="{ selected: url === '/process-executions' }"
            class="tab"
            id="ManageNetworkProcesses"
            routerLink="/process-executions"
        >
            Network Processes
        </div>
    </div>
    <div *ngIf="tabs === 'network-groups'" class="tabs">
        <div
          *ngIf="authorizationService.canListOrganizations()"
          [ngClass]="{ selected: url === '/network-groups' }"
          class="tab"
          id="ManageNetworksGroupTab"
          routerLink="/network-groups"
        >
          Network Groups
        </div>
    </div>
    <div *ngIf="tabs === 'processes'" class="tabs">
        <div
            *ngIf="authorizationService.canListNetworks()"
            [ngClass]="{ selected: url === '/process-executions' }"
            class="tab"
            id="ManageNetworkProcesses"
            routerLink="/process-executions"
        >
            Network Processes
        </div>
    </div>
    <div *ngIf="tabs === 'controllers'" class="tabs">
        <div
            *ngIf="authorizationService.canListNetworks()"
            [ngClass]="{ selected: url === '/network-controllers' }"
            class="tab"
            id="ManageNetworkControllers"
            routerLink="/network-controllers"
        >
            Network Controllers
        </div>
        <div
        *ngIf="authorizationService.canListNetworks() && networkVersion > 7"
        [ngClass]="{ selected: url === '/network/edge-routers' }"
        class="tab"
        id="ManageEdgeRouters"
        routerLink="/infrastructure/routers"
      >
        Edge Routers
      </div>
        <div
        *ngIf="authorizationService.canListNetworks()"
        [ngClass]="{ selected: url === '/allocated-ips' }"
        class="tab"
        id="ManageAllocatedIps"
        routerLink="/allocated-ips"
    >
        Allocated Ips
    </div>
    </div>
    <div *ngIf="tabs === 'azure'" class="tabs">
        <div [ngClass]="{ selected: url === '/virtualwans' }" class="tab" id="VirtualWANsTab" routerLink="/virtualwans">
            Virtual WANs
        </div>
    </div>
    <div *ngIf="tabs === 'metrics'" class="tabs">
      <div
        *ngIf="authorizationService.canReadElasticSearch()"
        [ngClass]="{ selected: url === Metrics }"
        [routerLink]="featureService.isCloudZiti ? CZ_Metrics : Metrics"
        class="tab"
        id="Metrics"
      >
        Metrics
      </div>
      <div
        *ngIf="authorizationService.canReadElasticSearch()"
        [ngClass]="{ selected: url === Fabric_Latency }"
        [routerLink]="Fabric_Latency"
        class="tab"
        id="FabricLatency"
      >
        Fabric Latency
      </div>
      <div
        *ngIf="authorizationService.canReadElasticSearch()"
        [ngClass]="{ selected: url === Controller_Latency }"
        [routerLink]="Controller_Latency"
        class="tab"
        id="ControllerLatency"
      >
        Controller Latency
      </div>
    </div>
    <div *ngIf="tabs === 'events'" class="tabs">
        <div
            *ngIf="authorizationService.canReadElasticSearch()"
            [ngClass]="{ selected: url === Management_Events }"
            [routerLink]="Management_Events"
            class="tab"
            id="ManagementEvents"
        >
            Management Events
        </div>
        <div
            *ngIf="authorizationService.canReadElasticSearch() && apiService.productFamily === 'ZITI'"
            [ngClass]="{ selected: url === Network_Events }"
            [routerLink]="Network_Events"
            class="tab"
            id="NetworkEvents"
        >
            Network Events
        </div>

        <div
            *ngIf="authorizationService.canReadAlarms() && apiService.productFamily !== 'ZITI'"
            [ngClass]="{ selected: url === Alarms }"
            [routerLink]="Alarms"
            class="tab"
            id="AlarmLogButton"
        >
            Network Alarms
        </div>
        <div
            [ngClass]="{ selected: url === Notifications }"
            [routerLink]="Notifications"
            class="tab"
            id="NotificationsButton"
        >
            Notifications
        </div>
    </div>
    <div *ngIf="tabs === 'integrations'" class="tabs">
        <div
            *ngIf="authorizationService.canListAzureSubscriptions()"
            [ngClass]="{ selected: url === Subscriptions_VWAN }"
            [routerLink]="Subscriptions_VWAN"
            class="tab"
            id="AzureVWSubscriptionTab"
        >
            Endpoint Lifecycle
        </div>
        <div
            *ngIf="authorizationService.canListAzureSubscriptions() && networkVersion < 7"
            [ngClass]="{ selected: url === Subscriptions_VIRTUALWAN }"
            [routerLink]="Subscriptions_VIRTUALWAN"
            class="tab"
            id="AzureVirtualWanSubscriptionTab"
        >
            Azure Virtual WAN Subscriptions
        </div>
        <!-- <div class="tab" id="AADSubscriptionTab" *ngIf="authorizationService.canListClientSyncs()" [routerLink]="Subscriptions_AAD" [ngClass]="{selected:url===Subscriptions_AAD, disabled:network.id == null}">Azure Active Directory</div>
<div class="tab" id="ADSubscriptionTab" *ngIf="authorizationService.canListClientSyncs()" [routerLink]="Subscriptions_AD" [ngClass]="{selected:url===Subscriptions_AD, disabled:network.id == null}">Active Directory</div> -->
    </div>
    <div *ngIf="tabs === 'organization'" class="tabs">
        <div
            *ngIf="
                authorizationService.canUpdateColors() ||
                authorizationService.canDeleteColors() ||
                authorizationService.canUpdateLogos() ||
                authorizationService.canDeleteLogos()
            "
            [ngClass]="{ selected: url === Settings }"
            [routerLink]="Settings"
            class="tab"
            id="OrganizationSettingTab"
        >
            Organization Settings
        </div>
    </div>
    <div *ngIf="tabs === 'organizations'" class="tabs">
        <div
            [ngClass]="{ selected: url === Organizations }"
            [routerLink]="Organizations"
            class="tab"
            id="ManageOrganizationsTab"
        >
            Organizations
        </div>
    </div>
    <div *ngIf="tabs === 'billing'" class="tabs">
        <div [ngClass]="{ selected: url === Billing }" [routerLink]="Billing" class="tab" id="ManageBillingTab">
            Billing
        </div>
    </div>
    <div *ngIf="tabs === 'app-billing'" class="tabs">
        <div [ngClass]="{ selected: url === Org_Billing }" [routerLink]="App_Billing" class="tab" id="ManageBillingTab">
            Billing
        </div>
    </div>
    <div *ngIf="tabs === 'org-billing'" class="tabs">
        <div [ngClass]="{ selected: url === Org_Billing }" [routerLink]="Org_Billing" class="tab" id="ManageBillingTab">
            Billing
        </div>
    </div>
    <div *ngIf="tabs === 'support-billing'" class="tabs">
        <div [ngClass]="{ selected: url === Support_Billing }" [routerLink]="Support_Billing" class="tab" id="ManageBillingTab">
            Billing
        </div>
    </div>
    <div *ngIf="tabs === 'billing-management'" class="tabs">
        <div
            [ngClass]="{ selected: url === Payment_Profile }"
            [routerLink]="Payment_Profile"
            class="tab"
            id="PaymentProfileTab"
        >
            Payment Profile
        </div>
        <div
        *ngIf="featureService.experimentalFeatures"
        [ngClass]="{ selected: url === Account_Details }"
        [routerLink]="Account_Details"
        class="tab"
        id="AccountDetailsTab"
        >
            Account Details
        </div>
        <div
            [ngClass]="{ selected: url === Billing_Subscriptions }"
            [routerLink]="Billing_Subscriptions"
            class="tab"
            id="PageBillingSubscriptionsTab"
        >
            Subscriptions
        </div>
        <div
            [ngClass]="{ selected: url === Billing_Subscription_Usage }"
            [routerLink]="Billing_Subscription_Usage"
            class="tab"
            id="PageBillingSubscriptionUsageTab"
        >
            Data Usage
        </div>
        <div
            [ngClass]="{ selected: url === Billing_Subscription_Overages }"
            [routerLink]="Billing_Subscription_Overages"
            class="tab"
            id="PageBillingSubscriptionOveragesTab"
        >
            Overages
        </div>
    </div>
    <div *ngIf="tabs === 'app-billing-management'" class="tabs">
      <div
        [ngClass]="{ selected: url === Billing_Payment_Profile }"
        [routerLink]="Billing_Payment_Profile"
        class="tab"
        id="PaymentProfileTab"
      >
        Payment Profile
      </div>
      <div
        *ngIf="featureService.experimentalFeatures"
        [ngClass]="{ selected: url === Billing_Account_Details }"
        [routerLink]="Billing_Account_Details"
        class="tab"
        id="AccountDetailsTab"
      >
        Account Details
      </div>
      <div
        [ngClass]="{ selected: url === App_Billing_Subscriptions }"
        [routerLink]="App_Billing_Subscriptions"
        class="tab"
        id="PageBillingSubscriptionsTab"
      >
        Subscriptions
      </div>
      <div
        [ngClass]="{ selected: url === App_Billing_Subscription_Usage }"
        [routerLink]="App_Billing_Subscription_Usage"
        class="tab"
        id="PageBillingSubscriptionUsageTab"
      >
        Data Usage
      </div>
      <div
        [ngClass]="{ selected: url === App_Billing_Subscription_Overages }"
        [routerLink]="App_Billing_Subscription_Overages"
        class="tab"
        id="PageBillingSubscriptionOveragesTab"
      >
        Overages
      </div>
    </div>
    <div *ngIf="tabs === 'org-billing-management'" class="tabs">
        <div
          [ngClass]="{ selected: url === Org_Payment_Profile }"
          [routerLink]="Payment_Profile"
          class="tab"
          id="PaymentProfileTab"
        >
          Payment Profile
        </div>
        <div
          *ngIf="featureService.experimentalFeatures"
          [ngClass]="{ selected: url === Org_Account_Details }"
          [routerLink]="Org_Account_Details"
          class="tab"
          id="AccountDetailsTab"
        >
          Account Details
        </div>
        <div
          [ngClass]="{ selected: url === Org_Billing_Subscriptions }"
          [routerLink]="Org_Billing_Subscriptions"
          class="tab"
          id="PageBillingSubscriptionsTab"
        >
          Subscriptions
        </div>
        <div
          [ngClass]="{ selected: url === Org_Billing_Subscription_Usage }"
          [routerLink]="Org_Billing_Subscription_Usage"
          class="tab"
          id="PageBillingSubscriptionUsageTab"
        >
          Data Usage
        </div>
        <div
          [ngClass]="{ selected: url === Org_Billing_Subscription_Overages }"
          [routerLink]="Org_Billing_Subscription_Overages"
          class="tab"
          id="PageBillingSubscriptionOveragesTab"
        >
          Overages
        </div>
    </div>
    <div *ngIf="tabs === 'support-billing-management'" class="tabs">
      <div
        [ngClass]="{ selected: url === Support_Payment_Profile }"
        [routerLink]="Support_Payment_Profile"
        class="tab"
        id="PaymentProfileTab"
      >
        Payment Profile
      </div>
      <div
        *ngIf="featureService.experimentalFeatures"
        [ngClass]="{ selected: url === Support_Account_Details }"
        [routerLink]="Support_Account_Details"
        class="tab"
        id="AccountDetailsTab"
      >
        Account Details
      </div>
      <div
        [ngClass]="{ selected: url === Support_Billing_Subscriptions }"
        [routerLink]="Support_Billing_Subscriptions"
        class="tab"
        id="PageBillingSubscriptionsTab"
      >
        Subscriptions
      </div>
      <div
        [ngClass]="{ selected: url === Support_Billing_Subscription_Usage }"
        [routerLink]="Support_Billing_Subscription_Usage"
        class="tab"
        id="PageBillingSubscriptionUsageTab"
      >
        Data Usage
      </div>
      <div
        [ngClass]="{ selected: url === Support_Billing_Subscription_Overages }"
        [routerLink]="Support_Billing_Subscription_Overages"
        class="tab"
        id="PageBillingSubscriptionOveragesTab"
      >
        Overages
      </div>
    </div>
    <div *ngIf="tabs === 'tokens'" class="tabs">
        <div [ngClass]="{ selected: url === Tokens }" [routerLink]="Tokens" class="tab" id="ManageAPIAccessTab">
            API Accounts
        </div>
    </div>
    <div *ngIf="tabs === 'stored_secrets'" class="tabs">
        <div [ngClass]="{ selected: url === Stored_Secrets }" [routerLink]="Stored_Secrets" class="tab" id="ManageAPIAccessTab">
           SSH Keys
        </div>
    </div>
    <div *ngIf="tabs === 'oidc_audiences'" class="tabs">
        <div [ngClass]="{ selected: url === Oidc_Issuers }" [routerLink]="Oidc_Issuers" class="tab" id="ManageOidcIssuersTab">
           Oidc Issuers
        </div>
        <div [ngClass]="{ selected: url === Oidc_Audiences }" [routerLink]="Oidc_Audiences" class="tab" id="ManageAPIAccessTab">
           Oidc Audiences
        </div>
        <div [ngClass]="{ selected: url === Oidc_Identity_Audience_Maps }" [routerLink]="Oidc_Identity_Audience_Maps" class="tab" id="ManageAudienceMapTab">
            Oidc Identity Audience Maps
        </div>
        <div [ngClass]="{ selected: url === Oidc_Public_Clients}" [routerLink]="Oidc_Public_Clients" class="tab" id="ManagePublicClientsTab">
            Oidc Public Clients
        </div>
        <div [ngClass]="{ selected: url === Oidc_Public_Client_Org_Mapping }" [routerLink]="Oidc_Public_Client_Org_Mapping" class="tab" id="ManageOidcOrgPublicClientTab">
            Oidc Organization to Public Client Map
         </div>
    </div>
    <div *ngIf="tabs === 'user'" class="tabs">
        <div
            *ngIf="authorizationService.canListUserIdentities()"
            [ngClass]="{ selected: url === User }"
            [routerLink]="User"
            class="tab"
            id="ManageUserTab"
        >
            User
        </div>
    </div>
    <div *ngIf="tabs === 'users'" class="tabs">
        <div
            *ngIf="authorizationService.canListUserIdentities()"
            [ngClass]="{ selected: url === Users }"
            [routerLink]="Users"
            class="tab"
            id="ManageUsersTab"
        >
            Users
        </div>
        <div
            *ngIf="authorizationService.canListUserIdentities()"
            [ngClass]="{ selected: url === UserRoles }"
            [routerLink]="UserRoles"
            class="tab"
            id="UserRolesTab"
        >
            User Roles
        </div>
        <div
            *ngIf="authorizationService.canListInvitations()"
            [ngClass]="{ selected: url === Manage_Invitations }"
            [routerLink]="Manage_Invitations"
            class="tab"
            id="ManageInvitationsTab"
        >
            Invitations
        </div>
    </div>
    <div *ngIf="tabs === 'ca'" class="tabs">
        <div
            *ngIf="authorizationService.canListCAs() && networkVersion < 7"
            [ngClass]="{ selected: url === Certificate_Authorities }"
            [routerLink]="Certificate_Authorities"
            class="tab"
            id="ManageCertificatesTab6"
        >
            Certificate Authorities
        </div>
        <div
            *ngIf="authorizationService.canListCAs() && networkVersion >= 7"
            [ngClass]="{ selected: url === Ziti_Certificate_Authorities }"
            [routerLink]="Ziti_Certificate_Authorities"
            class="tab"
            id="ManageCertificatesTab"
        >
            Certificate Authorities
        </div>
        <div
            *ngIf="featureService.powerUserEnabled && authorizationService.canListJwtSigners() && networkVersion >= 7"
            [ngClass]="{ selected: url === JWT_Signers }"
            [routerLink]="JWT_Signers"
            class="tab"
            id="ManageJwtSignersTab"
        >
            JWT Signers
        </div>
        <div
            *ngIf="featureService.powerUserEnabled && authorizationService.canListAuthPolicies() && networkVersion >= 7"
            [ngClass]="{ selected: url === Auth_Policies }"
            [routerLink]="Auth_Policies"
            class="tab"
            id="ManageAuthPoliciesTab"
        >
            Authentication Policies
        </div>
    </div>
    <div *ngIf="tabs === 'reporting'" class="tabs">
        <div
            [ngClass]="{ selected: url === Network_Group_Summary }"
            [routerLink]="Network_Group_Summary"
            class="tab"
            id="NetworkGroupSummary"
        >
            Network Group Summary
        </div>
        <div
            [ngClass]="{ selected: url === Resource_Usage }"
            [routerLink]="Resource_Usage"
            class="tab"
            id="ResourceUsageSummary"
        >
            Resource Usage Days
        </div>
        <div [ngClass]="{ selected: url === Daily_Stats }" [routerLink]="Daily_Stats" class="tab" id="DailyStats">
            Daily Resource Stats
        </div>
        <div
            [ngClass]="{ selected: url === Daily_Endpoint_Utilization }"
            [routerLink]="Daily_Endpoint_Utilization"
            class="tab"
            id="DailyEndpointUtilization"
        >
            Daily Endpoint Utilization
        </div>
    </div>
    <div *ngIf="tabs === 'roles'" class="tabs">
        <div [ngClass]="{ selected: url === '/roles' }" class="tab" id="ManageRolesTab" routerLink="/roles">
            Roles & Permissions
        </div>
    </div>
    <div *ngIf="tabs === 'profile'" class="tabs">
        <div [ngClass]="{ selected: url === '/profile' }" class="tab" id="ManageProfileTab" routerLink="/profile">
            Profile
        </div>
        <div
            [ngClass]="{ selected: url === '/user-settings' }"
            class="tab"
            id="UserSettingsTab"
            routerLink="/user-settings"
        >
            User Settings
        </div>
        <!-- <div class="tab" id="NotificationsButton" *ngIf="networkVersion < 7"  [routerLink]="Notifications" [ngClass]="{selected:url===Notifications}" >Manage Notifications</div> -->
    </div>
    <!-- Ziti UI Area -->
    <div *ngIf="tabs === 'routers'" class="tabs">
        <div
            [ngClass]="{ selected: url === Edge_Routers }"
            [routerLink]="Edge_Routers"
            class="tab"
            id="ManageEdgeRouters"
        >
            Edge Routers
        </div>
        <div
            [ngClass]="{ selected: url === '/v7/policies' }"
            class="tab"
            id="ManageEdgeRouterPolicies"
            routerLink="/v7/policies"
        >
            Edge Router Policies
        </div>
        <div
            *ngIf="featureService.powerUserEnabled"
            [ngClass]="{ selected: url === '/v7/service-edge-router-policies' }"
            class="tab"
            id="ManageServiceEdgeRouterPolicies"
            routerLink="/v7/service-edge-router-policies"
        >
            Service Edge Router Policies
        </div>
    </div>
    <div *ngIf="tabs === 'zitiappwans'" class="tabs">
        <div
            [ngClass]="{ selected: url === Ziti_Services }"
            [routerLink]="Ziti_Services"
            class="tab"
            id="ManageZitiServices2"
        >
            Services
        </div>
        <div
            [ngClass]="{ selected: url === Ziti_AppWans }"
            [routerLink]="Ziti_AppWans"
            class="tab"
            id="ManageZitiAppWans"
        >
            AppWANs
        </div>
        <div
            *ngIf="this.featureService.powerUserEnabled"
            [ngClass]="{ selected: url === Ziti_Configs }"
            [routerLink]="Ziti_Configs"
            class="tab"
            id="ManageZitiConfigs"
        >
            Configurations
        </div>
        <div
            *ngIf="this.featureService.powerUserEnabled"
            [ngClass]="{ selected: url === Ziti_Config_Types }"
            [routerLink]="Ziti_Config_Types"
            class="tab"
            id="ManageZitiConfigTypes"
        >
            Configuration Types
        </div>
    </div>
    <div *ngIf="tabs === 'zitiservices'" class="tabs">
        <div
            [ngClass]="{ selected: url === Ziti_Services }"
            [routerLink]="Ziti_Services"
            class="tab"
            id="ManageZitiServices"
        >
            Manage Services
        </div>
    </div>
    <div *ngIf="tabs === 'zitiendpoints'" class="tabs">
        <div
            [ngClass]="{ selected: url === Ziti_Endpoints }"
            [routerLink]="Ziti_Endpoints"
            class="tab"
            id="ManageZitiEndpoints"
        >
            Endpoints
        </div>
    </div>
    <div *ngIf="tabs === 'posture-checks'" class="tabs">
        <div
            [ngClass]="{ selected: url === Posture_Checks }"
            [routerLink]="Posture_Checks"
            class="tab"
            id="ManagePostureChecks"
        >
            Posture Checks
        </div>
    </div>

    <div *ngIf="tabs === 'ziti-identities'" class="tabs">
        <div
            [ngClass]="{ selected: url === Ziti_Endpoints }"
            [routerLink]="Ziti_Endpoints"
            class="tab"
            id="ManageEdgeRouters"
        >
            Identities
        </div>
    </div>
    <div *ngIf="tabs === 'ziti-services'" class="tabs">
        <div
            [ngClass]="{ selected: url === Ziti_Services }"
            class="tab"
            id="ManageEdgeRouterPolicies"
            [routerLink]="Ziti_Services"
        >
            Services
        </div>
        <div
            *ngIf="this.featureService.powerUserEnabled"
            [ngClass]="{ selected: url === Ziti_Configs }"
            [routerLink]="Ziti_Configs"
            class="tab"
            id="ManageZitiConfigs"
        >
            Configurations
        </div>
        <div
            *ngIf="this.featureService.powerUserEnabled"
            [ngClass]="{ selected: url === Ziti_Config_Types }"
            [routerLink]="Ziti_Config_Types"
            class="tab"
            id="ManageZitiConfigTypes"
        >
            Configuration Types
        </div>
    </div>
    <div *ngIf="tabs === 'ziti-ers'" class="tabs">
        <div
            [ngClass]="{ selected: url === Edge_Routers }"
            [routerLink]="Edge_Routers"
            class="tab"
            id="ManageServiceEdgeRouterPolicies"
            routerLink="/v7/service-edge-router-policies"
        >
            Edge Routers
        </div>
    </div>
    <div *ngIf="tabs === 'ziti-policies'" class="tabs">
        <div
            [ngClass]="{ selected: url === Ziti_AppWans }"
            [routerLink]="Ziti_AppWans"
            class="tab"
            id="ManageZitiAppWans"
        >
            Service Policies (AppWANs)
        </div>
        <div
            [ngClass]="{ selected: url === '/v7/policies' }"
            class="tab"
            id="ManageEdgeRouterPolicies"
            routerLink="/v7/policies"
        >
            Edge Router Policies
        </div>
        <div
            *ngIf="featureService.powerUserEnabled"
            [ngClass]="{ selected: url === '/v7/service-edge-router-policies' }"
            class="tab"
            id="ManageServiceEdgeRouterPolicies"
            routerLink="/v7/service-edge-router-policies"
        >
            Service Edge Router Policies
        </div>
    </div>
    <div *ngIf="tabs === 'ziti-access'" class="tabs">
        <div
            [ngClass]="{ selected: url === Ziti_Services }"
            [routerLink]="Ziti_Services"
            class="tab"
            id="ManageZitiServices2"
        >
            Services
        </div>
        <div
            [ngClass]="{ selected: url === Ziti_AppWans }"
            [routerLink]="Ziti_AppWans"
            class="tab"
            id="ManageZitiAppWans"
        >
            AppWANs
        </div>
        <div
            *ngIf="this.featureService.powerUserEnabled"
            [ngClass]="{ selected: url === Ziti_Configs }"
            [routerLink]="Ziti_Configs"
            class="tab"
            id="ManageZitiConfigs"
        >
            Configurations
        </div>
        <div
            *ngIf="this.featureService.powerUserEnabled"
            [ngClass]="{ selected: url === Ziti_Config_Types }"
            [routerLink]="Ziti_Config_Types"
            class="tab"
            id="ManageZitiConfigTypes"
        >
            Configuration Types
        </div>
    </div>
    <div *ngIf="tabs === 'ziti-visibility'" class="tabs">
        <div
            [ngClass]="{ selected: url === Ziti_Services }"
            [routerLink]="Ziti_Services"
            class="tab"
            id="ManageZitiServices"
        >
            Manage Services
        </div>
    </div>
    <div *ngIf="tabs === 'ziti-endpoints'" class="tabs">
        <div
            [ngClass]="{ selected: url === Ziti_Endpoints }"
            [routerLink]="Ziti_Endpoints"
            class="tab"
            id="ManageZitiEndpoints"
        >
            Endpoints
        </div>
    </div>
    <div *ngIf="tabs === 'ziti-management' || tabs === 'ziti-posture'" class="tabs">
        <div
            [ngClass]="{ selected: url === Posture_Checks }"
            [routerLink]="Posture_Checks"
            class="tab"
            id="ManagePostureChecks"
        >
            Posture Checks
        </div>
    </div>
    <div *ngIf="tabs === 'browzer'" class="tabs">
        <div [ngClass]="{ selected: url === Browzer }" [routerLink]="Browzer" class="tab" id="ManageBrowzerApps">
            BrowZer Apps
        </div>
        <div [ngClass]="{ selected: url === Browzer_Bootstrappers }" [routerLink]="Browzer_Bootstrappers" class="tab" id="ManageBrowzerBootstrappers">
            BrowZer Bootstrappers
        </div>
    </div>
</div>
