<app-action-button
    (addEvent)="create()"
    [isDeleteAction]="isDeleteAction"
    (deleteEvent)="openConfirm($event)"
    [hidden]="true"
>
</app-action-button>

<app-loading-indicator [isLoading]="isLoading" class="load-indicator" *ngIf="isLoading" id="oidcAudiencesPage"></app-loading-indicator>
<div class="area data-table" id="ItemsArea">
    <app-noitems
        (clickEmit)="create()"
        [image]="'No_Clients'"
        [isEmpty]="showNoData"
        [typeName]="'oidc identity audience maps.'"
    ></app-noitems>

    <app-data-table
        (filterChanged)="filterChanged.bind(this)"
        *ngIf="filterApplied || (oidcIdentityAudienceMaps.length > 0 && !isLoading) || filterHasChanged || changedPage"
        [columnDefinitions]="columnDefs"
        [columnFilters]="columnFilters"
        [rowData]="oidcIdentityAudienceMaps"
        [tableId]="'oidc-identity-audience-maps'"
        [toggleItem]="toggle.bind(this)"
        [toggleAll]="toggleAll.bind(this)"
        [deleteItem]="deleteSingleItem.bind(this)"
    ></app-data-table>
</div>
