import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { IDENTITY_EXTENSION_SERVICE } from "ziti-console-lib";
import {Router} from "@angular/router";
import {ApiService} from "@netfoundry-ui/shared/services";
import {delay, isEmpty} from "lodash";
import moment from 'moment';
import {IdentitiesExtensionService} from "../identities-extension/identities-extension.service";
import {ZitiShareService} from "@netfoundry-ui/feature/ziti/ziti-share";
import {Subscription} from "rxjs";

@Component({
    selector: 'netfoundry-ui-identity-form-extension',
    templateUrl: './identity-form-extension.component.html',
    styleUrls: ['./identity-form-extension.component.scss'],
})
export class IdentityFormExtensionComponent implements OnInit, OnDestroy {
  toggleOn = false;
  showToggle = false;

  subscription = new Subscription();

  enrollmentExpired = true;
  currentNetwork: any = {};

  constructor(
    @Inject(IDENTITY_EXTENSION_SERVICE) public svc: IdentitiesExtensionService,
    private router: Router,
    private apiService: ApiService
  ) {

  }

  ngOnInit() {
    this.subscription.add(
      this.svc.identityUpdated.subscribe((formData: any) => {
        this.enrollmentExpired = !this.svc?.formData?.enrollment?.ott?.expiresAt || moment(this.svc?.formData?.enrollment?.ott?.expiresAt).isBefore();
      })
    );
    this.subscription.add(
      this.apiService.currentNetwork.subscribe((currentNetwork: any) => {
        this.currentNetwork = currentNetwork;
      })
    );
  }

  get hasEnrollmentJwt() {
    return !isEmpty(this.svc?.formData?.enrollment?.ott?.token);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
