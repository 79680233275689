import { Component, HostListener, Inject, OnInit, Renderer2, ɵComponentType } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PostureCheckInfoModalComponent } from '@netfoundry-ui/feature/posture-check-info-modal';
// import { MetricsModalComponent } from '@netfoundry-ui/feature/metrics-modal';
import { ZitiShareService } from '@netfoundry-ui/feature/ziti/ziti-share';
import {
    AppWanServiceV2,
    AttributesService,
    AuthPolicyService,
    EdgeRouterPolicyServiceV2,
    EndpointServiceV2,
    NetworkControllerService,
    PlatformServiceService,
} from '@netfoundry-ui/shared/apiv2';
import { AuthorizationService, AuthService, IamService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import {
    ADVANCED_SERVICE_DIALOG,
    APPWAN_DIALOG,
    AuthPolicy,
    CertificateAuthorityV2,
    CONFIG_SERVICE_DIALOG,
    EndpointV2,
    Environment,
    ENVIRONMENT,
    NetworkV2,
    PagedAttributes,
    ROUTER_DIALOG,
    SERVICE_DIALOG,
    ZShareData,
} from '@netfoundry-ui/shared/model';
import {
    ApiService,
    FeatureService,
    LoggerService,
    NetworkVersionService,
    ValidateService,
} from '@netfoundry-ui/shared/services';
import { FromDatePipe, SortbyPipe } from '@netfoundry-ui/ui/pipes';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { MetricsModalComponent } from '@netfoundry-ui/feature/metrics-modal';
import { EventsModalComponent } from '@netfoundry-ui/feature/events-modal';
import { DialLogsComponent } from '@netfoundry-ui/feature/dial-logs';
import { Router } from '@angular/router';
import { take, tap } from 'rxjs/operators';
import {isEmpty, last} from 'lodash';

const OTT = 'OTT';
const OTTCA = 'OTTCA';
declare const gtag;

@Component({
    selector: 'app-ziti-endpoint-form',
    templateUrl: './ziti-endpoint-form.component.html',
    styleUrls: ['./ziti-endpoint-form.component.scss'],
    providers: [FromDatePipe],
})
export class ZitiEndpointFormComponent implements OnInit {
    public model: EndpointV2 = new EndpointV2();
    invalidWords = ['#all'];
    isComplete = false;
    errorName = false;
    errorNameLength = false;
    editId = '';
    processing = false;
    hideHelp = false;
    isInline = false;
    currentNetwork: NetworkV2;
    endpointAttributes = new PagedAttributes();
    selectedEndpointAttributes = new PagedAttributes();
    isEditing = false;
    bulkEdit = false;
    isBuilding = false;
    isLoading = false;
    endpoints = [];
    provisionedString = 'has been created';
    buildingString = 'is building';
    completedTitleStatusString = this.provisionedString;
    errors = {};
    jwt;
    jwtExpired = false;
    jwtExpiration;
    canEdit = true;
    isTunnelEnabled = false;

    canShareEndpoint = false;
    jwtAssigned = false;
    jwtTimeout = 0;
    enrollmentMethod = OTT;
    certificateAuthorities = [];
    selectedCA = '';
    requireAuthSession = false;
    hadRequireAuthSession = false;
    canListUsers = false;
    users = [];
    publicDomainUrl;
    showControllerDomain = false;
    authUserIdentity = '';
    errorAuthUser = false;
    newUserString = 'NewUser';
    addingNewUser = false;
    newUserEmail;
    errorEmail = false;
    currentUserStandardRolesMap = {};
    standardRoleTypesMap = {};
    currentUserStandardRoles = [];
    roleMap = {};
    availableStandardRoleTypesMap = {};
    userSelected = [];
    canCreateUsers = false;
    networkGroupId;
    appwans = [];
    appwanMap = {};
    routerMap = {};
    routers = [];
    managedRouters = [];
    services = [];
    serviceMap = {};
    isRouterManaged: boolean;
    isLoadingEndpointAttr: boolean;
    isLoadingAssocServices: boolean;
    isLoadingAssocAppwans: boolean;
    isLoadingAssocRouters: boolean;
    isLoadingManagedRouters: boolean;
    attributeError = false;
    externalIdError = false;
    authPolicyTooltip = 'Authentication Policies restrict the authentication methods available to identities.';
    authPolicies: AuthPolicy[] = [];

    private subscription: Subscription = new Subscription();
    private conflictsOnly = false;
    servicePreviewList: string[] = [];

    constructor(
        private logger: LoggerService,
        private dialogRef: MatDialogRef<ZitiEndpointFormComponent>,
        public growlerService: GrowlerService,
        public endpointServiceV2: EndpointServiceV2,
        private platformSvc: PlatformServiceService,
        public edgeRouterPolicyServiceV2: EdgeRouterPolicyServiceV2,
        public networkControllerService: NetworkControllerService,
        public appwanServiceV2: AppWanServiceV2,
        public networkVersionService: NetworkVersionService,
        private validateService: ValidateService,
        private authorizationService: AuthorizationService,
        private zitiShareService: ZitiShareService,
        private authService: AuthService,
        private apiService: ApiService,
        private iamService: IamService,
        private sortByPipe: SortbyPipe,
        public dialogForm: MatDialog,
        private fromDate: FromDatePipe,
        private router: Router,
        private attributesService: AttributesService,
        public featureService: FeatureService,
        private authPolicyService: AuthPolicyService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        @Inject(ENVIRONMENT) private environment: Environment,
        @Inject(APPWAN_DIALOG) private appwanDialog: ɵComponentType<any>,
        @Inject(SERVICE_DIALOG) private serviceDialog: ɵComponentType<any>,
        @Inject(ADVANCED_SERVICE_DIALOG) private advServiceDialog: ɵComponentType<any>,
        @Inject(CONFIG_SERVICE_DIALOG) private configServiceDialog: ɵComponentType<any>,
        @Inject(ROUTER_DIALOG) private routerDialog: ɵComponentType<any>,
        private renderer: Renderer2
    ) {}

    public getOptions(currentNetwork, embedAll = false) {
        if (!currentNetwork) {
            return {};
        }
        const params = { networkId: currentNetwork.id };
        if (embedAll) {
            params['embed'] = 'all';
        }
        return { params };
    }

    ngOnInit() {
        this.initModel();
        this.currentNetwork = this.apiService.currentNetwork.getValue();
        if (this.currentNetwork) {
            this.authPolicyService.getAllByNetworkId(this.currentNetwork.id, {}, 'id|name').then((rows) => {
                this.authPolicies = rows;
            });

            if(this.currentNetwork.status === 'PROVISIONED') {
                this.networkControllerService.getNetworkControllerPage(this.getOptions(this.currentNetwork)).then((data) => {
                    this.publicDomainUrl = data[0].publicDomainName;
                 })
            }
            this.model.networkId = this.currentNetwork.id;

            const options = this.getOptions(this.currentNetwork);
            if (this.authorizationService.canListCAs()) {
                this.currentNetwork
                    .getRelatedSubscription<CertificateAuthorityV2>('certificate-authorities', options)
                    .subscribe((certificateAuthorities) => {
                        this.certificateAuthorities = certificateAuthorities;
                    });
            }

            this.getAuthPolicyInfo();

            this.apiService.currentOrg.subscribe((networkGroup) => {
                this.networkGroupId = networkGroup.getId();
            });

            this.getEndpointAttributesList();
            this.findAssociatedEntities();

            this.canCreateUsers = this.authorizationService.canCreateUserIdentity();
            if (this.canCreateUsers) {
                this.users.push({ name: 'Add a New User', id: this.newUserString });
            }
            if (this.authorizationService.canListUserIdentities(this.apiService.theTenantIs.id)) {
                this.canListUsers = true;
                this.subscription.add(
                    this.iamService
                        .find('user-identities', { tenantId: this.apiService.theTenantIs.id })
                        .subscribe((results: any) => {
                            for (const user of this.sortByPipe.transform(results, 'lastName', 'desc')) {
                                const newUser = {
                                    name: `${user['firstName']} ${user['lastName']} - ${user['email']}`,
                                    id: user['id'],
                                };
                                this.users.push(newUser);

                                if (this.model.sessionIdentityId === user['id']) {
                                    this.userSelected.push(newUser);
                                }
                            }
                        })
                );
            }
            if (this.apiService.tutorialActive) {
                this.apiService.tutorialEndpoint.subscribe((ep: EndpointV2) => {
                    const updatedEp = _.cloneDeep(ep);
                    if (_.isEmpty(updatedEp.name)) {
                        this.model = updatedEp;
                        return;
                    }
                    this.model = _.merge(this.model, updatedEp);
                    const attrs = _.cloneDeep(this.model.attributes);
                    this.model.attributes = undefined;
                    _.delay(() => {
                        this.model.attributes = attrs;
                        if (_.isEmpty(this.model.networkId)) {
                            this.model.networkId = this.currentNetwork.id;
                        }
                        this.getEndpointAttributesList();
                    }, 200);
                });
            }
        }
    }

    hide(response?) {
        this.dialogRef.close(response);
    }

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
        this.hide();
    }

    openDownloads() {
        window.open(this.networkVersionService.getDownloadsLink() + '#zititunnelers');
    }

    openUrl(url) {
        window.open(url);
    }

    getAuthPolicyInfo() {
        if(this.isEditing && !isEmpty(this.publicDomainUrl)) {
            this.endpointServiceV2.getAuthPolicyInfoById(this.model.id).subscribe((data) => {
                if(data?.authPolicy[0]?.primary?.extJwt.allowed) {
                    this.showControllerDomain = true;
                }
            })
        }
    }

    copyPublicDomainUrl() {
        navigator.clipboard.writeText(this.publicDomainUrl);
        this.growlerService.show(
            new GrowlerData('success', 'Success', 'Copied to clipboard', 'JWT Based Auth Domain been copied to your clipboard')
        );
    }



    downloadJWT() {
        if (gtag)
            gtag('event', 'click', {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                event_category: 'endpoint',
                // eslint-disable-next-line @typescript-eslint/naming-convention
                event_label: 'downloadJWT',
                value: this.model.name + '.jwt',
            });
        const name = this.model.name + '.jwt';
        const jwt = new Blob([this.model.jwt], { type: 'text/plain;charset=utf-8' });
        saveAs(jwt, name);
    }

    getEndpointAttributesList() {
        this.isLoadingEndpointAttr = true;
        const endCollector = new PagedAttributes();
        for (const attribute of this.model.attributes) {
            endCollector.mappedAtrributes.set(attribute, { name: attribute, isGroup: true });
        }

        this.selectedEndpointAttributes = endCollector;

        let endpointCollector;
        const pend1 = this.attributesService.getGroupAttributes(this.currentNetwork, 'endpoint').catch((err) => {
            this.logger.error(err);
        });

        Promise.all([pend1])
            .then((results) => {
                endpointCollector = results[0] !== undefined ? results[0] : new PagedAttributes();
                this.endpointAttributes = endpointCollector;
            })
            .catch((err) => this.logger.error(err))
            .finally(() => {
                this.isLoadingEndpointAttr = false;
            });
    }

    async save(keyUp = false) {
        if (keyUp && this.apiService.tutorialActive) {
            return;
        }
        if (this.enrollmentMethod === OTT) {
            this.model.enrollmentMethod = { ott: true };
        } else if (this.enrollmentMethod === OTTCA) {
            this.model.enrollmentMethod = { ottca: this.selectedCA };
        }
        if (_.isEmpty(this.model.authPolicyId)) {
            this.model.authPolicyId = undefined;
        }

        if (await this.validate()) {
            if (this.requireAuthSession) {
                if (this.addingNewUser) {
                    const newIdentity = await this.createUser(this.newUserEmail);
                    if (newIdentity != null) {
                        this.inviteIdentity(newIdentity);
                        this.model['sessionIdentityId'] = newIdentity.id;
                    } else {
                        this.processing = false;
                        return;
                    }
                } else {
                    this.model['sessionIdentityId'] = this.authUserIdentity;
                }
                this.model.attributes = Array.from(this.selectedEndpointAttributes.mappedAtrributes.keys());
            } else {
                this.model.sessionIdentityId = '';
            }

            if (this.bulkEdit) {
                this.dialogRef.close({ saved: true });
            } else if (this.model.id) {
                // update
                this.endpointServiceV2.patchResource(this.model).subscribe(
                    (data: EndpointV2) => {
                        this.logger.info('Update endpoint response: ', data);
                        this.processing = false;
                        this.dialogRef.close({ updatedEndpoint: data });
                    },
                    (httpErrorResponse) => {
                        this.processing = false;
                        this.logger.error('Error from endpoint patch', httpErrorResponse);
                        this.growlerService.show(
                            new GrowlerData(
                                'error',
                                'Endpoint update request failed. ',
                                httpErrorResponse.error.errors[0]
                            )
                        );
                    }
                );
            } else {
                if (this.enrollmentMethod === OTT) {
                    this.model.enrollmentMethod = { ott: true };
                } else if (this.enrollmentMethod === OTTCA) {
                    this.model.enrollmentMethod = { ottca: this.selectedCA };
                }

                if (gtag)
                    gtag('event', 'click', {
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        event_category: 'endpoint',
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        event_label: 'create',
                        value: JSON.stringify(this.model),
                    });
                this.endpointServiceV2.createResource({ body: this.model }).subscribe(
                    (data: EndpointV2) => {
                        this.logger.info('Create network response: ', data);
                        this.model.enrollmentMethod = { ott: true };
                        this.model = data;
                        // this.networkModel = new Network(data);
                        this.processing = false;
                        this.jwtAssigned = false;
                        this.jwtTimeout = Date.now() + 5000;
                        this.isLoading = true;
                        this._checkForJWT(data.id);
                    },
                    (httpErrorResponse) => {
                        this.processing = false;
                        this.logger.info('Error from endpoint put: ', httpErrorResponse);
                        this.growlerService.show(
                            new GrowlerData('error', 'Endpoint creation failed. ', httpErrorResponse.error.errors[0])
                        );
                    }
                );
            }
        }
    }

    _checkForJWT(endpointId) {
            const currentTime = Date.now();
            this.endpointServiceV2.getEndpoint(this.model.id).then(
                (data: EndpointV2) => {
                    if (_.isEmpty(data.jwt) && !this.isComplete && currentTime < this.jwtTimeout) {
                        _.delay(() => {
                            this._checkForJWT(endpointId);
                        }, 500);
                    }  else {
                        this.model = data;
                        this.jwtAssigned = true;
                        this.isComplete = true;
                        this.canShareEndpoint = true;
                        this.isLoading = false;
                        this.apiService.setLatestEndpoint(data);
                        if (_.isEmpty(data.jwt) && currentTime >= this.jwtTimeout) {
                            this.growlerService.show(
                                new GrowlerData('error', 'Timed out waiting for enrollment data. Please check back later.')
                            );
                            this.hide(data);
                        }
                    }
                },
                (httpErrorResponse) => {
                    this.processing = false;
                    this.isLoading = false;
                    this.growlerService.show(
                        new GrowlerData('error', 'Endpoint update request failed. ', httpErrorResponse.error.errors[0])
                    );
                }
            );

    }

    async validate() {
        this.errors = {};
        this.attributeError = false;

        if (!this.model.id) {
            const errors = await this.endpointServiceV2.validateCreate(this.model);
            if (!_.isArray(errors)) {
                return false;
            }
            if (_.find(errors, (e) => e.path === '$.name')) {
                this.errors['name'] = _.find(errors, (e) => e.path === '$.name').message;
            }
            if (_.find(errors, (e) => e.path === '$.attributes')) {
                this.attributeError = true;
                this.errors['attributes'] = _.find(errors, (e) => e.path === '$.attributes').message;
            }
            if (_.find(errors, (e) => e.path === '$.externalId')) {
                this.externalIdError = true;
                this.errors['externalId'] = _.find(errors, (e) => e.path === '$.externalId').message;
            }
            return errors.length === 0;
        } else {
            const errors = await this.endpointServiceV2.validateUpdate(this.model.id, this.model);
            if (!_.isArray(errors)) {
                return false;
            }
            if (_.find(errors, (e) => e.path === '$.name')) {
                this.errors['name'] = _.find(errors, (e) => e.path === '$.name').message;
            }
            if (_.find(errors, (e) => e.path === '$.attributes')) {
                this.attributeError = true;
                this.errors['attributes'] = _.find(errors, (e) => e.path === '$.attributes').message;
            }
            if (_.find(errors, (e) => e.path === '$.externalId')) {
                this.errors['externalId'] = _.find(errors, (e) => e.path === '$.externalId').message;
            }
            return errors.length === 0;
        }
    }

    share() {
        if (this.model['jwt'] !== undefined) {
            this.zitiShareService.show(new ZShareData('endpoint', this.model));
        }
    }

    reissueJWT() {
        this.endpointServiceV2.resetToken([this.model.id]).subscribe((data: any) => {
            this.growlerService.show(
                new GrowlerData(
                    'success',
                    'Success',
                    'Reissue Successful',
                    'Reissue token process was started successfully.<a href="' +
                        this.router.createUrlTree(['/process-executions']).toString() +
                        '">Click here to find out more.</a>'
                )
            );
            this.hide(data);
        });
    }

    getAttributeOptions(type, attribute?) {
        const params = [
            { key: 'networkId', value: this.currentNetwork.id },
            { key: 'attributeType', value: type },
            { key: 'parentType', value: type },
        ];
        if (!_.isEmpty(attribute)) {
            params.push({ key: 'attribute', value: type === 'service' ? attribute : _.replace(attribute, '#', '') });
        }
        return { params: params };
    }

    getPostureData() {
        this.dialogForm.open(PostureCheckInfoModalComponent, {
            data: { endpointId: this.model.id },
            height: '620px',
            width: '1050px',
            autoFocus: false,
        });
    }

    getMetrics() {
        this.dialogForm.open(MetricsModalComponent, {
            data: {
                resourceType: 'endpoint',
                model: this.model,
                networkGroupId: this.networkGroupId,
                networkId: this.currentNetwork.id,
            },
            height: '800px',
            width: '1200px',
            autoFocus: false,
        });
    }

    getDialLogs() {
        this.dialogForm.open(DialLogsComponent, {
            data: {
                resourceType: 'endpoint',
                model: this.model,
                networkGroupId: this.networkGroupId,
                networkId: this.currentNetwork.id,
            },
            height: '800px',
            width: '1000px',
            autoFocus: false,
        });
    }

    getEventsData() {
        this.dialogForm.open(EventsModalComponent, {
            data: {
                resourceType: 'endpoint',
                model: this.model,
                networkId: this.currentNetwork.id,
                networkGroupId: this.networkGroupId,
            },
            height: '620px',
            width: '1050px',
            autoFocus: false,
        });
    }

    toggleRequireAuth() {
        this.requireAuthSession = !this.requireAuthSession;
        if (this.requireAuthSession === false) {
            this.authUserIdentity = '';
            this.addingNewUser = false;
        }
    }

    onChangeAuthUserIdentityId(userIdentityIdList) {
        if (userIdentityIdList.length > 0) {
            this.authUserIdentity = userIdentityIdList[0];
        } else {
            this.authUserIdentity = null;
            this.errorEmail = false;
        }

        if (this.authUserIdentity === this.newUserString) {
            this.addingNewUser = true;
        } else {
            this.addingNewUser = false;
            this.errorEmail = false;
        }
    }

    edgeRouterSelected(name: string) {
        this.dialogRef.close();
        const service = this.routerMap[name];
        this.dialogForm.open(this.routerDialog, {
            data: { model: service, inline: true },
            minHeight: '93%',
            minWidth: '100%',
            height: '93%',
            width: '100%',
        });
    }

    async createUser(email: string) {
        const identityModel = {
            firstName: '',
            lastName: '',
            email: '',
            tenantId: '',
        };

        identityModel.email = email;

        const splitEmail = identityModel.email.split('@');
        const nameSplit = splitEmail[0].split('.');

        const nameRegex = /[^a-zA-Z]/g;
        if (nameSplit.length > 1) {
            identityModel.firstName = nameSplit[0].replace(nameRegex, '');
            identityModel.lastName = nameSplit[1].replace(nameRegex, '');
        } else {
            identityModel.lastName = nameSplit[0].replace(nameRegex, '');
        }

        identityModel.tenantId = this.apiService.theTenantIs.id;

        return await this.iamService
            .create('user-identities', identityModel)
            .toPromise()
            .then(
                (result) => result,
                () => {
                    this.growlerService.show(
                        new GrowlerData(
                            'error',
                            'Error',
                            'Unable to Create User',
                            'There was an issue when attempting to create a new user'
                        )
                    );
                    return null;
                }
            );
    }

    serviceSelected(name: string) {
        const serviceId = this.serviceMap[name].id;
        this.platformSvc.getService(serviceId).then((svc) => {
            let dialog = this.serviceDialog;
            if (svc.modelType === 'AdvancedTunnelerToEndpoint') {
                dialog = this.advServiceDialog;
            } else if (svc.modelType === 'Generic') {
                dialog = this.configServiceDialog;
            }
            this.dialogForm.open(dialog, {
                data: { model: svc, inline: true },
                minHeight: '93%',
                minWidth: '100%',
                height: '93%',
                width: '100%',
            });
        });
    }

    appwanSelected(name: string) {
        const aw = this.appwanMap[name];
        const dialogRef = this.dialogForm.open(this.appwanDialog, {
            data: { model: aw, inline: true },
            minHeight: '93%',
            minWidth: '100%',
            height: '93%',
            width: '100%',
        });
        dialogRef.afterClosed().subscribe(() => {});
    }

    removeEndpointAttribute(oldAttribute) {
        this.selectedEndpointAttributes.mappedAtrributes.delete(oldAttribute);
        const tmpSelected = new PagedAttributes();
        tmpSelected.mappedAtrributes = this.selectedEndpointAttributes.mappedAtrributes;
        this.selectedEndpointAttributes = tmpSelected;
        this.model.attributes = Array.from(this.selectedEndpointAttributes.mappedAtrributes.keys());
        this.validate();
    }

    addEndpointsAttributes(newAttribute) {
        if (this.invalidWords.includes(newAttribute.toLowerCase())) {
            this.attributeError = true;
            this.errors['attributes'] = `attribute name not allowed: ${newAttribute}`;
            return;
        }
        this.selectedEndpointAttributes.mappedAtrributes.set(newAttribute, {
            name: newAttribute,
            isGroup: newAttribute.startsWith('#'),
            isNamed: newAttribute.startsWith('@'),
        });
        const tmpSelected = new PagedAttributes();
        tmpSelected.mappedAtrributes = this.selectedEndpointAttributes.mappedAtrributes;
        this.selectedEndpointAttributes = tmpSelected;
        this.model.attributes = Array.from(this.selectedEndpointAttributes.mappedAtrributes.keys());
        this.validate();
    }

    findAssociatedEntities() {
        this.isLoadingAssocServices = true;
        this.endpointServiceV2
            .findAssociatedServices(this.model.id, 'id|name', 'application/hal+json')
            .then((result) => {
                const recs = result?._embedded?.serviceList;
                this.services = recs.map((s) => s.name);
                recs.forEach((rec) => {
                    this.serviceMap[rec.name] = rec;
                });
            })
            .finally(() => {
                this.servicePreviewList = this.services;
                this.isLoadingAssocServices = false;
            });
        this.isLoadingAssocAppwans = true;
        this.endpointServiceV2
            .findAffectedAppwans(this.model.id, 'application/hal+json')
            .pipe(
                tap((result) => {
                    const recs = result?._embedded?.appWanList;
                    this.appwans = recs.map((s) => s.name);
                }),
                tap((result) => {
                    const recs = result?._embedded?.appWanList;
                    recs.forEach((rec) => {
                        this.appwanMap[rec.name] = rec;
                    });
                })
            )
            .toPromise()
            .finally(() => {
                this.isLoadingAssocAppwans = false;
            });
        if (this.isRouterManaged) {
            this.isLoadingManagedRouters = true;
            this.endpointServiceV2
                .findManagedRouters(this.model.zitiId)
                .pipe(
                    tap((recs) => {
                        this.managedRouters = recs.map((s) => s.name);
                    })
                )
                .toPromise()
                .finally(() => {
                    this.isLoadingManagedRouters = false;
                });
        }
    }

    private initModel() {
        if (!this.model.attributes) {
            this.model.attributes = [];
        }

        
        this.isInline = this.data.inline;

        if (this.data.model) {
            this.model = this.data.model;
            this.isRouterManaged = this.model.typeId === 'Router';
            this.bulkEdit = this.data.bulkEdit;
            this.isEditing = !this.bulkEdit;
            this.endpointServiceV2
                .findAssociatedServices(this.model.id, 'id|name', 'application/hal+json')
                .then((result) => {
                    const recs = result?._embedded?.serviceList;
                    this.services = recs.map((s) => s.name);
                    recs.forEach((rec) => {
                        this.serviceMap[rec.name] = rec;
                    });
                })
                .finally(() => {
                    this.servicePreviewList = this.services;
                });
            this.endpointServiceV2
                .findAffectedAppwans(this.model.id, 'application/hal+json')
                .pipe(
                    take(1),
                    tap((result) => {
                        const recs = result?._embedded?.appWanList;
                        this.appwans = recs.map((s) => s.name);
                    }),
                    tap((result) => {
                        const recs = result?._embedded?.appWanList;
                        recs.forEach((rec) => {
                            this.appwanMap[rec.name] = rec;
                        });
                    })
                )
                .subscribe();

            if (this.isRouterManaged) {
                this.endpointServiceV2.findAssociatedRouters(this.model.id).then((recs) => {
                    this.routers = recs.map((s) => s.name);
                    recs.forEach((rec) => {
                        this.routerMap[rec.name] = rec;
                    });
                });

                this.endpointServiceV2
                    .findManagedRouters(this.model.zitiId)
                    .pipe(
                        take(1),
                        tap((recs) => {
                            this.managedRouters = recs.map((s) => s.name);
                        })
                    )
                    .subscribe();
            }

            this.authUserIdentity = this.model.sessionIdentityId;

            this.canEdit = this.authorizationService.canUpdateEndpoint(this.model.id);
            this.isTunnelEnabled = this.model.typeId === 'Router';

            this.canShareEndpoint = this.authorizationService.canUpdateEndpoint(this.model.id);
            if (this.model['enrollments'] && this.model['enrollments'][0].ott) {
                this.enrollmentMethod = OTT;
            } else if (this.model['enrollments'] && this.model['enrollments'][0].ottca != null) {
                this.selectedCA = this.model['enrollments'][0].ottca;
            }

            if (moment() > moment(this.model.jwtExpiresAt)) {
                this.jwtExpired = true;
            } else {
                this.jwtExpiration = moment(this.model.jwtExpiresAt).local().format('M/D/YY h:mm a');
            }
        } else {
            this.model.enrollmentMethod = {};
            this.model.authPolicyId = '';
        }

        if (this.model.sessionIdentityId != null && this.model.sessionIdentityId !== '') {
            this.requireAuthSession = true;
            this.hadRequireAuthSession = true;
        }

        if (this.bulkEdit) {
            this.validate();
        }
    }

    private inviteIdentity(identity) {
        // model for the invitation using the invitation URL for the environment, the provided email, and the provided tenant ID
        const inviteModel = {
            invitationUrl: this.environment.identityConfig.invitationUrl,
            invitedEmailAddress: identity.email,
            toTenantId: this.apiService.theTenantIs.id,
            targetUserIdentityId: identity.id,
        };

        // sending the invitation
        this.iamService.create('invitations', inviteModel).subscribe(() => {
            this.logger.info('successfully sent invitation');
        });
    }

    postProcessDisplay = (elements) => {
        if (
            elements &&
            (this.data.model.interceptConflicts.length > 0 || this.data.model.loopbackConflicts.length > 0)
        ) {
            if (elements.beforeCounterEl) {
                const img = this.renderer.createElement('img');
                this.renderer.setAttribute(img, 'src', '/assets/svgs/Conflict-Intercept.svg');
                this.renderer.setStyle(img, 'width', '1.5rem');
                while (elements.beforeCounterEl.nativeElement.lastElementChild) {
                    elements.beforeCounterEl.nativeElement.removeChild(
                        elements.beforeCounterEl.nativeElement.lastElementChild
                    );
                }
                this.renderer.appendChild(elements.beforeCounterEl.nativeElement, img);
                img.addEventListener('click', this.toggleConflicted.bind(this));
            }
            if (elements.listViews) {
                elements.listViews.forEach((el) => {
                    const name = el.nativeElement.innerText;
                    if (this.data.model.interceptConflicts.length > 0) {
                        for (let idx = 0; idx < this.data.model.interceptConflicts.length; idx++) {
                            const conflict = this.data.model?.interceptConflicts[idx];
                            for (let idx2 = 0; idx2 < conflict.conflictingServiceInfo.length; idx2++) {
                                if (
                                    name === conflict.conflictingServiceInfo[idx2].fromServiceName ||
                                    name === conflict.conflictingServiceInfo[idx2].toServiceName
                                ) {
                                    this.renderer.setStyle(el.nativeElement, 'color', '#f49c00');
                                    const img = this.renderer.createElement('img');
                                    this.renderer.setAttribute(img, 'src', '/assets/svgs/Conflict-Intercept.svg');
                                    this.renderer.setStyle(img, 'width', '1.25rem');
                                    this.renderer.setStyle(img, 'float', 'right');
                                    while (el.nativeElement.lastElementChild) {
                                        el.nativeElement.removeChild(el.nativeElement.lastElementChild);
                                    }
                                    this.renderer.appendChild(el.nativeElement, img);
                                    break;
                                }
                            }
                        }
                    }
                    if (this.data.model.loopbackConflicts?.length > 0) {
                        for (let idx = 0; idx < this.data.model.loopbackConflicts.length; idx++) {
                            const conflict = this.data.model?.loopbackConflicts[idx];
                            for (let idx2 = 0; idx2 < conflict.conflictingServiceInfo.length; idx2++) {
                                if (
                                    name === conflict.conflictingServiceInfo[idx2].fromServiceName ||
                                    name === conflict.conflictingServiceInfo[idx2].toServiceName
                                ) {
                                    this.renderer.setStyle(el.nativeElement, 'color', 'red');
                                    const img = this.renderer.createElement('img');
                                    this.renderer.setAttribute(img, 'src', '/assets/svgs/Conflict-Loopback.svg');
                                    this.renderer.setStyle(img, 'width', '1.25rem');
                                    this.renderer.setStyle(img, 'float', 'right');
                                    while (el.nativeElement.lastElementChild) {
                                        el.nativeElement.removeChild(el.nativeElement.lastElementChild);
                                    }
                                    this.renderer.appendChild(el.nativeElement, img);
                                    break;
                                }
                            }
                        }
                    }
                });
            }
        }
    };

    toggleConflicted = () => {
        this.conflictsOnly = !this.conflictsOnly;
        if (this.conflictsOnly) {
            const filtered: string[] = [];
            this.services.forEach((name) => {
                if (this.data.model.interceptConflicts.length > 0) {
                    for (let idx = 0; idx < this.data.model.interceptConflicts.length; idx++) {
                        const conflict = this.data.model?.interceptConflicts[idx];
                        for (let idx2 = 0; idx2 < conflict.conflictingServiceInfo.length; idx2++) {
                            if (
                                name === conflict.conflictingServiceInfo[idx2].fromServiceName ||
                                name === conflict.conflictingServiceInfo[idx2].toServiceName
                            ) {
                                filtered.push(name);
                                break;
                            }
                        }
                    }
                }
                if (this.data.model.loopbackConflicts?.length > 0) {
                    for (let idx = 0; idx < this.data.model.loopbackConflicts.length; idx++) {
                        const conflict = this.data.model?.loopbackConflicts[idx];
                        for (let idx2 = 0; idx2 < conflict.conflictingServiceInfo.length; idx2++) {
                            if (
                                name === conflict.conflictingServiceInfo[idx2].fromServiceName ||
                                name === conflict.conflictingServiceInfo[idx2].toServiceName
                            ) {
                                if (filtered.indexOf(name) < 0) filtered.push(name);
                                break;
                            }
                        }
                    }
                }
            });

            this.servicePreviewList = filtered;
        } else {
            this.servicePreviewList = this.services;
        }
    };
}
