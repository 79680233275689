<div (mouseleave)="onMouseLeave()" [ngClass]="{ open: isOpen }" class="recent-alerts-container" *ngIf="isLoggedIn">
    <div (click)="close()" *ngIf="isOpen" class="close icon-HamburgerAppClose" id="NotificationMenuClose"></div>
    <div class="row">
        <div class="header title">{{ isV7Network ? 'Recent Alerts' : 'Notifications' }}</div>
    </div>
    <mat-tab-group *ngIf="isV7Network">
        <mat-tab label="Network Alarms">
            <div id="AlarmsList">
                <div
                    *ngFor="let item of alarmItems; let index = index"
                    [attr.data-index]="index"
                    [hidden]="alarmItems.length === 0"
                    class="network-alarm"
                >
                    <div class="alarm-icon"></div>
                    <div class="alarm-name-container alarm-detail">
                        <span
                            [ngClass]="{
                                Online: item._source.event_type === 'router-online',
                                Offline: item._source.event_type !== 'router-online'
                            }"
                            class="bubble"
                        >
                            <svg *ngIf="showLoader" class="circular" viewBox="25 25 50 50">
                                <circle
                                    class="path"
                                    cx="50"
                                    cy="50"
                                    fill="none"
                                    r="20"
                                    stroke-miterlimit="10"
                                    stroke-width="3"
                                ></circle>
                            </svg>
                        </span>
                        <div class="alarm-name">
                            ROUTER {{ item._source.event_type === 'router-online' ? 'ONLINE' : 'OFFLINE' }}
                        </div>
                    </div>
                    <div class="resource-name alarm-detail">{{ item._source.nf_edge_router_name }}</div>
                    <div class="timestamp-container alarm-detail">
                        <div class="clock-icon"></div>
                        <!--            <div class='timestamp'>{{ moment(item._source.timestamp).format(dateFormat) }}</div>-->
                        <div class="timestamp">{{ formatTime(item._source.timestamp) }}</div>
                    </div>
                </div>
                <!--<div class="network-alarm">
<div class="alarm-icon"></div>
<div class="alarm-name-container alarm-detail">
<span [ngClass]="statusClass" class="bubble Offline">
<svg *ngIf="showLoader" class="circular" viewBox="25 25 50 50">
    <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="3" stroke-miterlimit="10"></circle>
</svg>
</span>
<div class="alarm-name">Router Offline</div>
</div>
<div class="resource-name alarm-detail">Edge Router XYZ</div>
<div class="timestamp-container alarm-detail">
<div class="clock-icon"></div>
<div class="timestamp">June 2nd 2021, 08:55:08.472</div>
</div>
</div>-->
                <div [hidden]="alarmItems.length !== 0">
                    <div class="NoNotificationsText">No Alarms to Display</div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Notifications">
            <div id="NotificationsList">
                <div
                    *ngFor="
                        let item of items | tableSearch: filterString | sortby: sorting:ordering;
                        let index = index;
                        trackBy: trackByTimestamp
                    "
                    [attr.data-index]="index"
                    [hidden]="items.length === 0"
                    class="growler {{ item.level }}"
                >
                    <div class="title">{{ item.level }}</div>
                    <div class="subtitle">{{ item.subtitle }}</div>
                    <div class="time">{{ formatTime(item.timestamp) }}</div>
                    <div class="content">{{ item.content }}</div>
                    <div class="icon"></div>
                </div>
                <div [hidden]="items.length !== 0">
                    <div class="NoNotificationsText">No Error Notifications to Display</div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
    <div *ngIf="!isV7Network" id="NotificationsList">
        <div
            *ngFor="
                let item of items | tableSearch: filterString | sortby: sorting:ordering;
                let index = index;
                trackBy: trackByTimestamp
            "
            [attr.data-index]="index"
            [hidden]="items.length === 0"
            class="growler {{ item.level }}"
        >
            <div class="title">{{ item.level }}</div>
            <div class="subtitle">{{ item.subtitle }}</div>
            <div class="time">{{ formatTime(item.timestamp) }}</div>
            <div class="content">{{ item.content }}</div>
            <div class="icon"></div>
        </div>
        <div [hidden]="items.length !== 0">
            <div class="NoNotificationsText">No Error Notifications to Display</div>
        </div>
    </div>
</div>
