import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TableFilterService } from '@netfoundry-ui/feature/shared-services';
import {
    AppWanServiceV2,
    EdgeRouterPolicyServiceV2,
    EdgeRouterServiceV2,
    EndpointServiceV2,
    NETWORK_SERVICE,
    NetworkServiceV2,
    PlatformServiceService,
    ServiceServiceV2,
} from '@netfoundry-ui/shared/apiv2';
import { NetworkUpgradeService } from '@netfoundry-ui/feature-network-upgrade';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { GrowlerService } from '@netfoundry-ui/shared/growler';
import {EndpointV2, Environment, ENVIRONMENT, NetworkV2} from '@netfoundry-ui/shared/model';
import {
  ApiService,
  FeatureService,
  IdentityPreferencesService, INFRASTRUCTURE_URLS,
  LoggerService,
  URLS,
  ZITI_URLS
} from '@netfoundry-ui/shared/services';
import { TEMPLATE_SEARCH_SERVICE, TemplateSearchService } from "@netfoundry-ui/shared/apiv2";
import { FromDatePipe } from '@netfoundry-ui/ui/pipes';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ConsoleTourComponent } from '@netfoundry-ui/feature/console-tour';
import { MatDialog } from '@angular/material/dialog';
import { PagedGetOption, Sort } from '@lagoshny/ngx-hateoas-client';
import {SETTINGS_SERVICE, SettingsService, ZITI_DATA_SERVICE, ZitiDataService} from "ziti-console-lib";

@Component({
    selector: 'app-ziti-dashboard',
    templateUrl: './ziti-dashboard.component.html',
    styleUrls: ['./ziti-dashboard.component.scss'],
    providers: [FromDatePipe],
})
export class ZitiDashboardComponent implements OnInit, OnDestroy {
    currentNetwork = new NetworkV2();
    dateFilter = '24h';
    endTime: number = Date.now();
    startTime: number = this.endTime - 24 * 60 * 60 * 1000;
    lastPageCount = 0;
    lastTotalCount = 0;
    networkJwt: any;
    networkJwtLoaded = false;
    endpoints = [];
    edgeRouters = [];
    appWans = [];
    services = [];
    edgeRouterPolicies = [];
    subscription = new Subscription();
    page = 1;
    pageSize = 1;
    networksTotalElements = 0;
    serviceTotalElements = 0;
    edgeRouterTotalElements = 0;
    edgeRouterPolicyTotalElements = 0;
    appWanTotalElements = 0;
    endpointTotalElements = 0;
    filterString = '';
    paramList: { size: number; sort: Sort } = {
        size: this.pageSize,
        sort: { name: 'ASC' },
    };

    isUpgradeable = false;

    Ziti_Endpoints = URLS.ZITIENDPOINTS;
    Ziti_Routers = URLS.ZITIROUTERS;
    Edge_Router_Policies = URLS.ZITIPOLICIES;
    Network_Controllers = URLS.NETWORK_CONTROLLERS;
    Networks = URLS.NETWORKS_COMBINED;
    App_Wans = URLS.ZITIAPPS;
    Services = URLS.ZITISERVICES;
    Topology = URLS.TOPOLOGY;
    Ziti_Service_Policies = ZITI_URLS.ZITI_SERVICE_POLICIES;
    Ziti_Services = ZITI_URLS.ZITI_SERVICES;
    Ziti_Edge_Routers = ZITI_URLS.ZITI_ROUTERS;
    Ziti_Identities = ZITI_URLS.ZITI_IDENTITIES;
    Ziti_Edge_Router_Policies = ZITI_URLS.ZITI_ROUTER_POLICIES;

    dialogRef: any;

    isV8Network = false;

    constructor(
        private logger: LoggerService,
        public filterService: TableFilterService,
        public authorizationService: AuthorizationService,
        @Inject(NETWORK_SERVICE) private networkServiceV2: NetworkServiceV2,
        private serviceServiceV2: ServiceServiceV2,
        private growlerService: GrowlerService,
        public apiService: ApiService,
        public featureService: FeatureService,
        private endpointService: EndpointServiceV2,
        private edgeRouterService: EdgeRouterServiceV2,
        private edgeRouterPolicyService: EdgeRouterPolicyServiceV2,
        private appwanServiceV2: AppWanServiceV2,
        private fromDate: FromDatePipe,
        private platformServiceService: PlatformServiceService,
        private networkUpgradeService: NetworkUpgradeService,
        private identityPreferencesService: IdentityPreferencesService,
        public dialogForm: MatDialog,
        @Inject(SETTINGS_SERVICE) private settingsService: SettingsService,
        @Inject(ZITI_DATA_SERVICE) private zitiService: ZitiDataService,
        @Inject(ENVIRONMENT) public environment: Environment,
        @Inject(TEMPLATE_SEARCH_SERVICE) public templateService: TemplateSearchService
    ) {}

    ngOnInit(): void {
        this.identityPreferencesService.getPreferences().then((preferences) => {
            if (preferences?.showConsoleTour) {
                this.dialogRef = this.dialogForm.open(ConsoleTourComponent, {
                    data: {},
                    height: '455px',
                    width: '935px',
                    autoFocus: false,
                });
                this.identityPreferencesService.setPreference('showConsoleTour', false);
            }
        });
        this.subscription.add(
            this.apiService.currentNetwork.subscribe((network) => {
                this.networksTotalElements = 0;
                if (network?.id) {
                    this.networksTotalElements = 1;
                    let majorVersionNumber = this.apiService.getNetworkVersion(this.currentNetwork);
                    //TODO: For now there are no v8 networks, so hardcoding this for the v7 network in staging that was manually enabled for CloudZiti
                    majorVersionNumber = network.name === 'v8manualtest' ? 8 : majorVersionNumber;
                    this.isV8Network = majorVersionNumber === 8;
                    this.getNetworkJwt(network.id);
                    this.initData(network.id);

                    this.subscription.add(
                        this.apiService.addedFromAnywhere.subscribe(() => {
                            this.initData(network.id);
                        })
                    );
                }
            })
        );
        this.subscription.add(
            this.settingsService.settingsChange.subscribe((settings) => {
                if (!isEmpty(settings?.session?.id)) {
                    this.getZitiSummary();
                }
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    refresh() {
        this.initData(this.currentNetwork.id);
    }

    getServices() {
        this.platformServiceService.getServicesPage(this.getNewOptions()).then((services) => {
            this.serviceTotalElements = this.platformServiceService.lastTotalCount;
            this.filterService.setTotalElements(this.serviceTotalElements);
            this.filterService.setTotalElements(this.platformServiceService.lastTotalCount);
            return services;
        });
    }

    getEndpoints() {
        this.endpointService.getEndpointPage(this.getNewOptions()).then((endpoints: EndpointV2[]) => {
            this.endpointTotalElements = this.endpointService.lastTotalCount;
            this.filterService.setTotalElements(this.endpointTotalElements);
            this.filterService.setTotalElements(this.endpointService.lastTotalCount);
            return endpoints;
        });
    }

    getEdgeRouterPolicies() {
        this.edgeRouterPolicyService.getEdgeRouterServicePolicyPage(this.getOptions()).then((edgeRouterPolicies) => {
            this.edgeRouterPolicyTotalElements = this.edgeRouterPolicyService.lastTotalCount;
            this.filterService.setTotalElements(this.edgeRouterPolicyTotalElements);
            this.filterService.setTotalElements(this.edgeRouterPolicyService.lastTotalCount);
            return edgeRouterPolicies;
        });
    }

    getEdgeRouters() {
        this.edgeRouterService.getEdgeRouterPage(this.getNewOptions()).then((edgeRouters) => {
            this.edgeRouterTotalElements = this.edgeRouterService.lastTotalCount;
            this.filterService.setTotalElements(this.edgeRouterTotalElements);
            this.filterService.setTotalElements(this.edgeRouterService.lastTotalCount);
            return edgeRouters;
        });
    }

    getAppWans() {
        this.appwanServiceV2.getAppWanPage(this.getOptions()).then((appwans) => {
            this.appWanTotalElements = this.appwanServiceV2.lastTotalCount;
            this.filterService.setTotalElements(this.appWanTotalElements);
            this.filterService.setTotalElements(this.appwanServiceV2.lastTotalCount);
            return appwans;
        });
    }

    getZitiSummary() {
        this.zitiService.get('summary', {}, []).then((data) => {
          this.appWanTotalElements = data?.data?.servicePolicies;
          this.edgeRouterTotalElements = data?.data['routers.edge'];
          this.edgeRouterPolicyTotalElements = data?.data?.edgeRouterPolicies;
          this.endpointTotalElements = data?.data?.identities;
          this.serviceTotalElements = data?.data['services.edge'];
        });
    }

    /**
     * Updates the observables for starttime / endtime
     * @param value
     */
    public setStartTime(value: string) {
        this.startTime = this.fromDate.transform(value);
        this.endTime = Date.now();

        // if the filter is 1m, 6m, or 12m, we should display from the start of one month to the end of another
        if (value === '1m' || value === '6m' || value === '12m') {
            // the start time should be set to the start of the first month by the fromDate pipe
            //  setting teh end time to the end of the current month manually
            this.endTime = moment(this.endTime).endOf('month').valueOf();
        }
        this.dateFilter = value;
    }

    /**
     * Updates the observables for starttime / endtime
     * @param value
     */
    public setEndTime(value: string) {
        // TODO - process date field into ms number
        this.endTime = this.fromDate.transform(value);
    }

    getNewOptions() {
        const options: PagedGetOption = {
            params: {
                networkId: this.currentNetwork.id,
                isSystem: false,
            },
            pageParams: {
                size: this.paramList['size'],
                page: this.page - 1,
            },
        };

        if (this.filterString) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            options.params['name'] = this.filterString;
        }
        return options;
    }

    getOptions(): PagedGetOption {
        const options: PagedGetOption = {
            params: {
                networkId: this.currentNetwork.id,
                isSystem: false,
            },
            pageParams: {
                size: this.paramList['size'],
                page: this.page - 1,
            },
        };

        if (this.filterString) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            options.params['name'] = `%${this.filterString}%`;
        }
        return options;
    }

    gotoDownloads(): void {
        window.open('https://netfoundry.io/downloads/', '_blank');
    }

    gotoapis(): void {
        window.open('https://developer.netfoundry.io', '_blank');
    }

    getUpgradableVersions() {
        this.isUpgradeable = this.networkUpgradeService.isUpgradeable(this.currentNetwork);
    }

    upgrade() {
        const network = JSON.parse(JSON.stringify(this.currentNetwork));
        network['upgradeableVersions'] = this.networkUpgradeService.getUpgradeableVersions(this.currentNetwork);
        this.networkUpgradeService.requestUpgrade(network);
    }

    getNetworkJwt(id: string) {
      this.networkJwtLoaded = false;
      this.networkServiceV2.downloadNetworkJwt(id).then((result: any) => {
        this.networkJwt = result?.zitiNetworkJwts[0]?.token;
      }).catch(() => {
        this.networkJwt = undefined;
      }).finally(() => {
        this.networkJwtLoaded = true;
      });
    }

    get buttonRowClass() {
      let rowClass = 'three-columns';
      if (this.environment.v3Enabled) {
        if (this.networkJwt === undefined) {
          rowClass = 'two-columns';
        } else {
          rowClass = 'three-columns';
        }
      } else {
        if (this.networkJwt === undefined) {
          rowClass = 'three-columns';
        } else {
          rowClass = 'four-columns';
        }
      }
      return rowClass;
    }

    private initData(networkId: any) {
        const options = {
          params: {
            embed: 'clusters'
          }
        };
        this.networkServiceV2.getNetwork(networkId, options).then((nw: NetworkV2) => {
            this.currentNetwork = nw;
            if (this.environment.v3Enabled) {
              return;
            }
            this.getServices();
            this.getAppWans();
            this.getEdgeRouterPolicies();
            this.getEdgeRouters();
            this.getEndpoints();
            this.getUpgradableVersions();
        });
    }
}
