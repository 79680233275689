<div *ngIf="!loginSvc.isResuming && loginInit" class="login-page">
    <ng-container *ngIf="!this.loginSvc.isVanityUrl">
        <div class="gradientbg">
            <div class="right-rect"></div>
            <div class="left-rect"></div>
        </div>
        <div class="titleImage step1"></div>
        <div class="nf-icon-container">
            <div class="nf-icon"></div>
        </div>
    </ng-container>
    <app-loader
        *ngIf="!showVanityLogout && this.loginSvc.isVanityUrl"
        [isLoading]="!showVanityLogout && this.loginSvc.isVanityUrl"
        class="load-indicator"
        id="LoginPageLoader"
    >
    </app-loader>
    <div *ngIf="showVanityLogout || !this.loginSvc.isVanityUrl" class="container">
        <div class="row">
            <div class="col-md-6 col-md-offset-3 col-sm-12" >
                <img class="logo-img" src="/assets/svgs/BlastOff.svg" [ngClass]="{hideBlastOff: environment.defaultBranding}"/>
            </div>
            <div class="col-md-6 col-md-offset-3 col-sm-12">
                <div>
                    <div *ngIf="!loginSvc.forgotSiteName; else forgotSiteNameForm" class="control-box" id="signupBox">
                        <div *ngIf="showVanityLogout" id="vanityUrlLoggedOut">
                            <h1>Successfully Logged Out</h1>
                            <h2 class="intro">Click the button below to return to the sign in page.</h2>
                            <button
                                (click)="loginSvc.returnToVanityLogin()"
                                class="btn btn-primary btn-block btn-init-login"
                                name="action"
                                type="submit"
                            >
                                Return
                            </button>
                        </div>
                        <div *ngIf="!showVanityLogout" class="login-container">
                            <h1>Let's Get Started</h1>
                            <h2 class="intro">
                                Enter the organization name you chose at signup, then enter your credentials.
                            </h2>
                            <form
                                #f="ngForm"
                                (ngSubmit)="continueLogin()"
                                *ngIf="loginSvc.isRootDomain"
                                [hidden]="loginSvc.vanityLogin"
                                class="login-form"
                                id="mainLoginForm"
                                name="form"
                                novalidate
                            >
                                <div class="label row org-name-label">
                                    Organization Name
                                    <span class="error" id="ErrorLogin" style="display: none">Invalid Credentials</span>
                                </div>
                                <div class="row">
                                    <input
                                        [(ngModel)]="loginSvc.tenantLabel"
                                        [ngClass]="{ error: loginSvc.errorSiteName, 'after-init': afterInit }"
                                        data-enter="LoginButton"
                                        id="tenantLabel"
                                        name="tenantLabel"
                                        placeholder="yoursitename"
                                        required
                                        type="text"
                                    />
                                    <div class="inputAppend">.{{ domain }}</div>
                                </div>
                                <div (click)="toggleForgotSiteName()" class="text-center">
                                    Forget your organization name?
                                </div>
                                <button class="btn btn-primary btn-block btn-init-login" name="action" type="submit">
                                    Continue
                                </button>
                            </form>
                        </div>
                    </div>
                    <ng-template #forgotSiteNameForm>
                        <form
                            #f="ngForm"
                            (ngSubmit)="f.form.valid && loginSvc.initiateSiteNameRequest()"
                            *ngIf="loginSvc.isRootDomain"
                            [hidden]="loginSvc.vanityLogin"
                            name="form"
                            novalidate
                        >
                            <div class="area middle" id="SitenameForm">
                                <div class="title row">Forget your organization name? No Worries</div>
                                <div class="subTitle row">
                                    Enter your email address below and we'll send you sites you've registered for.
                                </div>
                                <div class="label row">
                                    Email Address<span class="error" id="ErrorEmail" style="display: none"
                                        >Valid Email Required</span
                                    >
                                </div>
                                <div class="row">
                                    <input
                                        [(ngModel)]="loginSvc.emailAddress"
                                        [ngClass]="{ error: loginSvc.errorEmailAddress }"
                                        data-enter="ForgotSiteNameButton"
                                        data-private
                                        id="emailAddress"
                                        name="emailAddress"
                                        placeholder="john.smith@netfoundry.io"
                                        required
                                        type="text"
                                    />
                                </div>
                                <div class="row forgot-site-button-container">
                                    <div class="forgot-site-back">
                                        <span (click)="toggleForgotSiteName()" class="linkButton request-sitename"
                                            >Back to sign in</span
                                        >
                                    </div>
                                    <div class="forgot-site-button">
                                        <button class="save alert confirm" id="ForgotSiteNameButton">
                                            Request Organization Name
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </ng-template>
                    <div *ngIf="false && !this.loginSvc.forgotSiteName" class="label row signup-link">
                        <div class="dont">Dont have an account?</div>
                        <a id="btn-goto-signup" routerLink="/trial/">TRY FOR FREE</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer col-xs-12">
        <div>
            <a href="https://netfoundry.io/terms-and-privacy-policy/" target="_blank">Terms of Use and Privacy Policy</a>
        </div>
    </div>
</div>
