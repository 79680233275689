import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NETWORK_SERVICE, NetworkServiceV2 } from '@netfoundry-ui/shared/apiv2';
import { NetworkV2 } from '@netfoundry-ui/shared/model';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { Router } from '@angular/router';
import moment from 'moment';
import { LoggerService } from '@netfoundry-ui/shared/services';
import { take } from 'rxjs';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-publiccert-confirm-modal',
    templateUrl: './publiccert-confirm-modal.component.html',
    styleUrls: ['./publiccert-confirm-modal.component.scss'],
})
export class PublicCertConfirmModalComponent {
    model: NetworkV2;
    networkId;
    processing = false;
    subsciption: Subscription = new Subscription();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private logger: LoggerService,
        private dialogRef: MatDialogRef<PublicCertConfirmModalComponent>,
        @Inject(NETWORK_SERVICE) private networkServiceV2: NetworkServiceV2,
        public growlerService: GrowlerService,
        private router: Router
    ) {
        this.model = data;
        this.networkId = data.model.id;
    }

    close() {
        this.dialogRef.close();
    }

    hide() {
        this.dialogRef.close();
    }

    addPublicCert() {
        this.networkServiceV2.addPublicCertificate(this.networkId, true).subscribe(
            (data) => {
                this.growlerService.show(
                    new GrowlerData(
                        'success',
                        'Success',
                        'Public Certificate Addition Process Started',
                        'Public Certificate process started successfully.<a href="' +
                            this.router.createUrlTree(['/process-executions']).toString() +
                            '">Click here to find out more.</a>'
                    )
                );
                this.processing = false;
                this.dialogRef.close(data);
            },
            (httpErrorResponse) => {
                this.processing = false;
                this.logger.error('Error from service creation', httpErrorResponse);
                this.growlerService.show(
                    new GrowlerData(
                        'error',
                        'Public Certificate Addition request failed. ',
                        httpErrorResponse.error.errors[0]
                    )
                );
            }
        );
    }
}
