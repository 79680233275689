<lib-identity-form>
  <div slot="column-1-slot-1" class="share-identity-container" *ngIf="hasEnrollmentJwt && !enrollmentExpired">
    <lib-form-field-container [showHeader]="false">
      <div class="share-identity-container">
        <span class="info-text">SHARE THIS IDENTITY</span>
        <div class="download-button share-button" (click)="svc.shareRegistration(svc.formData)">
          <div class="icon-email"></div>
          <div>SEND REGISTRATION EMAIL</div>
          <div class="icon-Icon_Share"></div>
        </div>
      </div>
    </lib-form-field-container>
  </div>
</lib-identity-form>
