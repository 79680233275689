import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BillingEnabledService, BillingService } from '@netfoundry-ui/feature/shared-services';
import { Auth0Service, IamService, LOGIN_SERVICE, LoginService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { BillableRegion, Environment, ENVIRONMENT } from '@netfoundry-ui/shared/model';
import {
    ApiService,
    CustomerService,
    GetCountryService,
    HTTP_CLIENT,
    LoggerService,
    NetworkGroupService,
    ValidateService,
} from '@netfoundry-ui/shared/services';
import { StripeCardElement, StripeElements, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeService } from 'ngx-stripe';
import _ from 'lodash';
import { take } from 'rxjs/operators';
import $ from 'jquery';

const domainRegex = /^([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]{0,61}[A-Za-z0-9])$/;

@Component({
    selector: 'app-selfservice',
    providers: [BillingService],
    templateUrl: './selfservice.component.html',
    styleUrls: ['./selfservice.component.scss'],
})
export class SelfserviceComponent implements OnInit {
    keyRefresher;

    model = {
        adminUsers: [],
        label: '',
        name: '',
        identityProviders: []
    }

    signupCode;

    adminUsers: string[] = [];

    dbProvider;
    providerTypes = [];

    signupUrl = '';
    isSuccessful = false;
    processing = false;

    dialogRef;
    data = {
        adminUsers: [],
        label: '',
        name: '',
        identityProviders: []
    }

    validSubmitRegion = true;

    selectedAtLeastOneRegion = false;

    originDomain = this.environment.originDomain;
    proto = location.protocol;
    errors = {};



    billingFormSubmitted = false;

    isProd = this.environment.production && !this.environment['showCreditCardHelp'];

    customerId;
    productId;
    providerId;

    isLoading = false;

    identityProviders = [];


    constructor(
        private fb: FormBuilder,
        private growlerService: GrowlerService,
        private stripeService: StripeService,
        @Inject(LOGIN_SERVICE) private auth: LoginService,
        private iamService: IamService,
        private apiService: ApiService,
        @Inject(HTTP_CLIENT) private http: HttpClient,
        public dialogForm: MatDialog,
        public orgService: NetworkGroupService,
        public billingService: BillingService,
        public billingEnabledService: BillingEnabledService,
        private activatedRoute: ActivatedRoute,
        private logger: LoggerService,
        private customerService: CustomerService,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {

    }

    tokenChangedDebounced: any = () => {};

    ngOnInit() {
        this.signupUrl = this.environment.identityConfig.invitationUrl;
        //this.auth.clearStorage();
        this.getProviders();
    }


    onKeyDown(event: KeyboardEvent) {
        if (event.key === ' ') {
            event.preventDefault();
            const element = event.target as HTMLElement;
            element.blur();
            element.focus();
        }
    }

    paste(event: ClipboardEvent) {
     event.preventDefault();
        const data = event.clipboardData.getData('Text').trim();
        if (data) {
            if (!this.model.adminUsers) {
                this.model.adminUsers = [];
            }
            const existingEmails = this.model.adminUsers
                .join(',') 
                .split(/[\n,; ]+/) 
                .map(e => e.trim())
            const newEmails = data
                .split(/[\n,; ]+/) 
                .map(e => e.trim()) 
            this.model.adminUsers = Array.from(new Set([...existingEmails, ...newEmails]));
        }
    }


    /**
     * This call is stubbed in for when we have a selectable authorization provider
     */
    getProviders() {
        this.iamService.getProviders().subscribe((providers: any) => {
            this.logger.info('Providers', providers);
            // @FIXME - TEMP fix until pipeline clears on the backend
            for (const provider of providers) {
                if (provider.auth0ConnectionId === 'Username-Password-Authentication') {
                    provider.auth0ConnectionType = 'Database';
                    // @FIXME - Force the DB provider as the default for now, eventually this will change to be selectable
                    this.dbProvider = provider;
                } else {
                    provider.auth0ConnectionType = 'Social';
                }
                this.providerTypes.push(provider);
            }
        });
    }


   async signup() {
    if(await this.validateSignup()) {
        this.processing = true;
        this.customerService.createNewSignUp(this.data).then((result) => {
            this.logger.info('Create new customer invites response: ', result);
            this.processing = false;
            this.isSuccessful = true;
            this.growlerService.show(
                new GrowlerData(
                    'success',
                    'Success',
                    'Customer Onboard Started',
                    'Customer onboard started successfully'
                )
            );

          },
          (HttpErrorResponse) => {
            this.isSuccessful = false;
            this.logger.error('Error from service creation', HttpErrorResponse);
            this.growlerService.show(
                new GrowlerData(
                    'error',
                    'New Customer Invitation request failed. ',
                    HttpErrorResponse.error.errors[0]
                )
            );
          }
        )
    }
    }

    async validateSignup() {
        
        this.data = {
            name: this.model.label,
            label: this.model.label,
            identityProviders: ['Username-Password-Authentication'],
            adminUsers: this.model.adminUsers
        }
        this.errors = {}
        const errors = await this.customerService.validateSignUp(this.data);
        if(!_.isArray(errors)) {
            return true;
        }
        if (_.find(errors, (e) => e.path === '$.label')) {
            this.errors['label'] = _.find(errors, (e) => e.path === '$.label').message;
        }
        if (_.find(errors, (e) => e.path === '$.adminUsers')) {
            this.errors['adminUsers'] = _.find(errors, (e) => e.path === '$.adminUsers').message;
        }
        return errors.length === 0;

    }


    failedSubmitRegion() {
        this.validSubmitRegion = false;
    }

    // TODO: get google signin working
    initiateGoogleSignIn() {
        this.logger.info('User clicked the google sign in button');
    }

    // TODO: get microsoft signin working
    initiateMicrosoftSignIn() {
        this.logger.info('User clicked the microsoft sign in button');
    }

}
