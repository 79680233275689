import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Network, Notification, OidcAudience } from '@netfoundry-ui/shared/model';
import { NotificationService } from '@netfoundry-ui/shared/notification';
import { LoggerService, NetworkGroupService, NetworkService, OidcAudienceService, URLS } from '@netfoundry-ui/shared/services';
import { Subscription, take } from 'rxjs';
import { NETWORK_SERVICE, NetworkServiceV2 } from '@netfoundry-ui/shared/apiv2';

import _ from 'lodash';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';

@Component({
    selector: 'app-oidc-audience-form',
    templateUrl: './oidc-audience-form.component.html',
    styleUrls: ['./oidc-audience-form.component.scss'],
})
export class OidcAudienceFormComponent implements OnInit {
    Oidc_Audiences = URLS.OIDC_AUDIENCES;

    errors = {};
    organizationList = []
    processing = false;
    canEdit = true;
    networkId: string;
    isEditing = false;
    emails = '';
    networkGroupName;
    isComplete = false;
    isBuilding;
    hideHelp = false;
    selectedIds = [];
    initialModel: OidcAudience = new OidcAudience();

    public model: OidcAudience = new OidcAudience();

    private subscription = new Subscription();

    constructor(
        public oidcAudienceService: OidcAudienceService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<OidcAudienceFormComponent>,
        public networkService: NetworkService,
        @Inject(NETWORK_SERVICE) private networkServiceV2: NetworkServiceV2,
        private router: Router,
        public growlerService: GrowlerService,
        public authorizationService: AuthorizationService,
        private networkGroupService: NetworkGroupService,
        private logger: LoggerService,
    ) {
  
    }

    ngOnInit() {

        this.initModel();
        this.networkGroupService.get().pipe(take(1)).subscribe((data) => {
            this.organizationList = data;
        })
      
    }

    closeDialog() {
        this.dialogRef.close();
    }

    hide(response?) {
        this.dialogRef.close(response);
    }

    async save() {
        if(await this.validate()) {
            if (this.model.id) {
                this.oidcAudienceService.update(this.model.id, this.model).subscribe((data) => {
                    this.processing = false;
                    this.dialogRef.close();
                    this.growlerService.show(
                        new GrowlerData(
                            'success',
                            'Success',
                            'OIDC Audience Updated Successfully',
                        )
                    );
                },
                (httpErrorResponse) => {
                    this.logger.error('Error from oidc audience patch', httpErrorResponse);
                    this.growlerService.show(
                        new GrowlerData(
                            'error',
                            'OIDC Public Client update request failed. ',
                            httpErrorResponse.error.errors[0]
                        )
                    );
                }
            )} else {
                this.oidcAudienceService.create(this.model).subscribe((data) => {
                    this.logger.info('Create oidc audience response: ', data);
                    this.processing = true;
                    this.growlerService.show(
                        new GrowlerData(
                            'success',
                            'Success',
                            'OIDC Audience Created Successfully',
                        )
                    );
                    this.isComplete = true;
                },
                (httpErrorResponse) => {
                    this.processing = false;
                    this.logger.error('Error from oidc audience create', httpErrorResponse);
                    this.growlerService.show(
                        new GrowlerData(
                            'error',
                            'OIDC Audience create request failed. ',
                            httpErrorResponse.error.errors[0]
                            )
                        );
                    }
                )

            }
        }
    }
    paste(event: ClipboardEvent) {
        event.preventDefault(); 
        const data = event.clipboardData.getData('Text').trim();
      
        this.model.restrictedToOrganizationIds.push(...data.split(' '));
        this.model.restrictedToOrganizationIds = [...new Set(this.model.restrictedToOrganizationIds)];
    }
    onKeyDown(event: KeyboardEvent) {
        if (event.key === ' ') {
            event.preventDefault();
            const element = event.target as HTMLElement;
            element.blur();
            element.focus();
        }
    }

    toggleActive() {
  
        this.model.active = !this.model.active;
    }


async validate() {
    this.errors = {};
    if(!this.model.id) {
        this.model.restrictedToOrganizationIds = this.selectedIds;
        const errors = await this.oidcAudienceService.validateCreate(this.model);
        if(!_.isArray(errors)) {
            return true;
        }
        if (_.find(errors, (e) => e.path.includes('oidcIssuerId'))) {
            this.errors['oidcIssuerId'] = (_.find(errors, (e) => e.path.includes('oidcIssuerId'))).message;
        }
        if (_.find(errors, (e) => e.path.includes('.audience'))) {
            this.errors['audience'] = (_.find(errors, (e) => e.path.includes('audience'))).message;
        }
        if (_.find(errors, (e) => e.path.includes('.restrictedToOrganizationIds'))) {
            this.errors['restrictedToOrganizationIds'] = (_.find(errors, (e) => e.path.includes('restrictedToOrganizationIds'))).message;
        }
        return errors.length === 0;
    } else {
        this.isEditing = true;
        this.model.restrictedToOrganizationIds = this.selectedIds;
        const errors = await this.oidcAudienceService.validateUpdate(this.model.id, this.model);
        if(!_.isArray(errors)) {
            return true;
        }
        if (_.find(errors, (e) => e.path.includes('oidcIssuerId'))) {
            this.errors['oidcIssuerId'] = (_.find(errors, (e) => e.path.includes('oidcIssuerId'))).message;
        }
        if (_.find(errors, (e) => e.path.includes('.audience'))) {
            this.errors['audience'] = (_.find(errors, (e) => e.path.includes('audience'))).message;
        }
        if (_.find(errors, (e) => e.path.includes('.restrictedToOrganizationIds'))) {
            this.errors['restrictedToOrganizationIds'] = (_.find(errors, (e) => e.path.includes('restrictedToOrganizationIds'))).message;
        }
        return errors.length === 0;

        }
    }

    private extractMessage(error: string): string {
        const parts = error.split(':', 2); // Split at the first colon
        return parts.length > 1 ? parts[1].trim() : error; // Return message after colon
    }

    private initModel() {
        if (this.data.model) {
            this.initialModel = _.cloneDeep(this.data.model);
            this.model = this.data.model;
        }
    }
}
