import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EdgeRouterServiceV2, EndpointServiceV2 } from '@netfoundry-ui/shared/apiv2';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import {FeatureService, HTTP_CLIENT, LoggerService, ValidateService} from '@netfoundry-ui/shared/services';
import {Environment, ENVIRONMENT} from "@netfoundry-ui/shared/model";
import {HttpClient} from "@angular/common/http";

@Component({
    selector: 'app-ziti-share',
    templateUrl: './ziti-share.component.html',
    styleUrls: ['./ziti-share.component.scss'],
})
export class ZitiShareComponent {
    type = 'client';
    emails = '';
    endpoint = null;
    edgeRouter = null;
    networkId = '';
    template = 'EmailClient';
    label = 'Identity';

    replacementParams = {
        EMAIL: localStorage.getItem('profile_email'),
        USER: localStorage.getItem('profile_nick'),
        ENV: '',
    };

    errorEmails = false;

    constructor(
        private logger: LoggerService,
        public endpointServiceV2: EndpointServiceV2,
        public edgeRouterServiceV2: EdgeRouterServiceV2,
        private dialogRef: MatDialogRef<ZitiShareComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private validateService: ValidateService,
        private growlerService: GrowlerService,
        private featureService: FeatureService,
        @Inject(ENVIRONMENT) public environment: Environment,
        @Inject(HTTP_CLIENT) private http: HttpClient,
    ) {
        this.type = data.type;
        this.endpoint = data.endpoint;
        this.edgeRouter = data.edgeRouter;
        this.networkId = data.networkId;
        this.label = this.type === 'endpoint' ? (featureService.disableNewNavigation ? 'Endpoint' : 'Identity') : 'Edge Router';
    }

    doShare() {
        if (this.validate()) {
            const splitEmails = this.emails.split(';');
            const emailList = [];
            for (const email of splitEmails) {
                emailList.push(email.trim());
            }

            const payload: any = {
                subject: 'NetFoundry - Registration Information',
                id: this.type === 'endpoint' ? this.endpoint['id'] : this.edgeRouter['id'],
            };

            if (this.environment.v3Enabled) {
                payload.to = emailList;
                const options: any = {
                  responseType: 'json',
                };
                payload.networkId = this.networkId;
                payload.type = this.type === 'endpoint' ? 'IDENTITY' : 'EDGEROUTER';
                return this.http.post(this.environment.v3apiUrl + `/registrations/share`, [payload], options).subscribe(
                  () => {
                    this.close(true);
                  },
                  (error) => {
                    this.growlerService.show(
                      new GrowlerData(
                        'error',
                        'Error',
                        'Share registration Information request failed',
                        'Email is not sent'
                      )
                    );
                    this.logger.error('Share registration Information request failed, Email is not sent', error);
                  }
                );
            } else if (this.type === 'endpoint') {
                payload.toList = emailList;
                this.endpointServiceV2.share(payload).subscribe(
                    () => {
                        this.close(true);
                    },
                    (error) => {
                        this.growlerService.show(
                            new GrowlerData(
                                'error',
                                'Error',
                                'Share registration Information request failed',
                                'Email is not sent'
                            )
                        );
                        this.logger.error('Share registration Information request failed, Email is not sent', error);
                    }
                );
            } else {
                this.edgeRouterServiceV2.share(payload).subscribe(
                    (res) => {
                        this.close(true);
                    },
                    (error) => {
                        this.growlerService.show(
                            new GrowlerData(
                                'error',
                                'Error',
                                'Share registration Information request failed',
                                'Email is not sent'
                            )
                        );
                        this.logger.error('Share registration Information request failed, Email is not sent', error);
                    }
                );
            }
        }
    }

    close(success?) {
        if (success) {
          this.growlerService.show(
            new GrowlerData(
              'success',
              'SUCCESS',
              'Registration Information Sent',
              'Registration details have been sent to the requested emails'
            )
          );
        }
        this.emails = '';
        this.dialogRef.close();
    }

    validate() {
        this.errorEmails = false;

        if (!this.validateService.hasValue(this.emails)) {
            this.errorEmails = true;
        }

        if (!this.validateService.isValidEmailList(this.emails)) {
            this.errorEmails = true;
        }

        return !this.errorEmails;
    }
}
