import { Component, Inject, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { ApiService, ErrorHistoryService, LoggerService } from '@netfoundry-ui/shared/services';
import { NetworkV2 } from '@netfoundry-ui/shared/model';
import { AttributesServiceResults, NetworkServiceV2 } from '@netfoundry-ui/shared/apiv2';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { Router } from '@angular/router';

@Component({
    selector: 'netfoundry-ui-manage-controller-confic-modal',
    templateUrl: './manage-controller-config-modal.component.html',
    styleUrls: ['./manage-controller-config-modal.component.scss'],
})
export class ManageControllerConfigModalComponent implements OnInit {
    model: NetworkV2;
    currentOrgId: string | null | undefined = null;
    currentNetwork = new NetworkV2();
    networkControllerId;
    sdsConfigPortEnabled: boolean;
    softwareDeploymentStateId;

    private subscription: Subscription = new Subscription();

    constructor(
        private logger: LoggerService,
        private authorizationService: AuthorizationService,
        private apiService: ApiService,
        public networkServiceV2: NetworkServiceV2,
        public growlerService: GrowlerService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private router: Router,
        private dialogRef: MatDialogRef<ManageControllerConfigModalComponent>
    ) {
        this.model = data;
        this.networkControllerId = data.model.networkController.id;
    }

    ngOnInit(): void {
        this.networkServiceV2.getsdsConfigPortEnabled(this.networkControllerId).subscribe((result) => {
            this.sdsConfigPortEnabled = result.softwaredeploymentstate[0].softwareConfigurationPortEnabled;
            this.softwareDeploymentStateId = result.softwareDeploymentStateId;
            console.log(this.sdsConfigPortEnabled);
        });
    }

    togglePortConfiguration() {
        this.sdsConfigPortEnabled = !this.sdsConfigPortEnabled;
    }

    savePortConfiguration() {
        this.networkServiceV2.toggleConfigPort(this.softwareDeploymentStateId, this.sdsConfigPortEnabled).subscribe(
            (data) => {
                this.growlerService.show(
                    new GrowlerData(
                        'success',
                        'Success',
                        'Software Config Port Modification Process Started',
                        'Software config port modification process started successfully.<a href="' +
                            this.router.createUrlTree(['/process-executions']).toString() +
                            '">Click here to find out more.</a>'
                    )
                );
                this.dialogRef.close(data);
            },
            (httpErrorResponse) => {
                this.logger.error('Error with software config port modification', httpErrorResponse);
                this.growlerService.show(
                    new GrowlerData(
                        'error',
                        'Software Config Port Modification request failed. ',
                        httpErrorResponse.error.errors[0]
                    )
                );
            }
        );
    }

    close() {
        this.dialogRef.close();
    }

    hide() {
        this.dialogRef.close();
    }
}
