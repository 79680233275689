import { Injectable, EventEmitter, Inject } from '@angular/core';
import { ApiService } from '@netfoundry-ui/shared/services';
import { EdgeRouterServiceV2 } from '@netfoundry-ui/shared/apiv2';
import { ExtensionService, GrowlerModel, GrowlerService, DataTableFilterService, FilterObj } from 'ziti-console-lib';

import { MatDialog } from '@angular/material/dialog';
import {MetricsModalComponent} from "@netfoundry-ui/feature/metrics-modal";
import {DialLogsComponent} from "@netfoundry-ui/feature/dial-logs";
import {EventsModalComponent} from "@netfoundry-ui/feature/events-modal";

import {isEmpty} from "lodash";
import {BehaviorSubject} from "rxjs";

@Injectable({ providedIn: 'root' })
export class ServicesExtensionService implements ExtensionService {

  hosting = false;
  errors: any = {};
  closed: EventEmitter<any> = new EventEmitter<any>();
  currentNetwork: any = {};
  dialogRef: any;

  formDataChanged: any = new BehaviorSubject<any>({isEmpty: true});
  closeAfterSave = true;

  moreActions = [
    {name: 'showMetrics', action: 'show-metrics', label: 'Show Metrics', callback: this.showMetricsModal.bind(this), hidden: this.hideMetricsAction.bind(this)},
    {name: 'showDialLogs', action: 'show-dial-logs', label: 'Show Dial Logs', callback: this.showDialLogsModal.bind(this), hidden: this.hideDialLogsAction.bind(this)}
  ];

  listActions = [
    {name: 'Show Metrics', action: 'show-metrics', label: 'Show Metrics', callback: this.showMetricsModal.bind(this), hidden: this.hideEventsTableAction.bind(this)},
    {name: 'Show Dial Logs', action: 'show-dial-logs', label: 'Show Dial Logs', callback: this.showDialLogsModalFromListPage.bind(this), hidden: this.hideEventsTableAction.bind(this)}
  ]

  formData: any = {};

  isAdminColumnFilterActive = false;

  constructor(
    private edgeRouterService: EdgeRouterServiceV2,
    private apiService: ApiService,
    private growlerService: GrowlerService,
    private dialogForm: MatDialog,
    protected filterService: DataTableFilterService,
  ) {
    this.apiService.currentNetwork.subscribe((network) => {
      this.currentNetwork = network;
    });
    this.filterService.filtersChanged.subscribe((event: any) => {
      this.isAdminColumnFilterActive = false;
      this.filterService.filters.forEach((filter: any) => {
        if (filter.columnId === 'isAdmin') {
          this.isAdminColumnFilterActive = filter.hidden !== true;
        }
      });
    });
  }

  extendOnInit() {
    //this.initAdminFilter();
  }

  hideEventsTableAction(item: any) {
    return false;
  }

  hideMetricsTableAction(item: any) {
    return false;
  }

  hideDialLogsTableAction(item: any) {
    return false;
  }

  hideEventsAction() {
    return isEmpty(this.formData?.id);
  }

  hideMetricsAction() {
    return isEmpty(this.formData?.id);
  }

  hideDialLogsAction() {
    return isEmpty(this.formData?.id);
  }

  updateFormData(data: any) {
    this.formData = data;
  }

  showMetricsModal(data?: any) {
    this.dialogForm.open(MetricsModalComponent, {
      data: {
        resourceType: 'service',
        model: data || this.formData,
        networkGroupId: this.currentNetwork.networkGroupId,
        networkId: this.currentNetwork.id,
      },
      height: '800px',
      width: '1200px',
      autoFocus: false,
    });
  }

  showDialLogsModal(data?: any) {
    this.dialogForm.open(DialLogsComponent, {
      data: {
        resourceType: 'service',
        model: data || this.formData,
        networkGroupId: this.currentNetwork.networkGroupId,
        networkId: this.currentNetwork.id,
      },
      height: '800px',
      width: '1000px',
      autoFocus: false,
    });
  }

  showDialLogsModalFromListPage(zitiIdentity: any) {
    this.formData = zitiIdentity;
    this.showDialLogsModal();
  }
  extendAfterViewInits(extentionPoints: any): void {}

  initAdminFilter() {
    const filterObj: FilterObj = {
      filterName: 'Admins',
      columnId: 'isAdmin',
      value: false,
      label: 'Non-Admins',
      type: 'BOOLEAN',
    };
    this.filterService.filters = [filterObj];
    this.filterService.filtersChanged.next(this.filterService.filters);
  }

  updateAdminFilter(showAdmin: any) {
    if (showAdmin) {
      this.filterService.filters = this.filterService.filters.filter((filter: any) => {
        return filter.columnId !== 'isAdmin';
      });
    } else {
      const filterObj: FilterObj = {
        filterName: 'Admins',
        columnId: 'isAdmin',
        value: false,
        label: 'Admins',
        type: 'BOOLEAN',
      };
      this.filterService.filters.push(filterObj);
    }
    this.filterService.filtersChanged.next(this.filterService.filters);
  }

  validateData(): Promise<any> {
    return Promise.resolve(true);
  }

  formDataSaved(data: any): Promise<any> {
    return Promise.resolve(data);
  }

  processTableColumns(tableColumns: any): any[] {
    return tableColumns;
  }

}
