import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { SETTINGS_SERVICE, SettingsService } from 'ziti-console-lib';
import { ZitiDomainControllerService, ZitiSessionData } from 'ziti-console-lib';
import { debounce, delay, get, isEmpty } from 'lodash';
import { ApiService, MenuService } from '@netfoundry-ui/shared/services';
import moment from 'moment/moment';
import { Environment, ENVIRONMENT } from '@netfoundry-ui/shared/model';
import { NetworkServiceV2 } from './network.service';
import {Router} from "@angular/router";
import {GrowlerData, GrowlerService} from "@netfoundry-ui/shared/growler";
import {ConfirmComponent} from "@netfoundry-ui/ui/confirm";
import {MatDialog} from "@angular/material/dialog";
import {URLS} from '@netfoundry-ui/shared/services';

@Injectable({
    providedIn: 'root',
})
export class ZitiControllerService implements ZitiDomainControllerService {
    currentNetwork: any = {};
    tokenExpirationDate: any = {};
    dialogRef: any;

    getZitiControllerSessionDebounced = debounce(this.getZitiControllerSession.bind(this), 1000);

    public zitiSessionData: ZitiSessionData = {
        zitiDomain: '',
        zitiSessionId: '',
        expiresAt: '',
    };

    public zitiSettings: any = new BehaviorSubject(this.zitiSessionData);
    public loadingZitiController = false;

    constructor(
        @Inject(SETTINGS_SERVICE) private settingsService: SettingsService,
        private apiService: ApiService,
        private networkServiceV2: NetworkServiceV2,
        @Inject(ENVIRONMENT) private environment: Environment,
        private router: Router,
        private growlService: GrowlerService,
        private menuService: MenuService,
        private dialogForm: MatDialog,
    ) {
        if (!this.environment.v3Enabled) {
            return;
        }
        this.apiService.currentNetwork.subscribe(async (network) => {
            if (isEmpty(network?.id)) {
                return;
            }
            if (this.currentNetwork?.id !== network?.id) {
                this.currentNetwork = network;
                if (this.currentNetwork?.status === 'PROVISIONING' || this.currentNetwork?.status === 'NEW') {
                  this.router.navigate([URLS.PROCESS_EXECUTIONS]);
                  this.pollForNetworkProvisioned();
                } else if (this.currentNetwork?.status === 'PROVISIONED') {
                  this.setAuthPolicyId();
                  this.getZitiControllerSession();
                } else {
                  this.router.navigate([URLS.PROCESS_EXECUTIONS]);
                }
            } else {
                this.currentNetwork = network;
            }
        });
    }

    getZitiControllerSession(retryOnFail = false, retryCount = 0) {
        const controllers = get(this.currentNetwork, '_embedded.network-controllers');
        let controllerId;
        if (!isEmpty(controllers)) {
          controllers.forEach((controller) => {
            if (controller.haPrimaryNode) {
              controllerId = controller.id;
            }
          });
        } else {
          controllerId = this.currentNetwork?.networkController?.id;
        }
        if (isEmpty(controllerId)) {
            return;
        }

        this.loadingZitiController = true;
        this.zitiSessionData = {
            zitiDomain: '',
            zitiSessionId: '',
            expiresAt: '',
        };
        const zitiSettings = { ...this.zitiSessionData };
        this.zitiSettings.next(zitiSettings);
        const settings = {
          ...this.settingsService.settings,
          session: {
            id: '',
            controllerDomain: '',
            authorization: 100,
            expiresAt: '',
          },
        };
        this.settingsService.set(settings);
        this.networkServiceV2
            .getNetworkControllerInfo(controllerId)
            .toPromise()
            .then((result: any) => {
                let edgeUrl = `https://${result.publicDomainName}`;
                this.settingsService.addContoller(this.currentNetwork.name, edgeUrl);
                this.settingsService.set(this.settingsService.settings);
                this.settingsService.initApiVersions(edgeUrl).then(() => {
                  this.networkServiceV2
                      .getZitiSessionToken(this.currentNetwork?.id)
                      .toPromise()
                      .then((result) => {
                          if (result?.networkControllerUrl) {
                              const url = new URL(result?.networkControllerUrl);
                              edgeUrl = `${url.protocol}//${url.hostname}${url.port ? ':' + url.port : ''}`;
                          }

                          const settings = {
                              ...this.settingsService.settings,
                              session: {
                                  id: result.value,
                                  controllerDomain: edgeUrl,
                                  authorization: 100,
                                  expiresAt: result.expiresAt,
                              },
                          };
                          this.zitiSessionData.zitiSessionId = result.value;
                          this.zitiSessionData.zitiDomain = edgeUrl;
                          this.zitiSessionData.expiresAt = result.expiresAt;
                          this.settingsService.set(settings);
                          this.tokenExpirationDate = moment(result.expiresAt);
                          const expTime = this.tokenExpirationDate.diff(moment());
                          let buffer = expTime - 1000 * 30;
                          if (buffer < 10000) {
                              buffer = 10000;
                          }
                          delay(() => {
                              this.getZitiControllerSessionDebounced();
                          }, buffer);
                          const zitiSettings = { ...this.zitiSessionData };
                          this.zitiSettings.next(zitiSettings);
                          this.apiService.setCurrentZitiEdgeDetails(result);
                          this.loadingZitiController = false;
                      }).catch((er) => {
                          this.handleControllerSessionError();
                      });
                }).catch((er) => {
                  if (retryOnFail && retryCount < 5) {
                    delay(() => {
                      this.getZitiControllerSession(retryOnFail, retryCount++);
                    }, 10000);
                  } else {
                    this.handleControllerSessionError();
                  }
                });
            }).catch((er) => {
                this.handleControllerSessionError();
            })
    }

    get hasZitiSession() {
      const hasSessionId = !isEmpty(this.zitiSessionData?.zitiSessionId);
      const sessionExpired = moment(this.zitiSessionData?.expiresAt).isBefore(new Date());
      return hasSessionId && !sessionExpired;
    }

    handleControllerSessionError() {
      this.loadingZitiController = false;
      this.growlService.show(
          new GrowlerData(
            'error',
            'Error',
            'Failed to connect',
            'Can not establish a session with network controller'
          )
      );
      if (this.menuService.areaId === 'ZAC') {
          const data = {
              title: 'Unable to Connect',
              appendId: 'ZitiConnectionFailed',
              subtitle: 'Unable to connect to the ziti controller for the selected network. Would you like to try again?',
              icon: 'Confirm',
              action: 'Yes',
          };
          this.dialogRef = this.dialogForm.open(ConfirmComponent, {
              data: data,
              height: '340px',
              width: '600px',
              autoFocus: false,
          });
          this.dialogRef.afterClosed().subscribe((result: any) => {
              if (result) {
                  this.getZitiControllerSession();
              } else {
                  this.router.navigate(['/infrastructure/dashboard']);
              }
          });
      }
    }

    pollForNetworkProvisioned() {
      this.loadingZitiController = true;
      const options = {
        params: {
          embed: 'clusters,network-controllers'
        }
      };
      this.networkServiceV2.getNetwork(this.currentNetwork.id, options).then((network: any) => {
        this.currentNetwork = this.apiService.getNetworkModel(network);
        if (this.currentNetwork.status === 'PROVISIONED') {
          this.loadingZitiController = false;
          this.apiService.setCurrentNetwork(this.currentNetwork);
          this.getZitiControllerSession();
        } else if (this.currentNetwork.status === 'PROVISIONING' || this.currentNetwork.status === 'NEW') {
          delay(() => {
            this.pollForNetworkProvisioned();
          }, 15000);
        } else if (this.currentNetwork.status === 'ERROR') {
          this.loadingZitiController = false;
          this.growlService.show(
            new GrowlerData(
              'error',
              'Error',
              'Failed to connect',
              'Cannot connect to ziti controller. Network failed to provision.'
            )
          );
        }
      }).catch(() => {
        this.loadingZitiController = false;
      });
    }

    setAuthPolicyId() {
        let authPolicies = [];
        if (!isEmpty(this.currentNetwork?._embedded?.authPolicies)) {
          authPolicies = this.currentNetwork?._embedded?.authPolicies;
        } else if (!isEmpty(this.currentNetwork['auth-policies'])) {
          authPolicies = this.currentNetwork['auth-policies'];
        } else if (!isEmpty(this.currentNetwork?._embedded['auth-policies'])) {
          authPolicies = this.currentNetwork?._embedded['auth-policies'];
        } else if (!isEmpty(this.currentNetwork?.authPolicies)) {
          authPolicies = this.currentNetwork?.authPolicies;
        }
        let authPolicy = authPolicies?.find((authPolicy: any) => {
          return authPolicy.name === 'NetFoundry Console Integration Auth Policy';
        });
        if (isEmpty(authPolicy)) {
          authPolicy = authPolicies[0];
        }
        this.settingsService.settings.nfAdminAuthPolicyId = authPolicy?.zitiId;
    }

    handleUnauthorized(): any {
      // No-Op
    }
}
