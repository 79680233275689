import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EdgeRouterServiceV2, HostService, NETWORK_SERVICE, NetworkControllerService, NetworkServiceV2 } from '@netfoundry-ui/shared/apiv2';
import { Environment, ENVIRONMENT, NetworkV2 } from '@netfoundry-ui/shared/model';
import { ApiService } from '@netfoundry-ui/shared/services';
import _ from 'lodash';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { PagedGetOption } from '@lagoshny/ngx-hateoas-client';
import semver from 'semver';

@Component({
    selector: 'app-network-info-v2',
    templateUrl: './network-info-v2.component.html',
    styleUrls: ['./network-info-v2.component.scss'],
    providers: [HostService],
})
export class NetworkInfoV2Component implements OnInit {
    networkModel: NetworkV2 = new NetworkV2();
    items = [];

    majorVersionNumber;
    currentNetwork;
    networkControllersList = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<NetworkInfoV2Component>,
        private apiService: ApiService,
        private edgeRouterService: EdgeRouterServiceV2,
        private networkControllerService: NetworkControllerService,
        @Inject(NETWORK_SERVICE) private networkServiceV2: NetworkServiceV2,
        private hostService: HostService,
        public growlerService: GrowlerService,
        @Inject(ENVIRONMENT) public environment: Environment
    ) {
        this.networkModel = data.network;
        this.majorVersionNumber = apiService.getNetworkVersion(this.networkModel);
    }

    ngOnInit(): void {
        const hostId = this.networkModel.networkController.hostId;
        const networkControllerId = this.networkModel.networkController.id;

        this.networkServiceV2.getNetworkControllerInfo(networkControllerId).subscribe((result) => {
            let portString = this.getPortString();
            if (result?.softwaredeploymentstate[0]?.softwareConfigurationPortEnabled) {
                portString += '/6262';
            }
        });

        this.networkControllerService.getNetworkControllerPage(this.getOptions()).then(
            (result) => {
                this.networkControllersList = result;
                console.log(result)
                const controllerIps = []
                const controllerDomains = []
                for(const controller of this.networkControllersList) {
                    controllerIps.push(controller.host.ipAddress);
                    if (!_.isEmpty(controller.domainName)) {
                        controllerDomains.push(controller.domainName);
                    }
                    if(!_.isEmpty(controller.publicDomainName)) {
                        controllerDomains.push(controller.publicDomainName);
                    }
                    
                }
                this.items.push({
                    type: 'Network Controller(s)',
                    ips: controllerIps,
                    domainNames: controllerDomains,
                })
  
            }
        )

        this.getEdgeRouters();
    }

    close() {
        this.dialogRef.close();
    }

    clickToCopy(value) {
        navigator.clipboard.writeText(value);
        this.growlerService.show(
            new GrowlerData('success', 'Success', 'Info Copied', 'The firewall info has been copied to your clipboard')
        );
    }

    csv_export() {
        // Construct the header rows
        const data = [];
        const columns = [];
        const header = {};
        for (const column of Object.keys(this.items[0])) {
            header[column] = column.toUpperCase();
            columns.push(column);
        }
        data.push(header);

        // Construct each row based on the columns defined
        for (const item of this.items) {
            const row = {};

            for (const column of columns) {
                if (item[column] === undefined) {
                    row[column] = '';
                } else {
                    if (column === 'version number') {
                        row[column] = this.majorVersionNumber;
                    } else if (column === 'type') {
                        row[column] = item.type;
                    } else if (column === 'ips') {
                        row[column] = item[column].filter(Boolean).toString().replace(/,/g, '; ');
                    } else if (column === 'domainNames') {
                        row[column] = item[column].toString().replace(/, /g, '; ');
                    } else {
                        row[column] = item[column];
                    }
                }
            }
            data.push(row);
        }

        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalseparator: '.',
            showLabels: true,
            showTitle: false,
            useBom: true,
        };
        const csv = new AngularCsv(data, 'NetFoundry_IP_Whitelist', options);
    }

    getEdgeRouters() {
        this.edgeRouterService.getEdgeRouterPage(this.getOptions()).then((edgeRouters) => {
            const edgeRouterIps = [];
            const domainNames = [];
            for (const edgeRouter of edgeRouters) {
                if (edgeRouter.hostId) {
                    this.hostService.getHost(edgeRouter.hostId).then((host) => {
                        if (!_.isEmpty(host)) {
                            if (!_.isEmpty(host.ipAddress)) {
                                edgeRouterIps.push(host.ipAddress);
                            }
                            if (!_.isEmpty(edgeRouter.domainName)) {
                                domainNames.push(edgeRouter.domainName);
                            }
                            if(!_.isEmpty(edgeRouter.publicDomainName)) {
                                domainNames.push(edgeRouter.publicDomainName);
                            }
                        }
                    });
                }
            }

            const portString = this.getPortString();
            this.items.push({
                type: 'Hosted Edge Routers',
                ips: edgeRouterIps,
                domainNames: domainNames,
                port: portString,
            });
        });
    }

    getPortString() {
        let portString = '443';
        if (semver.lt(this.networkModel.productVersion, '7.3.94')) {
            portString = '80/' + portString;
        }
        return portString;
    }

    private getOptions() {
        const options: PagedGetOption = {
            params: { 
                networkId: this.networkModel.id,
                embed: 'all',
             },

        };
        return options;
    }
}
