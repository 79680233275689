import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Cluster, ENVIRONMENT, Environment } from '@netfoundry-ui/shared/model';
import { Observable } from 'rxjs';
import { HTTP_CLIENT } from '@netfoundry-ui/shared/services';
import {
  GetOption,
  HateoasResourceOperation,
  PagedGetOption,
  HttpMethod,
  PagedResourceCollection,
} from '@lagoshny/ngx-hateoas-client';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ClusterService extends HateoasResourceOperation<Cluster> {
  static defaultPaginationSize = 500;
  static defaultHttpAccept = {
    headers: {accept: 'application/hal+json'},
    pageParams: {
      page: 0,
      size: 2000,
    },
  };
  lastPageCount = 0;
  lastTotalCount = 0;
  apiUrl: string;

  constructor(@Inject(HTTP_CLIENT) private http: HttpClient, @Inject(ENVIRONMENT) private environment: Environment) {
    super(Cluster);
    this.apiUrl = environment.v3Enabled ? environment.v3apiUrl : environment.v2apiUrl
  }

  getCluster(id: string | number, options: GetOption = {}): Promise<Cluster | undefined> {
    return super
      .getResource(id, {...ClusterService.defaultHttpAccept, ...options})
      .toPromise()
      .then((cluster) => cluster);
  }

  async getClusterPage(options?: PagedGetOption, includeProperties?: string): Promise<Cluster[]> {
    let params = {...ClusterService.defaultHttpAccept, ...options};
    if (includeProperties) {
      params = {...params, headers: {accept: `application/hal+json;includeProperties=${includeProperties}`}};
    }
    return super
      .getPage(params)
      .pipe(
        tap((val) => {
          this.lastPageCount = val.totalPages;
          this.lastTotalCount = val.totalElements;
        })
      )
      .toPromise()
      .then((clusters) => (clusters ? clusters.resources : ([] as Cluster[])));
  }
}
