import {Component, Inject} from '@angular/core';
import { IDENTITY_EXTENSION_SERVICE } from "ziti-console-lib";
import {Router} from "@angular/router";
import {ApiService} from "@netfoundry-ui/shared/services";
import {delay} from "lodash";
import {IdentitiesExtensionService} from "./identities-extension.service";
import {ZitiShareService} from "@netfoundry-ui/feature/ziti/ziti-share";

@Component({
    selector: 'netfoundry-ui-identities-extension',
    templateUrl: './identities-extension.component.html',
    styleUrls: ['./identities-extension.component.scss'],
})
export class IdentitiesExtensionComponent {
  toggleOn = false;
  showToggle = false;
  constructor(
    @Inject(IDENTITY_EXTENSION_SERVICE) public svc: IdentitiesExtensionService,
    private router: Router,
    private apiService: ApiService,
    private zitiShareService: ZitiShareService,
  ) {}
  toggled() {
    if (this.svc.isAdminColumnFilterActive) {
      return;
    }
    this.toggleOn = !this.toggleOn;
    this.svc.updateAdminFilter(this.toggleOn);
  }
  get isAdminToolTip() {
    return this.svc.isAdminColumnFilterActive ? 'Toggle is disabled while column filter is applied' : '';
  }
}
