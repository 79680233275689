<div class="fullModal opened" id="ZitiEndpointAddModal">
    <div [hidden]="isComplete" class="fullRow">
        <div class="title" style="filter: brightness(50%)">
            <span *ngIf="model.id">Edit Your OIDC Issuer</span>
            <span *ngIf="!model.id">Create A New OIDC Issuer</span>
        </div>
        <div *ngIf="model.id" class="subtitle">Update your oidc issuer attributes below</div>
        <div *ngIf="!model.id" class="subtitlealt">
    In OpenID Connect, the issuer is a claim in the ID token that identifies the OpenID Provider that issued the token. It is a unique URL that represents the identity provider.
        </div>
        <div class="sized">
            <div id="NetworkField">
                <div class="label row">Oidc Issuer<span class="note">Required</span></div>

<div class="row">
    <input
        [(ngModel)]="model.issuer"
        id="EditZRouterName"
        placeholder="Place your issuer here."
        type="text"
    />
    <span [hidden]="!errors['issuer']" class="err">
        {{ errors['issuer'] }}
    </span>
</div>
</div>
<div class="label row">JWKS Uri<span class="note">Required</span></div>
<div class="row">
<input
    [(ngModel)]="model.jwksUri"
    id="EditZRouterName"
    placeholder="Place your JWKS Uri here."
    type="text"
/>
<span [hidden]="!errors['jwksUri']" class="err">
    {{ errors['jwksUri'] }}
</span>
</div>
<div class="label row">
    Activate Issuer
    <div
        class="infoicon"
        matTooltip="Sets this issuer active for your provider."
        matTooltipPosition="below"
    ></div>
    <span class="note">REQUIRED</span>
</div>
<div class="row">
    <div class="encryptionpod">
        <span class="encryptiontext">Activate Issuer</span>
        <div
            (click)="toggleActive()"
            [ngClass]="{
                on: model.active,
            }"
            [title]="'Activate This Issuer'"
            class="toggle"
            style="float: right; margin-top: 0.9375rem; margin-right: 0.75rem"
        >
            <span [hidden]="!model.active" style="margin-left: -0.9375rem">YES</span>
            <span [hidden]="model.active" style="margin-right: -0.9375rem">NO</span>
            <div class="switch"></div>
        </div>
    </div>
</div>
<div class="row buttonRow right" style="margin-top: 1.25rem">
<button
    (click)="save()"
    *ngIf="!processing && (!model.id || canEdit)"
    class="save"
    id="SaveZitiEndpointButton"
>
    {{ model.id ? 'Update' : 'Create' }}
</button>
<button *ngIf="processing" class="save confirm" disabled>Saving <span class="spinner"></span></button>
</div>
<div (click)="hide()" class="buttonBall close" id="EscZEndpointClose">
<div class="buttonLabel">ESC</div>
</div>
</div>
</div>

<div [hidden]="!isComplete" class="fullRow" id="Stage3">
<div class="confetti">
<!--confetti div starts-->
<div class="confetti-piece"></div>
<div class="confetti-piece"></div>
<div class="confetti-piece"></div>
<div class="confetti-piece"></div>
<div class="confetti-piece"></div>
<div class="confetti-piece"></div>
<div class="confetti-piece"></div>
<div class="confetti-piece"></div>
<div class="confetti-piece"></div>
<div class="confetti-piece"></div>
<div class="confetti-piece"></div>
<div class="confetti-piece"></div>
<div class="confetti-piece"></div>
<div class="confetti-piece"></div>

<div class="title congratulations" style="filter: brightness(50%)">Congratulations!</div>
<div class="subtitlealt">
Your oidc issuer has been created. You may edit it at anytime under oidc issuers.
</div>
<div class="row center">
</div>
<div class="row center" style="margin-top: 1.5625rem">
<button (click)="closeDialog()" [routerLink]="Oidc_Issuers" class="save">Oidc Issuers</button>
</div>
</div>

<div (click)="hide()" class="buttonBall close" id="EscZEndpointClose">
<div class="buttonLabel">ESC</div>
</div>
<app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
</div>
<!--confetti div ends-->
</div>
