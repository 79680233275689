import { HateoasResource, Resource } from '@lagoshny/ngx-hateoas-client';
import { EmbeddedHostV2 } from './embedded-host';

@HateoasResource('edge-routers', { routeName: 'networkResourceRoute' })
export class EdgeRouterV2 extends Resource {
    id: string;
    networkId: string;
    linkListener: boolean;
    name: string;
    enabled = true;
    alternateDomainName: string;
    ipAddress: string;
    domainName: string;
    publicDomainName: string;
    provider: string;
    jwt: string;
    dataCenterId: string;
    locationMetadataId: string;
    status: string;
    online: boolean;
    hostId: string;
    attributes: string[];
    createdAt: string;
    updatedAt: string;
    locationCode: string;
    tunnelerEnabled: boolean;
    softwareDeploymentStateId: string;
    selected = false;
    zitiId: string;
    verified: boolean;
    noTraversal: boolean;
    size: string;
    host: EmbeddedHostV2;
    productVersion: string;
    registration: {
        expiresAt: string;
        registrationAttempts: number;
    };
    wssListener: boolean;
    _embedded: {
        host: {
            provider: string;
        };
    };
}
