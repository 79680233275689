import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-start-menu-item',
    templateUrl: './start-menu-item.component.html',
    styleUrls: ['./start-menu-item.component.scss'],
})
export class StartMenuItemComponent {
    @Input() title = '';
    @Input() subtitle = '';
    @Input() buttonText = '';
    @Input() iconClass = '';
    @Input() disabled = false;
    @Input() showSpinner = false;
    @Input() disabledTooltip = '';
    @Input() completed = false;
    @Input() hideZiggy = false;
    @Output() itemSelected = new EventEmitter();

    constructor() {}

    ngOnInit() {
        this.disableZiggy()
    }

    menuButtonedClicked(event: any) {
        if (this.disabled) {
            return;
        }
        this.itemSelected.emit(event);
    }

    disableZiggy() {
        if (!this.iconClass) {
            this.iconClass = this.hideZiggy ? 'fireworks' : 'open-ziti';
        }
    }
}
