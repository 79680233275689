import { Component, EventEmitter, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import moment from 'moment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FromDatePipe } from '@netfoundry-ui/ui/pipes';
import { CsvDownloadService, TableFilterService } from '@netfoundry-ui/feature/shared-services';
import { Subscription } from 'rxjs';
import { EVENT_FIELDS, EventFields, NetworkV2 } from '@netfoundry-ui/shared/model';
import { ApiService, FeatureService } from '@netfoundry-ui/shared/services';
import { TEMPLATE_SEARCH_SERVICE, TemplateSearchService } from "@netfoundry-ui/shared/apiv2";
import { debounceTime } from 'rxjs/operators';
import _ from 'lodash';
import semver from 'semver';

@Component({
    selector: 'app-metrics-modal',
    templateUrl: './metrics-modal.component.html',
    styleUrls: ['./metrics-modal.component.scss'],
    providers: [FromDatePipe, CsvDownloadService],
})
export class MetricsModalComponent implements OnInit, OnDestroy {
    setDateFilterEvent: EventEmitter<boolean> = new EventEmitter();
    setNewEndTime: EventEmitter<string> = new EventEmitter();
    setNewStartTime: EventEmitter<string> = new EventEmitter();
    dateFilter = '24h';
    // dateFilter = '24h';
    endTime: number = Date.now();
    startTime: number = this.endTime - 24 * 60 * 60 * 1000;

    currentNetwork = new NetworkV2();

    networkId;
    networkGroupId;
    resourceType;
    model;
    filterType: string;
    filterValue: string;

    rangeDates: Date[];

    startDate;
    endDate;
    minimumDate;

    serviceItems;
    endpointItems;
    label = 'Edge Routers';
    labelEndpoint = this.featureService?.disableNewNavigation ? 'Endpoints' : 'Identities';

    usageHostingLabel = this.featureService?.disableNewNavigation ? 'Hosting Endpoints' : 'Hosting Identities';

    aggregateField;

    endpointTableFilterValue: string;
    serviceTableFilterValue: string;

    topUsageGridStyleClass = 'halfGrid';
    serviceTableLocked = false;
    hostingEndpointData = false;
    productVersion;
    subscription = new Subscription();

    filterTypes = {
        endpoint: this.eventFields.identityId,
        service: this.eventFields.serviceId,
        'edge-router': this.eventFields.edgeRouterId,
    };

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fromDate: FromDatePipe,
        private csvDownloadService: CsvDownloadService,
        private dialogRef: MatDialogRef<MetricsModalComponent>,
        public filterService: TableFilterService,
        public apiService: ApiService,
        public featureService: FeatureService,
        @Inject(TEMPLATE_SEARCH_SERVICE) public templateService: TemplateSearchService,
        @Inject(EVENT_FIELDS) private eventFields: EventFields,
    ) {
        this.networkGroupId = data.networkGroupId;
        this.networkId = data.networkId;
        this.resourceType = data.resourceType;
        this.topUsageGridStyleClass = this.resourceType === 'network'? 'thirdGrid': this.resourceType !== 'endpoint' ? 'thirdGrid' : 'halfGrid';
        this.model = data.model;
        this.filterType = data.filterType || this.filterTypes[this.resourceType];
        this.filterValue = data.resourceValue || this.model.id;

        this.minimumDate = new Date();
        this.minimumDate.setDate(this.minimumDate.getDate() - 30);
    }

    ngOnInit() {
        this.subscription.add(
            this.apiService.currentNetwork.pipe(debounceTime(500)).subscribe((network) => {
                this.currentNetwork = network;
                this.getProductVersion();
            })
        );
        this.filterService.getDateFilter();
        this.closeCalendar();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    /**
     * Updates the observables for starttime / endtime
     * @param value
     */
    public setStartTime(value) {
        this.startTime = this.fromDate.transform(value);
        this.endTime = Date.now();

        // if the filter is 1m, 6m, or 12m, we should display from the start of one month to the end of another
        if (value === '1m' || value === '6m' || value === '12m') {
            // the start time should be set to the start of the first month by the fromDate pipe
            //  setting teh end time to the end of the current month manually
            this.endTime = moment(this.endTime).endOf('month').valueOf();
        }
        this.dateFilter = value;
    }

    updateStart(value) {
        this.dateFilter = value;
        this.setStartTime(this.dateFilter);
        this.setEndTime();
        this.setDateFilterEvent.emit();
        this.rangeDates = null;
        this.closeCalendar();
    }

    getProductVersion() {
        this.productVersion = this.currentNetwork.productVersion;
        //if at 7.3.79 or higher add hosting endpoint metrics
        if (semver.gte(this.productVersion, '7.3.79')) {
            this.hostingEndpointData = true;
            this.label = this.featureService?.disableNewNavigation ? 'Hosting Endpoints' : 'Hosting Identities';
            this.labelEndpoint = this.featureService?.disableNewNavigation ? 'Dialing Endpoints' : 'Dialing Identities';
            this.aggregateField = this.templateService.host_aggregate_field;
        } else {
            this.aggregateField = this.templateService.edge_router_aggregate_field;
        }
    }

    closeCalendar() {
        if (!_.isEmpty(this.rangeDates) && !_.isNil(this.rangeDates[0]) && !_.isNil(this.rangeDates[1])) {
            this.filterService.setDateRange(moment.utc(this.rangeDates[0]), moment.utc(this.rangeDates[1]));
        }
    }

    public setEndTime() {
        this.endTime = Date.now();
    }

    receivedServiceItems(items) {
        this.serviceItems = items;
    }

    receivedEndpointItems(items) {
        this.endpointItems = items;
    }

    downloadServiceCsv() {
        this.downloadCsv('serviceUsageTotals', this.serviceItems);
    }

    downloadEndpointCsv() {
        this.downloadCsv('endpointUsageTotals', this.endpointItems);
    }

    downloadCsv(filename, items) {
        const csvItems = [];
        for (const item of items) {
            csvItems.push(item);
        }
        const columns = ['name', 'usage'];
        this.csvDownloadService.download(filename, csvItems, columns);
    }

    close(response?) {
        this.dialogRef.close(response);
    }

    //   hide(response?) {
    //     this.dialogRef.close(response);
    // }
    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
        this.close();
    }
}
