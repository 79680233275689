import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OidcIssuer } from '@netfoundry-ui/shared/model';
import { NotificationService } from '@netfoundry-ui/shared/notification';
import { LoggerService, NetworkService, OidcIssuerService, URLS } from '@netfoundry-ui/shared/services';
import { Subscription } from 'rxjs';
import { NETWORK_SERVICE, NetworkServiceV2 } from '@netfoundry-ui/shared/apiv2';

import _ from 'lodash';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';

@Component({
    selector: 'app-oidc-issuers-form',
    templateUrl: './oidc-issuers-form.component.html',
    styleUrls: ['./oidc-issuers-form.component.scss'],
})
export class OidcIssuersFormComponent implements OnInit {
    Oidc_Issuers = URLS.OIDC_ISSUERS;

    errors = {};
    processing = false;
    canEdit = true;
    isEditing  = false;
    networkId: string;
    isComplete = false;
    isBuilding;
    hideHelp = false;
    initialModel: OidcIssuer = new OidcIssuer();

    public model: OidcIssuer = new OidcIssuer();

    private subscription = new Subscription();

    constructor(
        public oidcIssuerService: OidcIssuerService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<OidcIssuersFormComponent>,
        public networkService: NetworkService,
        @Inject(NETWORK_SERVICE) private networkServiceV2: NetworkServiceV2,
        private router: Router,
        public growlerService: GrowlerService,
        public authorizationService: AuthorizationService,
        private logger: LoggerService,
    ) {
  
    }

    ngOnInit() {

        this.initModel();
    }

    closeDialog() {
        this.dialogRef.close();
    }

    hide(response?) {
        this.dialogRef.close(response);
    }


   async save() {
        if(await this.validate()) {
            if (this.model.id) {
                this.oidcIssuerService.update(this.model.id, this.model).subscribe((data) => {
                    this.processing = false;
                    this.dialogRef.close();
                    this.growlerService.show(
                        new GrowlerData(
                            'success',
                            'Success',
                            'OIDC Issuer Updated Successfully',
                        )
                    );
                },
                (httpErrorResponse) => {
                    this.logger.error('Error from oidc issuer patch', httpErrorResponse);
                    this.growlerService.show(
                        new GrowlerData(
                            'error',
                            'OIDC Issuer update request failed. ',
                            httpErrorResponse.error.errors[0]
                        )
                    );
                }
            )} else {
                this.oidcIssuerService.create(this.model).subscribe((data) => {
                    this.logger.info('Create oidc issuer response: ', data);
                    this.processing = true;
                    this.growlerService.show(
                        new GrowlerData(
                            'success',
                            'Success',
                            'OIDC Issuer Created Successfully',
                        )
                    );
                    this.isComplete = true;
                },
                (httpErrorResponse) => {
                    this.processing = false;
                    this.logger.error('Error from oidc issuer create', httpErrorResponse);
                    this.growlerService.show(
                        new GrowlerData(
                            'error',
                            'OIDC Issuer create request failed. ',
                            httpErrorResponse.error.errors[0]
                            )
                        );
                    }
                )

            }
        }
        
    }
            
    



    toggleActive() {
        this.model.active = !this.model.active;
    }

   async validate() {
    this.errors = {};
    if(!this.model.id) {
        const errors = await this.oidcIssuerService.validateCreate(this.model);
    if(!_.isArray(errors)) {
        return true;
    }
    if (_.find(errors, (e) => e.path.includes('.issuer'))) {
        this.errors['issuer'] = (_.find(errors, (e) => e.path.includes('issuer'))).message;
    }
    if (_.find(errors, (e) => e.path.includes('.jwksUri'))) {
        this.errors['jwksUri'] = (_.find(errors, (e) => e.path.includes('jwksUri'))).message;
    }
    return errors.length === 0;
} else {
    this.isEditing = true;
    const errors = this.oidcIssuerService.validateUpdate(this.model.id, this.model);
    if(!_.isArray(errors)) {
        return true;
    }
    if (_.find(errors, (e) => e.path.includes('.issuer'))) {
        this.errors['issuer'] = (_.find(errors, (e) => e.path.includes('issuer'))).message;
    }
    if (_.find(errors, (e) => e.path.includes('.jwksUri'))) {
        this.errors['jwksUri'] = (_.find(errors, (e) => e.path.includes('jwksUri'))).message;
    }
    return errors.length === 0;

    }
}
    
private initModel() {
        if (this.data.model) {
            this.initialModel = _.cloneDeep(this.data.model);
            this.model = this.data.model;
        }
    }
}
