import {Inject, Injectable, InjectionToken} from '@angular/core';
import {
  NetworkUtilizationTemplateQueryParameter,
  ServiceEventTemplateQueryParameter,
  FabricCircuitTemplateQueryParameter
} from "@netfoundry-ui/shared/model";
import * as momentTz from "moment-timezone";
import moment from "moment/moment";
import {get, isEmpty, isNil, omitBy} from "lodash";
import {AttributesService} from "./attributes.service";
import {ZITI_DATA_SERVICE, ZitiDataService} from "ziti-console-lib";

export const TEMPLATE_SEARCH_SERVICE = new InjectionToken('TEMPLATE_SEARCH_SERVICE');

export interface TemplateSearchService {
  UTILIZATION_SERIES_TEMPLATE;

  TOP_COMPONENTS_TEMPLATE;
  SERVICE_HEALTH_TEMPLATE;
  UTILIZATION_BYTES_BY_COMPONENT_TEMPLATE;
  UTILIZATION_SUM_BY_COMPONENT_TEMPLATE;
  SERVICE_ENDPOINT_DIAL_LOGS_TEMPLATE;
  SERVICE_HEALTH_COUNT_TEMPLATE;
  SERVICE_HEALTH_TIME_SERIES_TEMPLATE;

  identity_aggregate_field;
  service_aggregate_field;
  edge_router_aggregate_field;
  host_aggregate_field;
  usage_aggregate_field;
  dial_health_time_buckets_path;
  dial_health_inner_buckets_path;

  getUtilizationTemplateQuery(startTime, endTime, dateFilter, componentName, componentValue, attributes, networkId, networkGroupId, index, aggComponentName?, aggTermSize?, id?);
  getTopComponentsTemplateQuery(dateFilter, aggregateField, networkId, networkGroupId, index, size);
  getServiceHealthTemplateQuery(dateFilter, networkId, networkGroupId, index, serviceId?, aggInterval?, aggTermSize?, timeZone?);
  getFabricCircuitTemplateQuery(index, startTime, endTime, endpointId, serviceId, networkId, organizationId, networkEventType, endpointName, serviceName);
  getUtilizationSeriesData(data);
  getStackedUtilizationSeriesData(data);
  getUsageSumByComponentData(data);
  getUsagePieSumByComponentData(data);
  getUsageTotalSumByComponentData(data);
  getTopComponentsData(data);
  getServiceHealthData(data);
  getInterval(dateField);
  getAttributes(networkId, type): Promise<any>;

}

@Injectable({ providedIn: 'root' })
export class V7TemplateSearchService implements TemplateSearchService {
  UTILIZATION_SERIES_TEMPLATE = 'ncutilization_rx_and_tx_tmpl';
  TOP_COMPONENTS_TEMPLATE = 'ncutilization_top_components_tmpl';
  SERVICE_HEALTH_TEMPLATE = 'ncserviceevents_health_tmpl';
  UTILIZATION_BYTES_BY_COMPONENT_TEMPLATE = 'ncutilization_metrics_usage_bytes_by_component_tmpl';
  UTILIZATION_SUM_BY_COMPONENT_TEMPLATE = 'ncutilization_metrics_usage_sum_by_component_tmpl';
  SERVICE_ENDPOINT_DIAL_LOGS_TEMPLATE = 'ncfabriccircuits_service_endpoint_dial_logs_tmpl';
  SERVICE_HEALTH_COUNT_TEMPLATE = 'ncserviceevents_health_count_tmpl';
  SERVICE_HEALTH_TIME_SERIES_TEMPLATE = 'ncfabriccircuits_service_dial_health_tmpl';
  identity_aggregate_field = 'nf_endpoint_name.keyword';
  service_aggregate_field = 'nf_service_name.keyword';
  edge_router_aggregate_field = 'nf_edge_router_name.keyword';
  host_aggregate_field = 'nf_host_name.keyword';
  usage_aggregate_field = 'usage_type.keyword';
  dial_health_time_buckets_path = 'aggregations.time_buckets.buckets';
  dial_health_inner_buckets_path = 'failure_causes.buckets';

  constructor(
    private attributesService: AttributesService,
  ) {
  }

  getUtilizationTemplateQuery(startTime, endTime, dateFilter, componentName, componentValue, attributes, networkId, networkGroupId, index, aggComponentName?, aggTermSize?, id?) {
    const networkUtilizationTemplateQueryParameter: NetworkUtilizationTemplateQueryParameter = {
      gte: startTime,
      lte: endTime,
      size: '0',
      timeZone: dateFilter ? momentTz.tz.guess() : '',
      interval: this.getInterval(dateFilter),
      componentName: componentName,
      componentValue: componentValue,
      aggComponentName: aggComponentName,
      aggTermSize: aggTermSize,
      attributes: attributes,
      networkId: networkId,
      networkGroupId: networkGroupId,
      indexName: index,
    };
    return networkUtilizationTemplateQueryParameter;
  }

  getTopComponentsTemplateQuery(dateFilter, aggregateField, networkId, networkGroupId, index, size) {
    const networkUtilizationTemplateQueryParameter: NetworkUtilizationTemplateQueryParameter = {
      gte: 'now-' + dateFilter,
      lte: 'now',
      size: '0',
      aggTermSize: '5',
      aggComponentName: aggregateField,
      networkId: networkId,
      networkGroupId: networkGroupId,
      indexName: index,
    };
    return networkUtilizationTemplateQueryParameter;
  }

  getServiceHealthTemplateQuery(dateFilter, networkId, networkGroupId, index, serviceId = '', aggInterval = '', aggTermSize = '10', timeZone = '') {
    let serviceEventTemplateParams: ServiceEventTemplateQueryParameter = {
      indexName: index,
      gte: 'now-' + dateFilter,
      lte: 'now',
      size: '0',
      aggTermSize: aggTermSize,
      aggInterval: aggInterval,
      timeZone: timeZone,
      networkGroupId: networkGroupId,
      networkId: networkId,
      serviceId: serviceId
    };
    serviceEventTemplateParams = omitBy(serviceEventTemplateParams, isEmpty);
    return serviceEventTemplateParams;
  }

  getFabricCircuitTemplateQuery(index, startTime, endTime, endpointId, serviceId, networkId, organizationId, networkEventType, endpointName, serviceName) {
    const fabricCircuitTemplateQueryParameter: FabricCircuitTemplateQueryParameter = {
      indexName: index,
      gte: startTime.valueOf(),
      lte: endTime.valueOf(),
      size: '500',
      endpointId: endpointId,
      serviceId: serviceId,
      networkId: networkId,
      networkGroupId: organizationId,
      networkEventType: networkEventType,
      endpointName: endpointName,
      serviceName: serviceName,
    };
    return fabricCircuitTemplateQueryParameter;
  }

  getUtilizationSeriesData(data) {
    const TxSeries = [];
    const RxSeries = [];
    const buckets = get(data, 'aggregations.timebuckets.buckets');
    if (isEmpty(buckets) || isNil(buckets)) {
      return [];
    }

    for (const bucket of buckets) {
      const timestamp = moment(bucket['key_as_string']).unix() * 1000;
      let rx = 0;
      let tx = 0;
      for (const inner_bucket of bucket['usage_sums']['buckets']) {
        if (inner_bucket['key'] === 'usage.ingress.tx') {
          rx = Number(inner_bucket['usage_bytes']['value']);
        }
        if (inner_bucket['key'] === 'usage.egress.tx') {
          tx = Number(inner_bucket['usage_bytes']['value']);
        }
      }
      TxSeries.push([timestamp, tx]);
      RxSeries.push([timestamp, rx]);
    }

    const utilization_series = [
      {
        name: 'Tx',
        data: TxSeries,
        color: '#0273fb',
      },
      {
        name: 'Rx',
        data: RxSeries,
        color: '#00db48',
      },
    ];

    return utilization_series;
  }

  getStackedUtilizationSeriesData(data) {
    const buckets = get(data, 'aggregations.items.buckets');
    if (isEmpty(buckets) || isNil(buckets)) {
      return [];
    }

    // reset the utilization series so we don't stack series
    const utilization_series = [];
    const dataseries = [];
    for (const bucket of buckets) {
      // const timestamp = moment(bucket['key_as_string']).unix() * 1000;

      const key = bucket['key'];

      for (const inner_bucket of bucket['timebuckets']['buckets']) {
        const timestamp = moment(inner_bucket['key_as_string']).unix() * 1000;

        if (dataseries[key] === undefined) {
          dataseries[key] = [];
        }

        dataseries[key].push([timestamp, Number(inner_bucket['usage_bytes']['value'])]);
      }
    }

    for (const itemkey of Object.keys(dataseries)) {
      utilization_series.push({
        name: itemkey,
        data: dataseries[itemkey],
        // 'color': '#0273fb'
      });
    }

    return utilization_series;
  }

  getUsageSumByComponentData(data) {
    const items = [];
    const buckets = get(data, 'aggregations.items.buckets');
    if (isEmpty(buckets) || isNil(buckets)) {
      return items;
    }

    for (const bucket of buckets) {
      const count = bucket['item_sum']['value'];
      const key = bucket['key'];
      if (key === '') {
        continue;
      }
      items.push({
        usage: count,
        name: key,
      });
    }

    return items;
  }

  getUsagePieSumByComponentData(data) {
    const items = [];
    const buckets = get(data, 'aggregations.items.buckets');
    if (isEmpty(buckets) || isNil(buckets)) {
      return items;
    }

    for (const bucket of buckets) {
      const count = bucket['item_sum']['value'];
      const key = bucket['key'];
      items.push({
        name: key,
        y: count,
      });
    }
    return items;
  }

  getUsageTotalSumByComponentData(data) {
    const result = {};
    const buckets = get(data, 'aggregations.items.buckets');
    if (isEmpty(buckets) || isNil(buckets)) {
      return result;
    }

    let totalTx, totalRx;
    for (const bucket of buckets) {
      const val = bucket['item_sum']['value'];
      const key = bucket['key'];

      if (key === 'usage.ingress.tx') {
        totalTx = val;
      }
      if (key === 'usage.egress.tx') {
        totalRx = val;
      }
    }
    return {totalTx, totalRx};
  }

  getTopComponentsData(data) {
    const buckets = get(data, 'aggregations.unique_items.buckets');
    if (isEmpty(buckets) || isNil(buckets)) {
      return {};
    }

    const TopFive = [];
    const items = [];
    for (const bucket of buckets) {
      const count = bucket['item_sum']['value'];
      const key = bucket['key'];

      TopFive.push({
        name: key,
        y: count,
      });

      items.push({
        count: count,
        name: key,
      });
    }

    return {TopFive, items};
  }

  getServiceHealthData(data) {
    const items = [];
    const buckets = get(data, 'aggregations.group_by_service_name.buckets');
    if (isEmpty(buckets) || isNil(buckets)) {
      return items;
    }

    for (const bucket of buckets) {
      const dialHealth = bucket['dial_failure_rate']['value'];
      const success = bucket['eventSuccess']['sumSuccess']['value'];
      const failure = bucket['eventFailure']['sumFailure']['value'];
      const key = bucket['key'];

      items.push({
        dialHealth: dialHealth,
        success: success,
        failure: failure,
        name: key,
      });
    }
    return items;
  }

  getInterval(dateFilter) {
    if (!dateFilter) {
      return '';
    }
    if (dateFilter.includes('m')) {
      return '1d';
    } else if (dateFilter.includes('d')) {
      return '1h';
    } else if (dateFilter.includes('h')) {
      return '5m';
    } else {
      // just in case...
      return '1d';
    }
  }

  getAttributes(networkId, type) {
    return this.attributesService
      .findDistinctAttributes(networkId, type, null);
  }
}

@Injectable({ providedIn: 'root' })
export class V8TemplateSearchService implements TemplateSearchService {
  UTILIZATION_SERIES_TEMPLATE = 'ncutilization_rx_and_tx_dashboard_v8_tmpl';
  UTILIZATION_BYTES_BY_COMPONENT_TEMPLATE = 'ncutilization_metrics_usage_bytes_by_component_v8_tmpl';
  UTILIZATION_SUM_BY_COMPONENT_TEMPLATE = 'ncutilization_metrics_usage_sum_by_component_v8_tmpl';
  TOP_COMPONENTS_TEMPLATE = 'ncutilization_top_components_v8_tmpl';
  SERVICE_HEALTH_TEMPLATE = 'ncserviceevents_health_tmpl_v8';
  SERVICE_ENDPOINT_DIAL_LOGS_TEMPLATE = 'ncfabriccircuits_service_endpoint_dial_logs_v8_tmpl';
  SERVICE_HEALTH_COUNT_TEMPLATE = 'ncserviceevents_health_count_tmpl_v8';
  SERVICE_HEALTH_TIME_SERIES_TEMPLATE = 'ncserviceevents_health_time_series_tmpl_v8';
  identity_aggregate_field = 'identity_name.keyword';
  service_aggregate_field = 'service_name.keyword';
  edge_router_aggregate_field = 'edge_router_name.keyword';
  host_aggregate_field = 'host_name.keyword';
  usage_aggregate_field = 'identity_id.keyword';
  dial_health_time_buckets_path = 'aggregations.items.buckets';
  dial_health_inner_buckets_path = 'event_types.buckets';

  constructor(@Inject(ZITI_DATA_SERVICE) private zitiService: ZitiDataService) {}

  getUtilizationTemplateQuery(startTime, endTime, dateFilter, componentName, componentValue, attributes, networkId, networkGroupId, index, aggComponentName?, aggTermSize?, id?) {
    const networkUtilizationTemplateQueryParameter: NetworkUtilizationTemplateQueryParameter = {
      gte: startTime,
      lte: endTime,
      size: '0',
      aggTermSize: '10',
      timeZone: dateFilter ? momentTz.tz.guess() : '',
      interval: this.getInterval(dateFilter),
      calendar_interval: this.getInterval(dateFilter),
      componentName: componentName,
      componentValue: componentValue,
      aggComponentName: aggComponentName,
      attributes: attributes,
      networkId: networkId,
      networkGroupId: networkGroupId,
      indexName: index
    };
    return networkUtilizationTemplateQueryParameter;
  }

  getTopComponentsTemplateQuery(dateFilter, aggregateField, networkId, networkGroupId, index, size) {
    const networkUtilizationTemplateQueryParameter: NetworkUtilizationTemplateQueryParameter = {
      networkId: networkId,
      networkGroupId: networkGroupId,
      aggComponentName: aggregateField,
      gte: 'now-' + dateFilter,
      lte: 'now',
      size: '0',
      component_size: size,
      indexName: index
    };
    return networkUtilizationTemplateQueryParameter;
  }

  getServiceHealthTemplateQuery(dateFilter, networkId, networkGroupId, index, serviceId = '', aggInterval = '', aggTermSize = '10', timeZone = '') {
    let serviceEventTemplateParams: ServiceEventTemplateQueryParameter = {
      indexName: index,
      gte: 'now-' + dateFilter,
      lte: 'now',
      size: '0',
      networkGroupId: networkGroupId,
      aggTermSize: aggTermSize,
      aggInterval: aggInterval,
      networkId: networkId,
      serviceId: serviceId,
      timeZone: timeZone
    };
    serviceEventTemplateParams = omitBy(serviceEventTemplateParams, isEmpty);
    return serviceEventTemplateParams;
  }

  getFabricCircuitTemplateQuery(index, startTime, endTime, endpointId, serviceId, networkId, organizationId, networkEventType, endpointName, serviceName) {
    const fabricCircuitTemplateQueryParameter: FabricCircuitTemplateQueryParameter = {
      indexName: index,
      gte: startTime.valueOf(),
      lte: endTime.valueOf(),
      size: '500',
      endpointId: endpointId,
      serviceId: serviceId,
      networkId: networkId,
      networkGroupId: organizationId,
      networkEventType: networkEventType,
      endpointName: endpointName,
      serviceName: serviceName,
    };
    return fabricCircuitTemplateQueryParameter;
  }

  getUtilizationSeriesData(data) {
    const TxSeries = [];
    const RxSeries = [];

    const buckets = get(data, 'aggregations.time_buckets.buckets');
    if (isEmpty(buckets) || isNil(buckets)) {
      return [];
    }

    for (const bucket of data['aggregations']['time_buckets']['buckets']) {
      const timestamp = moment(bucket['key_as_string']).unix() * 1000;
      const rx = get(bucket, 'rx.value');
      const tx = get(bucket, 'tx.value');
      TxSeries.push([timestamp, tx]);
      RxSeries.push([timestamp, rx]);
    }

    const utilization_series = [
      {
        name: 'Tx',
        data: TxSeries,
        color: '#0273fb',
      },
      {
        name: 'Rx',
        data: RxSeries,
        color: '#00db48',
      },
    ];

    return utilization_series;
  }

  getStackedUtilizationSeriesData(data) {
    const buckets = get(data, 'aggregations.top10Services.buckets');
    if (isEmpty(buckets) || isNil(buckets)) {
      return [];
    }

    const dataseries = [];
    for (const bucket of buckets) {
      const key = bucket['key'];
      for (const inner_bucket of bucket['time_buckets']['buckets']) {
        const timestamp = moment(inner_bucket['key_as_string']).unix() * 1000;

        if (dataseries[key] === undefined) {
          dataseries[key] = [];
        }

        dataseries[key].push([timestamp, Number(inner_bucket['total_sum']['value'])]);
      }
    }

    const utilization_series = [];
    for (const itemkey of Object.keys(dataseries)) {
      utilization_series.push({
        name: itemkey,
        data: dataseries[itemkey],
        // 'color': '#0273fb'
      });
    }

    return utilization_series;
  }

  getUsageSumByComponentData(data) {
    const items = [];
    const buckets = get(data, 'aggregations.Top10Services.buckets');
    if (isEmpty(buckets) || isNil(buckets)) {
      return items;
    }

    for (const bucket of buckets) {
      const count = bucket['total_sum']['value'];
      const key = bucket['key'];
      if (key === '') {
        continue;
      }
      items.push({
        usage: count,
        name: key,
      });
    }

    return items;
  }

  getUsagePieSumByComponentData(data) {
    const items = [];
    const buckets = get(data, 'aggregations.Top10Services.buckets');
    if (isEmpty(buckets) || isNil(buckets)) {
      return items;
    }

    for (const bucket of buckets) {
      const count = bucket['total_sum']['value'];
      const key = bucket['key'];
      items.push({
        name: key,
        y: count,
      });
    }
    return items;
  }

  getUsageTotalSumByComponentData(data) {
    const result = {totalTx: 0, totalRx: 0};
    const buckets = get(data, 'aggregations.Top10Services.buckets');
    if (isEmpty(buckets) || isNil(buckets)) {
      return result;
    }

    let totalTx, totalRx;
    for (const bucket of buckets) {
      totalTx = bucket?.tx?.value;
      totalRx = bucket?.rx?.value;
    }
    return {totalTx, totalRx};
  }

  getTopComponentsData(data) {
    const buckets = get(data, 'aggregations.Top10Services.buckets');
    if (isEmpty(buckets) || isNil(buckets)) {
      return {};
    }

    const TopFive = [];
    const items = [];
    for (const bucket of buckets) {
      const count = bucket['total_sum']['value'];
      const key = bucket['key'];

      TopFive.push({
        name: key,
        y: count,
      });

      items.push({
        count: count,
        name: key,
      });
    }

    return {TopFive, items};
  }

  getServiceHealthData(data) {
    const items = [];
    const buckets = get(data, 'aggregations.group_by_service_name.buckets');
    if (isEmpty(buckets) || isNil(buckets)) {
      return items;
    }

    for (const bucket of buckets) {
      const dialHealth = bucket['dial_failure_rate']['value'];
      const success = bucket['eventSuccess']['sumSuccess']['value'];
      const failure = bucket['eventFailure']['sumFailure']['value'];
      const key = bucket['key'];

      items.push({
        dialHealth: dialHealth,
        success: success,
        failure: failure,
        name: key,
      });
    }
    return items;
  }

  getInterval(dateFilter) {
    if (!dateFilter) {
      return '';
    }
    if (dateFilter.includes('m')) {
      return '1d';
    } else if (dateFilter.includes('d')) {
      return '1h';
    } else if (dateFilter.includes('h')) {
      return '1m';
    } else {
      // just in case...
      return '1d';
    }
  }

  getAttributes(networkId, type) {
    return this.zitiService.get('identity-role-attributes', {}, []).then((result: any) => {
      const mappedResults = result.data.map((attr) => ({
        name: attr,
        attributeType: type,
        isGroup: true,
      }));
      return mappedResults;
    });
  }
}
