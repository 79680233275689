import { Injectable } from '@angular/core';
import _ from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';

const sideNavLockString = 'sideNavLocked';

@Injectable({ providedIn: 'root' })
export class MenuService {

    DEFAULT_NAV_ITEM = { isMOPView: true };
    // TODO move to State Management
    public locked = true;
    public areaId = '';
    public areaSource = new Subject<string>();
    public lastArea = '';
    area = this.areaSource.asObservable();
    areas = ['Network', 'Organization', 'Support', 'Super', 'ZAC'];
    app = '';
    appChanged = new BehaviorSubject<any>(this.app);
    navItem: any = this.DEFAULT_NAV_ITEM;
    navItemChanged = new BehaviorSubject<any>(this.navItem);
    toggleRecentAlerts = new BehaviorSubject<any>(false);

    constructor() {
        const sideNavLocked = localStorage.getItem(sideNavLockString);
        this.locked = true;
        if (sideNavLocked == null) {
            localStorage.setItem(sideNavLockString, String(this.locked));
        } else if (sideNavLocked === 'false') {
            this.locked = false;
        }
        if (this.locked) {
            const area = localStorage.getItem('NavArea');
            this.setArea(area);
        }
    }

    public setArea(area) {
        if (area === null) {
            return;
        }
        this.lastArea = this.areaId;
        this.areaId = area;
        this.areaSource.next(area);
        localStorage.setItem('NavArea', area);
    }

    public unsetArea() {
        this.areaId = '';
        this.areaSource.next('');
        localStorage.removeItem('NavArea');
    }

    public resetArea() {
        if (this.areaId === '') {
          this.setArea(this.lastArea);
        }
    }

    public setApp(app) {
        this.app = app;
        this.appChanged.next(this.app);
        switch (this.app) {
            case 'infrastructure':
                this.setArea('ZAC');
                break;
            case 'networks':
                this.setArea('ZAC');
                break;
            case 'billing':
                this.setArea('Billing');
                break;
            case 'organization':
                this.setArea('Organization');
                break;
            case 'browzer':
                this.setArea('Browzer');
                break;
            case 'support_operations':
                this.setArea('Support_Operations');
                break;
            case 'support':
                this.setArea('Support');
                break;
            case 'mop':
                this.setArea('MOP');
                break;
        }
    }

    public setNavItem(item) {
        this.navItem = item;
        this.navItemChanged.next(item);
    }

    public toggleLock() {
        this.locked = !this.locked;
        localStorage.setItem(sideNavLockString, String(this.locked));
    }

    public isOpen() {
        return !_.isEmpty(this.areaId);
    }

    public showAlerts() {
      this.toggleRecentAlerts.next(true);
    }

    public hideAlerts() {
      this.toggleRecentAlerts.next(false);
    }
}
