import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EdgeRouterV2, NetworkV2 } from '@netfoundry-ui/shared/model';
import { NetworkUpgradeService } from '../network-upgrade.service';

@Component({
    selector: 'app-network-upgrade-modal',
    templateUrl: './network-upgrade-modal.component.html',
    styleUrls: ['./network-upgrade-modal.component.scss'],
})
export class NetworkUpgradeModalComponent {
    title = 'Network Upgrade Available';
    icon = '';
    action = 'Upgrade Network';
    cancelAction = 'Oops, no get me out of here';
    upgradeMode: any;

    network: NetworkV2;
    versionList: any[];
    upgradeOptionList = [
        { label: 'Entire Network', value: 'all' },
        { label: 'Just my Network Controller(s)', value: 'nc' },
        { label: 'Network Controller(s) and Edge Router(s)', value: 'ncer' },
        { label: 'Just My Edge Router(s)', value: 'er' },
    ];
    edgeRouterList: any[] = [];
    selectedVersion = '';
    selectedUpgradeOption = this.upgradeOptionList[0];
    selectedEdgeRouters: EdgeRouterV2[] = [];

    constructor(
        private dialogRef: MatDialogRef<NetworkUpgradeModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private networkUpgradeSvc: NetworkUpgradeService
    ) {
        this.network = data.network;
        this.versionList = (this.network as any)['upgradeableVersions'];
        if (this.versionList) {
            for (const v of this.versionList) {
                if (v.recommended) {
                    this.selectedVersion = v.networkVersion;
                }
            }
        }
    }

    optionSelected(option: string) {
        this.selectedUpgradeOption = this.upgradeOptionList.filter((o) => o.value === option)[0];
        if (option === 'ncer' || option === 'er') {
            this.networkUpgradeSvc
                .getEdgeRouters(this.network.id)
                .then((data) => {
                    this.edgeRouterList = data;
                })
                .catch((e) => {
                    throw e;
                });
        }
    }

    cancel() {
        this.dialogRef.close();
    }

    confirm() {
        if (!this.selectedVersion) this.cancel()
        else if(this.selectedUpgradeOption.value === 'all') {
            const options: any = {
                network: this.network,
                upgradeVersion: this.selectedVersion,
            };
            options.upgradeMode= 'FULL'
            this.dialogRef.close(options)
        } else if(this.selectedUpgradeOption.value === 'nc') {
            const options: any = {
                network: this.network,
                upgradeVersion: this.selectedVersion,
            };
            options.upgradeMode = 'CONTROLLERS_ONLY'
            this.dialogRef.close(options)
        } else if(this.selectedUpgradeOption.value === 'ncer') {
            const options: any = {
                network: this.network,
                upgradeVersion: this.selectedVersion,
            };
            options.edgeRouterIds = this.selectedEdgeRouters.map((er) => er.id);
            options.upgradeMode = 'FULL';
            this.dialogRef.close(options)
        } else if (this.selectedUpgradeOption.value === 'er') {
            const options: any = {
                network: this.network,
                upgradeVersion: this.selectedVersion,
            };
            options.edgeRouterIds = this.selectedEdgeRouters.map((er) => er.id);
            options.upgradeMode = 'EDGE_ROUTERS_ONLY';
            this.dialogRef.close(options)
        }
    }
}
