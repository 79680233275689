import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NETWORK_SERVICE, NetworkServiceV2, NetworkControllerService } from '@netfoundry-ui/shared/apiv2';
import { NetworkController, NetworkV2 } from '@netfoundry-ui/shared/model';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { Router } from '@angular/router';
import moment from 'moment';



@Component({
    selector: 'app-network-controller-restart-modal',
    templateUrl: './network-controller-restart-modal.component.html',
    styleUrls: ['./network-controller-restart-modal.component.scss'],
})
export class NetworkControllerRestartModalComponent {
    networkControllerId;
    networkId;
    concurrentControllerRestart;
    selectedOption;
    selectedScopeOption

    restartOptions = [
        { label: 'Rolling Restart', value: false, severity: 'info', tagValue: 'Recommended' },
        { label: 'Parallel Restart', value: true, } // Only for Full Restart
      ];

      scopeOptions = [
        { label: 'Host', value: 'host',},
        { label: 'Process', value: 'process', } // Only for Full Restart
      ];


    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<NetworkControllerRestartModalComponent>,
        @Inject(NETWORK_SERVICE) private networkServiceV2: NetworkServiceV2,
        public growlerService: GrowlerService,
        public networkControllerService: NetworkControllerService,
        private router: Router
    ) {
        this.networkId = data.model;

    }

    ngOnInit() {

        this.selectedOption.value = false;
        this.selectedScopeOption.value = 'host';
    }

    close() {
        this.dialogRef.close();
    }

    restartAll() {

        if(this.selectedScopeOption.value === 'host') {
            this.networkControllerService.restartAllHost(this.networkId, this.selectedOption.value).subscribe(
                (result) => {
                    this.growlerService.show(
                        new GrowlerData(
                            'success',
                            'Success',
                            'Restart Process Started Successfully',
                            'Network Controller Rolling resart process started successfully'
                        )
                    );
                    this.dialogRef.close();
                    return result;
                },
                (httpErrorResponse) => {
                    this.growlerService.show(
                        new GrowlerData('error', 'Error restarting network controllers', httpErrorResponse.message)
                    );
                }
            );
        }
        if(this.selectedScopeOption.value === 'process') {
            this.networkControllerService.restartAllProcess(this.networkId, this.selectedOption.value).subscribe(
                (result) => {
                    this.growlerService.show(
                        new GrowlerData(
                            'success',
                            'Success',
                            'Restart Process Started Successfully',
                            'Network Controller Rolling resart process started successfully'
                        )
                    );
                    this.dialogRef.close();
                    return result;
                },
                (httpErrorResponse) => {
                    this.growlerService.show(
                        new GrowlerData('error', 'Error restarting network controllers', httpErrorResponse.message)
                    );
                }
            );
        }

    }
}
