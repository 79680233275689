export class OidcIssuer {
    id: string;
    issuer: string;
    jwksUri: string;
    active: boolean;;
    createdBy: string;
    createdAt: string;
    deletedAt: string;
    selected = false;
    deletedBy: string;
    deleted: boolean;
}