<app-side-filter
    (filterRemoved)="removeFilter($event)"
    *ngIf="showFilter"
    [appliedFilters]="appliedFilters"
    [columnFilters]="columnFilters"
    [isTimeSearchAvailable]="isTimeSearchAvailable"
    [isNotDialLogs]="isNotDialLogs"
    class="header-filter"
></app-side-filter>
<div *ngIf="gridRendered && showFilter" class="hidden-columns-list" id="ColumnVisibilityHeader">
    <div
        (click)="setColumnVisibilityColumn(col, true)"
        *ngFor="let col of hiddenColumns"
        class="hidden-column-item ag-dnd-ghost ag-unselectable ag-theme-alpine"
        id="ColumnVisibility_{{ col.headerName }}"
        title="Show column"
    >
        <span class="ag-dnd-ghost-icon">
            <span class="ag-icon ag-icon-eye-slash"></span>
        </span>
        <div class="ag-dnd-ghost-label">{{ col.headerName }}</div>
    </div>
</div>
<div (resized)="resizeGridColumnsDebounced($event)" class="nf-ag-grid" id="ItemTable">
    <ag-grid-angular
        #nfAgGrid
        (gridReady)="onGridReady($event)"
        [columnDefs]="mergedColumnDefinitions"
        [components]="frameworkComponents"
        [getDataPath]="getDataPath"
        [getRowId]="getRowNodeId"
        [gridOptions]="gridOptions"
        [modules]="gridModules"
        [rowData]="rowData"
        class="nf-ag-grid-table ag-theme-alpine"
        id="ItemGrid"
    >
    </ag-grid-angular>
</div>
<div
    [ngClass]="{ open: openMenu }"
    [ngStyle]="{ left: menuLeft + 'px', top: menuTop + 'px' }"
    class="tMenu action-menu"
    id="RowActionMenu"
>
    <div
        (click)="actionRequested.emit({ action: 'update', item: selectedItem })"
        *ngIf="selectedItem.actionList.includes('update')"
        class="tActionRow"
        id="EditSelectionButton"
    >
        Edit
    </div>
    <div
        (click)="actionRequested.emit({ action: 'edit', item: selectedItem })"
        *ngIf="selectedItem.actionList.includes('updateRoles')"
        class="tActionRow"
        id="RowEditRoles_{{ index }}"
    >
        Edit Roles
    </div>
    <div
        (click)="actionRequested.emit({ action: 'rename', item: selectedItem })"
        *ngIf="selectedItem.actionList.includes('rename')"
        class="tActionRow"
        id="RowEditRoles_{{ index }}"
    >
        Rename
    </div>
    <div
        (click)="actionRequested.emit({ action: 'reset', item: selectedItem })"
        *ngIf="selectedItem.actionList.includes('rotatePassword')"
        class="tActionRow"
        id="RowReset_{{ index }}"
    >
        New Secret
    </div>
    <div
        (click)="actionRequested.emit({ action: 'deactivate', item: selectedItem })"
        *ngIf="
            selectedItem.actionList.includes('activateOrDeactivate') || selectedItem.actionList.includes('deactivate')
        "
        [hidden]="selectedItem.active === 0"
        class="tActionRow"
        id="RowDelete_{{ index }}"
    >
        Deactivate
    </div>
    <div
        (click)="actionRequested.emit({ action: 'activate', item: selectedItem })"
        *ngIf="selectedItem.actionList.includes('activateOrDeactivate') || selectedItem.actionList.includes('activate')"
        [hidden]="selectedItem.active === 1"
        class="tActionRow"
        id="RowActivate_{{ index }}"
    >
        Activate
    </div>
    <div
        (click)="actionRequested.emit({ action: 'resetMFA', item: selectedItem })"
        *ngIf="selectedItem.actionList.includes('reset') || selectedItem.actionList.includes('resetMfa')"
        class="tActionRow"
        id="ResetSelectionButton"
    >
        Reset MFA
    </div>
    <div
        (click)="actionRequested.emit({ action: 'reissueToken', item: selectedItem })"
        *ngIf="selectedItem.actionList.includes('reissue')"
        class="tActionRow"
        id="ResetSelectionButton"
    >
        Reissue Token
    </div>
    <div
        (click)="actionRequested.emit({ action: 'releaseIP', item: selectedItem })"
        *ngIf="selectedItem.actionList.includes('releaseIP')"
        class="tActionRow"
        id="ResetSelectionButton"
    >
        Release IP
    </div>
    <div
        (click)="actionRequested.emit({ action: 'reEnroll', item: selectedItem })"
        *ngIf="selectedItem.actionList.includes('reEnroll')"
        class="tActionRow"
        id="ResetSelectionButton"
    >
        Re-Enroll
    </div>
    <div
        (click)="actionRequested.emit({ action: 'share', item: selectedItem })"
        *ngIf="selectedItem.actionList.includes('share')"
        class="tActionRow"
        id="ShareSelectionButton"
    >
        Share
    </div>
    <div
        (click)="actionRequested.emit({ action: 'openTopology', item: selectedItem })"
        *ngIf="selectedItem.actionList.includes('topology')"
        class="tActionRow"
        id="TopologySelectionButton"
    >
        Visualize
    </div>
    <div
        (click)="actionRequested.emit({ action: 'invite', item: selectedItem })"
        *ngIf="selectedItem.actionList.includes('invite')"
        class="tActionRow"
        id="InviteButton"
    >
        Invite
    </div>
    <div
        (click)="actionRequested.emit({ action: 'firewall', item: selectedItem })"
        *ngIf="selectedItem.actionList.includes('firewall')"
        class="tActionRow"
        id="FirewallButton"
    >
        Firewall Info
    </div>
    <div
        (click)="actionRequested.emit({ action: 'notifications', item: selectedItem })"
        *ngIf="selectedItem.actionList.includes('notifications')"
        class="tActionRow"
        id="NotifyButton"
    >
        Notifications
    </div>
    <div
        (click)="actionRequested.emit({ action: 'download', item: selectedItem })"
        *ngIf="selectedItem.actionList.includes('download')"
        class="tActionRow"
        id="DownloadButton"
    >
        Download
    </div>
    <div
        (click)="actionRequested.emit({ action: 'restart', item: selectedItem })"
        *ngIf="selectedItem.actionList.includes('restart')"
        class="tActionRow"
        id="RestartButton"
    >
        Restart
    </div>
    <div
        (click)="actionRequested.emit({ action: 'resize', item: selectedItem })"
        *ngIf="selectedItem.actionList.includes('resize')"
        class="tActionRow"
        id="ResizeButton"
    >
        Resize
    </div>
    <div
        (click)="actionRequested.emit({ action: 'suspend', item: selectedItem })"
        *ngIf="selectedItem.actionList.includes('suspend') && selectedItem['status'] !== 'SUSPENDED'"
        class="tActionRow"
        id="SuspendButton"
    >
        Suspend
    </div>
    <div
        (click)="actionRequested.emit({ action: 'resume', item: selectedItem })"
        *ngIf="selectedItem.actionList.includes('resume') && selectedItem['status'] === 'SUSPENDED'"
        class="tActionRow"
        id="ResumeButton"
    >
        Resume
    </div>
    <div
        (click)="actionRequested.emit({ action: 'json', item: selectedItem })"
        *ngIf="selectedItem.actionList.includes('json')"
        class="tActionRow"
        id="JsonSelectionButton"
    >
        JSON
    </div>
    <div
        (click)="actionRequested.emit({ action: 'publicCert', item: selectedItem })"
        *ngIf="selectedItem.actionList.includes('publicCert')"
        class="tActionRow"
        id="PublicCertSelectionButton"
    >
        Public Certificate
    </div>
    <div
        (click)="actionRequested.emit({ action: 'move', item: selectedItem })"
        *ngIf="selectedItem.actionList.includes('move')"
        class="tActionRow"
        id="MoveSelectionButton"
    >
        Move Network
    </div>
    <div
        (click)="actionRequested.emit({ action: 'upgrade', item: selectedItem })"
        *ngIf="selectedItem.actionList.includes('upgrade')"
        class="tActionRow"
        id="UpgradeSelectionButton"
    >
        Upgrade Network
    </div>
    <div
        (click)="actionRequested.emit({ action: 'restartAll', item: selectedItem })"
        *ngIf="selectedItem.actionList.includes('restartAll')"
        class="tActionRow"
        id="RestartAllSelectionButton"
    >
        Restart All
    </div>
    <div
        (click)="actionRequested.emit({ action: 'createBackup', item: selectedItem })"
        *ngIf="selectedItem.actionList.includes('createBackup')"
        class="tActionRow"
        id="BackupSelectionButton"
    >
        Create Backup
    </div>
    <div
        (click)="actionRequested.emit({ action: 'restoreBackup', item: selectedItem })"
        *ngIf="selectedItem.actionList.includes('restoreBackup')"
        class="tActionRow"
        id="RestoreSelectionButton"
    >
        Restore Backup
    </div>
    <div
    (click)="actionRequested.emit({ action: 'enableHA', item: selectedItem })"
    *ngIf="selectedItem.actionList.includes('enableHA')"
    class="tActionRow"
    id="RestoreSelectionButton"
>
    Enable HA
</div>

    <div
        (click)="actionRequested.emit({ action: 'delete', item: selectedItem })"
        *ngIf="selectedItem.actionList.includes('delete')"
        class="tActionRow"
        id="DeleteSelectionButton"
    >
        Delete
    </div>

    <div
        (click)="actionRequested.emit({ action: 'supportinfo', item: selectedItem })"
        *ngIf="selectedItem.actionList.includes('supportinfo')"
        class="tActionRow"
        id="DeleteSelectionButton"
    >
        Support Info
    </div>
    <div
        (click)="actionRequested.emit({ action: 'configPort', item: selectedItem })"
        *ngIf="selectedItem.actionList.includes('configPort')"
        class="tActionRow"
        id="DeleteSelectionButton"
    >
        Manage Config
    </div>
</div>
<div
    [ngClass]="{ open: openHeaderMenu }"
    [ngStyle]="{ left: menuLeft + 'px', top: menuTop + 'px' }"
    class="tMenu action-menu header-menu"
    id="HeaderActionMenu"
>
    <div (click)="resetTableColumns()" class="tActionRow" id="ResetTableButton">Restore Default Table</div>

    <div
        (click)="actionRequested.emit({ action: 'downloadAll' })"
        *ngIf="showDownload()"
        class="tActionRow"
        id="DownloadAllToCSV"
    >
        {{ view === 'users' ? 'Download User Roles' : 'Download All' }}
    </div>
    <div
        (click)="actionRequested.emit({ action: 'downloadCsv' })"
        *ngIf="anySelected()"
        class="tActionRow"
        id="DownloadCSV_Bulk"
    >
        Download Selected
    </div>
</div>
<div
    (clickOutside)="closeHeaderFilter($event)"
    [ngClass]="{ open: showFilterOptions }"
    [ngStyle]="{ left: menuLeft + 'px', top: menuTop + 'px' }"
    class="tMenu action-menu header-menu"
    id="HeaderFilterOptions"
>
    <div (click)="applyFilter($event, filter)" *ngFor="let filter of filterOptions" class="tActionRow">
        <span *ngIf="filter.bubbleClass" [ngClass]="filter.bubbleClass" class="bubble">
            <svg *ngIf="filter.showLoader" class="circular" viewBox="25 25 50 50">
                <circle
                    class="path"
                    cx="50"
                    cy="50"
                    fill="none"
                    r="20"
                    stroke-miterlimit="10"
                    stroke-width="3"
                ></circle>
            </svg>
        </span>
        {{ filter.label }}
    </div>
</div>
