<app-action-button
    (addEvent)="create()"
    (deleteEvent)="openConfirm($event)"
    [isDeleteAction]="isDeleteAction"
    [hidden]="!authorizationService.canCreateOIDCIssuer()"
>
</app-action-button>

<app-loading-indicator
    *ngIf="isLoading"
    [isLoading]="isLoading"
    class="load-indicator"
    id="oidcIssuersPage"
></app-loading-indicator>

<div class="area data-table" id="ItemsArea">
    <app-noitems
        (clickEmit)="create()"
        [image]="'No_Posture'"
        [isEmpty]="showNoData"
        [typeName]="'Oidc Issuers'"
    ></app-noitems>

    <app-data-table
        [tableId]="'oidc-issuers'"
        *ngIf="filterApplied || (oidcIssuers.length > 0 && !isLoading) || filterHasChanged || changedPage"
        [columnDefinitions]="columnDefs"
        [columnFilters]="columnFilters"
        [deleteItem]="deleteSingleItem.bind(this)"
        [filterChanged]="filterChanged.bind(this)"
        [openItem]="open.bind(this)"
        [rowData]="oidcIssuers"
        [toggleAll]="toggleAll.bind(this)"
        [toggleItem]="toggle.bind(this)"
        [value]="oidc-issuers"
    >
    </app-data-table>
</div>
