import {Component, Inject, Input, OnInit} from '@angular/core';
import { ElasticsearchService } from '@netfoundry-ui/shared/elasticsearch';
import { TobytesPipe } from '@netfoundry-ui/ui/pipes';
import { ApiService, LoggerService } from '@netfoundry-ui/shared/services';
import { Chart } from 'angular-highcharts';
import { Subscription } from 'rxjs';
import { ServiceEventTemplateQueryParameter } from '@netfoundry-ui/shared/model';
import {TEMPLATE_SEARCH_SERVICE, TemplateSearchService} from "@netfoundry-ui/shared/apiv2";

@Component({
    selector: 'app-service-health-pie',
    templateUrl: './service-health-pie.component.html',
    styleUrls: ['./service-health-pie.component.scss'],
})
export class ServiceHealthPieComponent implements OnInit {
    @Input() sourceId: string;
    @Input() networkId: any = null;
    @Input() networkGroupId: any = null;
    @Input() serviceId: any = null;
    @Input() dateFilter: any = '24h';
    @Input() height = '250px';

    chart: Chart;
    colors = ['#0273fb', '#08dc5a', '#FF0D49', '#1aadce', '#6d00f2', '#ffc000', '#ff7e00', '#ca0000', '#00aeb0'];
    colorMap = {
        'service.dial.success': '#08dc5a',
        'service.dial.fail': '#FF0D49',
        'service.dial.timeout': '#ff2222',
        'service.dial.error_other': '#ff5622',
        'service.dial.terminator.connection_refused': '#eb4934',
        'service.dial.terminator.invalid': '#ebd334',
    };
    noData = true;
    isLoading = false;
    initialized = false;
    chart_options = {};
    chart_series = [];
    interval = '1m';
    items = [];
    @Input() endTime = Date.now();
    @Input() startTime = this.endTime - 24 * 60 * 60 * 1000;
    private subscription = new Subscription();

    constructor(
        private elasticsearch: ElasticsearchService,
        private toBytes: TobytesPipe,
        public apiService: ApiService,
        private logger: LoggerService,
        @Inject(TEMPLATE_SEARCH_SERVICE) public templateService: TemplateSearchService
    ) {}

    ngOnInit() {
        this.initialized = true;
        this.getDialData();
    }

    ngOnChanges() {
        if (this.initialized) {
            this.getDialData();
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public set_chart_options() {
        const pipe = this.toBytes;
        this.chart_options = {
            colors: this.colors,
            styledMode: true,
            title: { text: null },
            credits: { enabled: false },
            chart: {
                type: 'pie',
                backgroundColor: 'rgba(255, 255, 255, 0.0)',
                height: this.height,
            },

            plotOptions: {
                pie: {
                    showInLegend: false,
                    dataLabels: {
                        enabled: false,
                        shadow: true,
                        distance: -20,
                        style: {
                            color: 'white',
                            fontSize: '12px',
                        },
                        format: '{point.name}<br /><b>{point.percentage:.1f}%</b> ({point.y:,.0f} dials)',
                    },
                    startAngle: -90,
                    endAngle: 90,
                    center: ['50%', '75%'],
                    size: '100%',
                },
            },
            yAxis: {
                labels: {
                    format: '{value}%',
                    color: window.getComputedStyle(document.body).getPropertyValue('--text'),
                },
            },
            tooltip: {
                pointFormat: '<b>{point.percentage:.1f}%</b> ({point.y:,.0f} dials)<br/>',
                // split: true
            },
        };
    }

    public getDialData() {
        const index = 'ncserviceevents';
        const series = [];

        this.logger.info('Ziti Service Pie Query:', JSON.stringify(this.getQuery()));
        // this.statusLoading = true;

        this.noData = true;
        this.isLoading = true;
        //reportig
        const serviceEventTemplateParams: ServiceEventTemplateQueryParameter = this.templateService.getServiceHealthTemplateQuery(
          this.dateFilter,
          this.networkId,
          this.networkGroupId,
          index,
          this.serviceId,
          undefined,
          '4',
          'UTC',
        );
        this.subscription.add(
            //this.elasticsearch.search(this.networkGroupId, index, this.getQuery()).subscribe((data) => {
            this.elasticsearch
                .apiTemplateSearch(this.templateService.SERVICE_HEALTH_COUNT_TEMPLATE, serviceEventTemplateParams)
                .subscribe((data) => {
                    if (
                        data['aggregations'] === undefined ||
                        data['aggregations']['event_types']['buckets'] === undefined
                    ) {
                        this.logger.info('No item buckets returned for utilization data');
                        this.noData = true;
                        this.isLoading = false;
                        return;
                    }

                    // reset the utilization series so we don't stack series
                    this.chart_series = [];
                    const dataseries = [];

                    for (const inner_bucket of data['aggregations']['event_types']['buckets']) {
                        const event_type = inner_bucket['key'];
                        const val = inner_bucket['sum']['value'];

                        series.push({
                            name: event_type,
                            y: val,
                            color: this.colorMap[event_type],
                        });
                    }

                    console.log('DATASERIES', dataseries);

                    this.set_chart_options();

                    this.chart_series = [
                        {
                            name: 'Service Dial Health',
                            innerSize: '50%',
                            data: series,
                        },
                    ];

                    if (data['aggregations']['event_types']['buckets'].length > 0) {
                        this.noData = false;
                    }

                    this.isLoading = false;
                })
        );
    }

    public getQuery() {
        const model: any = {
            aggs: {
                event_types: {
                    terms: {
                        field: 'event_type.keyword',
                        size: 4,
                    },
                    aggs: {
                        sum: {
                            sum: {
                                field: 'count',
                            },
                        },
                    },
                },
            },
            size: 0,
            query: {
                bool: {
                    must: [
                        {
                            range: {
                                '@timestamp': {
                                    gte: 'now-' + this.dateFilter,
                                    lte: 'now',
                                    format: 'epoch_millis',
                                },
                            },
                        },
                        {
                            match_phrase: {
                                network_id: {
                                    query: this.networkId,
                                },
                            },
                        },
                        {
                            match_phrase: {
                                organizationId: {
                                    query: this.networkGroupId,
                                },
                            },
                        },
                        {
                            match_phrase: {
                                nf_service_id: {
                                    query: this.serviceId,
                                },
                            },
                        },
                    ],
                },
            },
        };

        return model;
    }
}
