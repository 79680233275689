<div class="fullModal opened" id="AudienceAddModal">
    <div [hidden]="isComplete" class="fullRow">
        <div class="title" style="filter: brightness(50%)">
            <span *ngIf="model.id">Edit Your OIDC Audience</span>
            <span *ngIf="!model.id">Create A New OIDC Audience</span>
        </div>
        <div *ngIf="model.id" class="subtitle">Update your notification attributes below</div>
        <div *ngIf="!model.id" class="subtitlealt">
            In OpenID Connect, the audience is a claim in the ID token that specifies the intended recipient of the token. It typically represents the client application that will use the token.
        </div>
        <div class="sized">
            <div id="NetworkField">
                <div class="label row">Oidc Issuer Id<span class="note">Required</span></div>
                <div class="row">
                    <input
                        [(ngModel)]="model.oidcIssuerId"
                        id="EditZRouterName"
                        placeholder="Place your issuer ID here."
                        type="text"
                    />
                </div>
                <span [hidden]="!errors['oidcIssuerId']" class="err">
                    {{ errors['oidcIssuerId'] }}
                </span>
            </div>
            <div class="label row">Audience<span class="note">Required</span></div>
            <div class="row">
                <input
                    [(ngModel)]="model.audience"
                    id="EditZRouterName"
                    placeholder="Place your Oidc Audience here."
                    type="text"
                />
                <span [hidden]="!errors['audience']" class="err">
                    {{ errors['audience'] }}
                </span>
            </div>
            <div class="label row">Restricted To Organization Ids<span class="note">Optional</span></div>
            <div class="row">

            <p-multiSelect [options]="organizationList" optionValue="id" [(ngModel)]="selectedIds" optionLabel="name" placeholder="Select Organizations"></p-multiSelect>

            <span [hidden]="!errors['restrictedToOrganizationIds']" class="err">
                {{ errors['restrictedToOrganizationIds'] }}
            </span>
            </div>
            <div class="label row">
                Activate Audience
                <div
                    class="infoicon"
                    matTooltip="Sets this audience active for your provider."
                    matTooltipPosition="below"
                ></div>
                <span class="note">REQUIRED</span>
            </div>
            <div class="row">
                <div class="encryptionpod">
                    <span class="encryptiontext">Activate Audience</span>
                    <div
                        (click)="toggleActive()"
                        [ngClass]="{
                            on: model.active,
                        }"
                        [title]="'Activate This Audience'"
                        class="toggle"
                        style="float: right; margin-top: 0.9375rem; margin-right: 0.75rem"
                    >
                        <span [hidden]="!model.active" style="margin-left: -0.9375rem">YES</span>
                        <span [hidden]="model.active" style="margin-right: -0.9375rem">NO</span>
                        <div class="switch"></div>
                    </div>
                </div>
            </div>
            <!-- <div class="label row">Active<span class="note">Required</span></div>
            <div class="row">
            </div> -->

            <div class="row buttonRow right" style="margin-top: 1.25rem">
                <button
                    (click)="save()"
                    *ngIf="!processing && (!model.id || canEdit)"
                    class="save"
                    id="SaveZitiEndpointButton"
                >
                    {{ model.id ? 'Update' : 'Create' }}
                </button>
                <button *ngIf="processing" class="save confirm" disabled>Saving <span class="spinner"></span></button>
            </div>
            <div (click)="hide()" class="buttonBall close" id="EscZEndpointClose">
                <div class="buttonLabel">ESC</div>
            </div>
        </div>
    </div>

    <div [hidden]="!isComplete" class="fullRow" id="Stage3">
        <div class="confetti">
            <!--confetti div starts-->
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>

            <div class="title congratulations" style="filter: brightness(50%)">Congratulations!</div>
            <div class="subtitlealt">
                Your oidc audience has been created. You may edit it at anytime under oidc audiences.
            </div>
            <div class="row center" style="margin-top: 1.5625rem">
                <button (click)="closeDialog()" [routerLink]="Oidc_Audiences" class="save">Oidc Audiences</button>
            </div>
        </div>

        <div (click)="hide()" class="buttonBall close" id="EscZEndpointClose">
            <div class="buttonLabel">ESC</div>
        </div>
        <app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
    </div>
    <!--confetti div ends-->
</div>
