
<app-loader [isLoading]="isLoading" id="selfService"></app-loader>
<div>
    <main>
        <div class="area mid">
            <div class="titleImage step1"></div>
            <div *ngIf="!isSuccessful" class="title">Onboard New Customer</div>

            <div *ngIf="!isSuccessful" class="formContainer">
                    <div class="area middle" id="NameForm">
                        <div class="label row">
                            Organization Label
                        </div>
                        <div class="row">
                            <div>
                                <input
                                    [(ngModel)]="model.label"
                                    id="OrgLabel"
                                    name="label"
                                    placeholder="Organization Label"
                                    type="text"
                                />
                                <span [hidden]="!errors['label']" class="err">
                                    {{ errors['label'] }}
                                </span>
                            </div>
                        </div>
                        <div class="label row">
                            Customer Invitation Emails
                        </div>
                        <div class="row">
                            <p-chips
                            (keydown)="onKeyDown($event)"
                            [(ngModel)]="model.adminUsers"
                            [allowDuplicate]="false"
                            [placeholder]="'Paste emails here.'"
                            addOnBlur="true"
                            separator=","
                            (paste)="paste($event)"
            
                        >
                        </p-chips>
                        <span [hidden]="!errors['adminUsers']" class="err">
                            {{ errors['adminUsers'] }}
                        </span>
                        </div>

                        <div class="row topMargin">
                            <button *ngIf="!processing" (click)="signup()"class="button" id="NextButton1">Send New Customer Invites</button>
                            <button *ngIf="processing" class="save confirm button" disabled>
                                <span class="spinner"></span>
                                Saving
                            </button>
                        </div>
                    </div>
            </div>
            <div *ngIf="isSuccessful" class="private-key-header-success">
                <div class="private-key-title-container">
                  <div style="text-align: center;" class="private-key-form-title">
                    Onboard Started Successfuly
                  </div>
                  <div class="private-key-form-subtitle">
                    Customer Invites Will Be Sent After Network Is Ready
                  </div>
                  <div style="text-align: center; padding-top: 0.9375rem;">
                    <span class="pi pi-check-circle" style="font-size: 2.0rem; color: #00db48; align: center;"></span>
                  </div>
                  <div class="private-key-form-content">
                    You may now close the window.
                  </div>
            </div>
            </div>
            <app-copyright></app-copyright>
        </div>
    </main>
</div>
